import frenchTranslation from '@/translations/fr.json'

export function translateLabel(label, self) {
    let chosenKey = Object.keys(frenchTranslation).find(key => frenchTranslation[key] === label);
    if (chosenKey !== undefined) return self.$t(chosenKey)
    return label
}

export function getRowTranslation(row, self) {
    let res = {}
    for (let element in row) {
        res[translateLabel(self.$t(element), self)] = translateLabel(row[self.$t(element)], self)
    }
    return res
}
