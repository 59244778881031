/* eslint-disable space-before-function-paren */
import { HTTP, DefaultErrorHandler, axios } from '@/utils/http-common'

export default {
  download($, file) {
    return new Promise((resolve, reject) => {
      HTTP.get('/files/manage/' + file.id + '/', {
        responseType: 'arraybuffer',
      })
        .then((r) => {
          const blob = new Blob([r.data], { type: r.headers['content-type'] })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          resolve(link)
        })
        .catch((err) => DefaultErrorHandler($, err, reject))
    })
  },
  get($, projectId) {
    return new Promise((resolve, reject) => {
      HTTP.get('/projects/manage/' + projectId + '/')
        .then((r) => {
          resolve(r.data.files)
        })
        .catch((err) => DefaultErrorHandler($, err, reject))
    })
  },
  list($, search, page, size, cancelToken = null, merchantId = null) {
    let paramsList = []
    let paramsQuery = ''
    if (search) {
      paramsList.push(`search=${search}`)
    }
    if (page) {
      paramsList.push(`page=${page}`)
    }
    if (size) {
      paramsList.push(`size=${size}`)
    }
    if (paramsList.length > 0) {
      paramsQuery = '?' + paramsList.join('&')
    }
    let url
    if (merchantId) {
      url = `bnc/merchant/${merchantId}/files/`
    } else {
      url = '/bnc/files/'
    }
    return new Promise((resolve, reject) => {
      HTTP.get(url + paramsQuery, {
        cancelToken: cancelToken,
      })
        .then((r) => {
          resolve(r.data)
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            DefaultErrorHandler($, err, reject)
          }
        })
    })
  },
  create($, projectName, file, form) {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('project_id', projectName)
      formData.append('function_id', form.process_func)
      formData.append('file', file, file.filename)
      formData.append('details', JSON.stringify(form.details))
      HTTP.post('/files/manage/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((response) => {
          $.$store.commit('success', 'Fichier créé')
          resolve(response)
        })
        .catch((err) => DefaultErrorHandler($, err, reject))
    })
  },
  delete($, file) {
    return new Promise((resolve, reject) => {
      HTTP.delete('/files/manage/' + file.id)
        .then((response) => {
          $.$store.commit('success', 'Fichier supprimé')
          resolve(response)
        })
        .catch((err) => DefaultErrorHandler($, err, reject))
    })
  },
}
