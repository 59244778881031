import HmdAPI from '@/plugins/axios'
export default {
  get: function($, query) {
    return new Promise((resolve, reject) => {
      HmdAPI.get(`query/manage/${query}/`)
        .then(response => resolve(response.data))
        .catch(err => {
          return reject(err)
        })
    })
  },
  run: function($, query, parameters = []) {
    return new Promise((resolve, reject) => {
      HmdAPI.get(`query/manage/${query}/results/`, {
        params: {
          parameters: parameters,
        },
      })
        .then(response => resolve(response.data))
        .catch(err => {
          return reject(err)
        })
    })
  },
  runWithProject: function($, project, query, parameters = []) {
    return new Promise((resolve, reject) => {
      HmdAPI.get(`projects/manage/${project}/query/${query}/results/`, {
        params: {
          parameters: parameters,
        },
      })
        .then(response => resolve(response.data))
        .catch(err => {
          return reject(err)
        })
    })
  },
  runAnon($, project = null, query = '', parameters = []) {
    return new Promise((resolve, reject) => {
      HmdAPI.post('query/results/', {
        project_id: project,
        query: query,
        parameters: parameters,
      })
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
}
