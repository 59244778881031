<template>
  <v-container>
    <v-row>
      <v-col cols="4" class="offset-1">
        <v-autocomplete
          dense
          v-model="target"
          :items="currencies"
          item-text="currency"
          outlined
          return-object
          label="Currency"
          @change="handleValueChange(false)"
        ></v-autocomplete>
      </v-col>
      <v-col cols="2" />
      <v-col cols="4">
        <v-text-field dense :value="'Euro'" disabled outlined />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" class="offset-1 py-0">
        <v-text-field
          v-model="value.target"
          class="centered-input text-center"
          :suffix="target ? target.code || '' : ''"
          type="number"
          @input="handleValueChange(true)"
        />
      </v-col>
      <v-col cols="2" class="text-center py-0">
        <v-icon size="40"> mdi-arrow-left-right </v-icon><br />
        <span>{{ target ? target.trx || 0 : 0 }}</span>
      </v-col>
      <v-col cols="4" class=" py-0">
        <v-text-field
          v-model="value.euro"
          class="centered-input text-center"
          suffix="€"
          type="number"
          @input="handleValueChange(false)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DatasetService from "@/services/DatasetService";
export default {
  props: {
    updated: {
      type: Number,
      default: 0
    }
  },
  watch: {
    updated(newValue, oldValue) {
      this.getCurrencies();
    }
  },
  data() {
    return {
      currencies: [],
      target: null,
      value: {
        euro: 1,
        target: 0
      }
    };
  },
  mounted() {
    this.handleValueChange(false);
    this.getCurrencies();
  },
  methods: {
    getCurrencies() {
      this.loading = true;
      DatasetService.get(this, this.$store.state.devises).then(r => {
        this.currencies = r.data;
        if (this.target == null) {
          this.target = r.data[0];
          this.handleValueChange(false);
        } else {
          this.target = r.data.find(r => r.devise == this.target.devise);
          this.handleValueChange(true);
        }
        this.loading = false;
      });
    },
    handleValueChange(isTarget) {
      const $ = this;
      const valeur = $.target ? $.target.trx || 0 : 0;
      if (isTarget) {
        $.value.euro = $._.round($.value.target * valeur, 4);
      } else $.value.target = $._.round($.value.euro * (1 / valeur), 4);
    }
  }
};
</script>

<style scoped>
.centered-input >>> input {
  text-align: center;
  font-size: 35px;
  font-weight: 300;
  height: 50px;
  max-height: 80px;
}
</style>
