<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{ on }">
      <v-btn
        color="primary"
        dark
        class="mb-2"
        :fab="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
        :small="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
        v-on="on"
        @click="open"
      >
        <v-icon :left="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)">
          mdi-plus
        </v-icon>
        <template v-if="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)">
          Ajouter
        </template>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text class="pt-1">
        <v-row>
          <v-col cols="12">
            <div class="subtitle">
              Un email va étre envoyé a cet utilisateur contenant toutes les
              informations nécessaire pour se connecter.
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-row dense>
              <v-col cols="12" class="my-0 py-0">
                <v-text-field
                  v-model="user.username"
                  name="username"
                  label="Utilisateur"
                  outlined
                  :error-messages="errors.username"
                  @blur="$v.user.email.$touch()"
                />
              </v-col>

              <v-col cols="12" class="my-0 py-0">
                <v-text-field
                  v-model="user.first_name"
                  name="first_name"
                  label="Prenom"
                  outlined
                />
              </v-col>
              <v-col cols="12" class="my-0 py-0">
                <v-text-field
                  v-model="user.last_name"
                  name="last_name"
                  label="Nom"
                  outlined
                />
              </v-col>
              <v-col cols="12" class="my-0 py-0">
                <v-text-field
                  v-model="user.email"
                  :error-messages="errors.email"
                  name="email"
                  label="Email"
                  outlined
                  @blur="$v.user.email.$touch()"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6">
            <v-row>
              <v-col cols="12" class="my-0 py-0">
                <MerchantInput
                  v-model="user.merchant"
                  outlined
                  :error-messages="errors.merchant"
                  :merchant="value.merchant"
                />
              </v-col>
              <v-col cols="12" class="my-0 py-0">
                <v-autocomplete
                  :disabled="user.is_admin"
                  clearable
                  label="Brands"
                  item-text="label"
                  item-value="id"
                  return-object
                  v-model="user.groups"
                  :items="companyGroups"
                  outlined
                  multiple
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" class="my-0 py-0">
                <v-radio-group v-model="user.dashboard_default_period">
                  <template v-slot:label>
                    <div>Période par défaut des dashboards</div>
                  </template>
                  <v-radio label="Mois précédent" value="month"></v-radio>
                  <v-radio label="Jour précédent" value="day"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn @click="close" text> Annuler </v-btn>
        <v-btn
          class="primary--text"
          depressed
          @click="save"
          :loading="saveLoader"
        >
          Enregistrer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import MerchantInput from '@/components/inputs/MerchantInput'
import CompanyService from '@/services/CompanyService'
import CompanyGroupService from '@/services/CompanyGroupService'
import { required, email } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { cloneDeep } from 'lodash'
export default {
  components: {
    MerchantInput,
  },
  mixins: [validationMixin],
  validations: {
    user: {
      username: { required },
      email: { required, email },
      first_name: {},
      last_name: {},
      merchant: { required },
    },
    validationGroup: ['user'],
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          pk: null,
          username: '',
          email: '',
          first_name: '',
          last_name: '',
          merchant: '',
          dashboard_default_period: 'month',
          groups: [],
        }
      },
    },
    merchant: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: false,
      user: {
        pk: null,
        username: '',
        email: '',
        first_name: '',
        last_name: '',
        merchant: '',
        dashboard_default_period: 'month',
        groups: [],
      },
      saveLoader: false,
      companyGroups: [],
    }
  },
  computed: {
    formTitle() {
      return this.user.pk
        ? "Edition d'un utilisateur"
        : "Création d'un nouvel utilisateur"
    },
    errors() {
      const $ = this
      const errs = {
        email: [],
        merchant: [],
        username: [],
      }
      if ($.$v.user.email.$dirty) {
        !$.$v.user.email.required && errs.email.push("L'email est requis")
        !$.$v.user.email.email && errs.email.push("L'email n'est pas valide")
      }
      if ($.$v.user.merchant.$dirty) {
        !$.$v.user.merchant.required &&
          errs.merchant.push('Le marchand est requis')
      }
      if ($.$v.user.username.$dirty) {
        !$.$v.user.username.required &&
          errs.username.push("Le nom d'utilisateur est requis")
      }
      return errs
    },
  },
  mounted() {
    this.user.merchant = this.merchant
    this.getCompanyGroups()
  },
  watch: {
    value(newValue) {
      if (newValue.pk) {
        const temp = cloneDeep(newValue)
        this.user = {
          ...temp,
          groups: temp.groups.map((g) => ({
            id: g.id,
            label: this.lo.startCase(g.name),
            name: g.name,
          })),
        }
        this.dialog = true
      }
    },
  },
  methods: {
    open() {
      this.$v.$reset()
    },
    reset() {
      this.user = {
        id: null,
        username: '',
        email: '',
        first_name: '',
        last_name: '',
        merchant: this.user.id ? this.user.merchant : null || null,
        groups: [],
      }
    },
    close() {
      this.dialog = false
      this.reset()
      this.$emit('closed')
    },
    async handleUserGroupCreateUpdate(user, isCreate = false) {
      const $ = this
      if (isCreate && (!user.groups || user.groups?.length <= 0)) return
      const oldGroups = $.value.groups || []
      let promises = []
      if (user.pk != null) {
        // update (create/update/delete)
        // virer celles qui ont été déselectionné
        oldGroups
          .filter((g) => !user.groups.map((g2) => g2.pk).includes(g.id))
          .map((g) => {
            promises.push(
              new Promise((resolve, reject) => {
                CompanyGroupService.remove_member_group_company(
                  $,
                  g.id,
                  user.pk
                )
                  .then((r) => resolve(r))
                  .catch((err) => reject(err))
              })
            )
          })
        // créer les nouvelles
        user.groups
          .filter((g) => !oldGroups.map((g2) => g2.pk).includes(g.id))
          .map((g) => {
            promises.push(
              new Promise((resolve, reject) => {
                CompanyGroupService.add_member_group_company($, g.id, user.pk)
                  .then((r) => resolve(r))
                  .catch((err) => reject(err))
              })
            )
          })
      } else {
        // create
        user.groups.map((g) =>
          promises.push(
            new Promise((resolve, reject) => {
              CompanyGroupService.add_member_group_company($, g.id, user.pk)
                .then((r) => resolve(r))
                .catch((err) => reject(err))
            })
          )
        )
      }
      for (const promise of promises) {
        await Promise.resolve(promise)
      }
    },
    save() {
      if (this.user.pk != null) {
        this.$v.user.$touch()
        if (!this.$v.user.$invalid) {
          this.saveLoader = true
          CompanyService.User.update(this, this.user, this.user.pk)
            .then((r) => {
              this.handleUserGroupCreateUpdate(this.user)
              this.$emit('updated')
              this.close()
              this.saveLoader = false
            })
            .catch((error) => {
              this.saveLoader = false
            })
        }
      } else {
        this.$v.user.$touch()
        if (!this.$v.user.$invalid) {
          CompanyService.User.create(this, this.user).then((r) => {
            r.groups = this.user.groups
            this.handleUserGroupCreateUpdate(r, true)
            this.$emit('created')
            this.close()
          })
        }
      }
    },
    getCompanyGroups() {
      CompanyGroupService.list_group_company(this).then((r) => {
        this.companyGroups = r
          .filter((f) => !['ASD_default_group'].includes(f.name))
          .map((o) => ({ ...o, label: o.name }))
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
