<template>
  <div
    id="1620728985399"
    class="hmd-visualization unpaid hmd-visualization-card"
    style="width: 100%; height: 100%"
  >
    <div class="hmd-visualization-header">
      <div
        class="html-viqualization-title-subtitle-wrapper"
        style="text-align: left"
      >
        <div class="hmd-visualization-title">
          {{ $t('tableForMonitoringUnpaidInvoices') }}
        </div>
        <!---->
      </div>
      <!---->
      <div class="transition-ease el-dropdown">
        <button
          type="button"
          class="
            el-button
            custom-dropdown-btn
            transition-ease
            el-button--text
            is-circle
            el-dropdown-selfdefine
          "
          aria-haspopup="list"
          aria-controls="dropdown-menu-8885"
          role="button"
          tabindex="0"
          style="opacity: 0"
        >
          <!----><i class="el-icon-more-outline"></i
          ><!---->
        </button>
        <ul
          class="el-dropdown-menu el-popper"
          id="dropdown-menu-8885"
          style="display: none"
        >
          <li tabindex="-1" class="el-dropdown-menu__item">
            <!----><i class="el-icon-tickets"></i> {{ $t('exportToCsv') }}
          </li>
          <li tabindex="-1" class="el-dropdown-menu__item">
            <!----><i class="el-icon-picture-outline-round"></i>
            {{ $t('exportToImage') }}
          </li>
        </ul>
      </div>
    </div>
    <div class="hmd-visualization-body" style="min-height: auto">
      <!----><!---->
      <div class="text-center py-6" v-if="isLoading">
        <v-progress-circular
          width="2"
          size="20"
          class="mr-3"
          indeterminate
          color="primary"
        ></v-progress-circular>
        {{ $t('dataLoading') }}
      </div>
      <div class="text-center py-6" v-else-if="data.columns.length <= 0">
        {{ $t('noDataToDisplay') }}
      </div>
      <table class="hmd-visualization-table" v-else>
        <thead>
          <tr>
            <th v-for="(c, i) in data.columns" :key="i">
              {{ translate(c.name) }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(r, i) in data.rows" :key="i">
            <td v-for="(c, j) in data.columns" :key="j">
              {{ translate(r[c.name]) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { BncService } from '@/services'
import { translateLabel, getRowTranslation } from '@/utils/translations.js'
import { relativeTimeRounding } from 'moment'
export default {
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: true,
      data: { columns: [], row: [] },
    }
  },
  mounted() {
    this.getTCDData()
  },
  watch: {
    filters(newValue, oldValue) {
      this.getTCDData()
    },
  },
  methods: {
    translate(val) {
      return translateLabel(val, this)
    },
    getTCDData() {
      const $ = this
      this.isLoading = true
      BncService.impayesTCD(
        this,
        this.$store.getters.merchantId,
        (this.filters || []).reduce((p, c) => {
          p[c.column] = c.value
          return p
        }, {})
      )
        .then((r) => {
          this.data = r.data
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
