<template>
<v-card class="mb-6 card-shadow border-radius-xl px-4 py-4">
    <v-row no-gutters>
        <v-col sm="8">
        <p
            class="
            text-sm
            mb-0
            text-capitalize
            font-weight-bold
            text-body
            "
        >
            Today's Money
        </p>
        <h5 class="text-h5 text-typo font-weight-bolder mb-0">
            $53,000
            <span class="text-success text-sm font-weight-bolder"
            >+55%</span
            >
        </h5>
        </v-col>
        <v-col sm="4" class="text-end">
        <v-avatar
            color="bg-gradient-success"
            class="shadow"
            rounded
        >
            <v-icon size="20" class="text-white">
            ni ni-money-coins
            </v-icon>
        </v-avatar>
        </v-col>
    </v-row>
</v-card>

</template>

<script>

const WIDGET_SHORCUT = {
  title: "",
  'acquereurs': {
    icon: 'mdi-shield-airplane',
    label: 'Acquéreurs',
    path: 'Acquéreurs',
  },
  'PSP': {
    icon: 'mdi-shield-airplane',
    label: 'PSP',
    path: '/psp',
  },
  'impayes': {
    icon: 'mdi-shield-airplane',
    label: 'Impayés',
    path: '/psp',
  },
  'rapports': {
    icon: 'mdi-shield-airplane',
    label: 'Rapports',
    path: '/psp',
  }
}

export default {

}
</script>

<style>

</style>