var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"dash pl-10",style:({
    'padding-right': _vm.$store.state.filtersDrawerExpanded
      ? !_vm.$vuetify.breakpoint.smAndDown
        ? '320px'
        : '20px'
      : '12px',
  }),attrs:{"fluid":""}},[_c('core-app-bar'),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"d-print-none text-capitalize",attrs:{"dark":"","color":"blue-grey lighten-2"},on:{"click":function($event){return _vm.handleToggleFilters(true)}}},[_c('v-icon',[_vm._v(" mdi-filter-outline")]),_vm._v(" "+_vm._s(_vm.$t('filters'))+" ")],1),_c('v-btn',{staticClass:"d-print-none text-capitalize ml-2",attrs:{"color":"primary"},on:{"click":_vm.print}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-printer-outline")]),_vm._v(" "+_vm._s(_vm.$t('print'))+" ")],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('visualization',{attrs:{"card":"","type":"bar","query":"psp_evolution_des_transactions_base_mensuelle","translations":_vm.translations,"project":_vm.$store.getters.merchantId,"title":_vm.$t('evolutionOfMonthlyTransactions'),"options":{
          noData: {
            text: _vm.$t('emptyData'),
          },
          xAxes: ['Date'],
          xaxis: {
            reverse: true,
          },
          yAxes: ['Transaction'],
          colors: _vm.paletteChart,
          dataLabels: {
            style: {
              colors: ['#000000', '#000000', '#000000'],
            },
          },
        },"external-filters-values":_vm.specificFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6","md":"12"}},[_c('visualization',{attrs:{"card":"","type":"table","query":"psp_overview_tableau","translations":_vm.translations,"project":_vm.$store.getters.merchantId,"title":_vm.$t('volumesAndAcceptanceRates'),"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"12"}},[_c('visualization',{ref:"grapheGateway",attrs:{"card":"","type":"pie","query":"psp_overview_graph","project":_vm.$store.getters.merchantId,"title":_vm.$t('distributionOfVolumesByGateway'),"options":{
          noData: {
            text: _vm.$t('emptyData'),
          },
          xAxes: ['organism'],
          yAxes: ['count'],
          colors: _vm.paletteChart,
        },"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"divider-title"},[_vm._v(" "+_vm._s(_vm.$t('netAcceptanceRatePerGateway'))+" ")])]),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"12"}},[_c('visualization',{attrs:{"card":"","type":"table","title":_vm.$t('netAcceptanceRatePerGateway'),"query":"psp_taux_acceptation_net_par_gateway_tableau","translations":_vm.translations,"project":_vm.$store.getters.merchantId,"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"12"}},[_c('visualization',{attrs:{"card":"","type":"bar","query":"psp_taux_acceptation_net_par_gateway_graph","translations":_vm.translations,"project":_vm.$store.getters.merchantId,"title":_vm.$t('netAcceptanceRatePerGateway'),"options":{
          noData: {
            text: _vm.$t('emptyData'),
          },
          xAxes: ['Organism'],
          yAxes: [_vm.$t('rate')],
          colors: _vm.paletteChart,
          dataLabels: {
            formatter: _vm.percentFormatter,
          },
          yaxis: {
            max: 100,
            tickAmount: 5,
            labels: {
              show: true,
            },
          },
        },"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1)],1),_c('div',{staticClass:"divider-title"},[_vm._v(" "+_vm._s(_vm.$t('distributionOfVolumesBySalesChannel'))+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6","md":"12"}},[_c('visualization',{attrs:{"card":"","type":"table","query":"psp_repartition_des_transactions_par_canal_de_vente_tableau","translations":_vm.translations,"project":_vm.$store.getters.merchantId,"title":_vm.$t('volumesBySalesChannel'),"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"12"}},[_c('visualization',{attrs:{"card":"","type":"pie","query":"psp_repartition_des_transactions_par_canal_de_vente_graph","project":_vm.$store.getters.merchantId,"title":_vm.$t('distributionOfTransactionsByChannel'),"options":{
          noData: {
            text: _vm.$t('emptyData'),
          },
          xAxes: ['Canal de vente'],
          yAxes: ['Nombre de Statut de Transaction'],
          colors: _vm.paletteChart,
        },"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1)],1),_c('div',{staticClass:"divider-title"},[_vm._v(" "+_vm._s(_vm.$t('distributionOfVolumesByTypeOfPaymentMethod'))+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6","md":"12"}},[_c('visualization',{attrs:{"card":"","type":"table","title":_vm.$t('distributionOfTransactionsByTypeOfPaymentMethod'),"query":"psp_repartition_transactions_par_type_de_mop","translations":_vm.translations,"project":_vm.$store.getters.merchantId,"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"12"}},[_c('visualization',{attrs:{"card":"","type":"pie","query":"psp_repartition_transactions_par_type_de_mop","project":_vm.$store.getters.merchantId,"title":_vm.$t('distributionOfTransactionsByTypeOfPaymentMethod'),"options":{
          noData: {
            text: _vm.$t('emptyData'),
          },
          xAxes: ['Type de méthode de paiement'],
          yAxes: ['Volume'],
          colors: _vm.paletteChart,
        },"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1)],1),_c('div',{staticClass:"divider-title"},[_vm._v(" "+_vm._s(_vm.$t('distributionOfVolumesByTypeOfCard'))+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6","md":"12"}},[_c('visualization',{attrs:{"card":"","type":"table","title":_vm.$t('distributionOfVolumesByBrand'),"query":"psp_repartition_par_type_de_carte_tableau","translations":_vm.translations,"project":_vm.$store.getters.merchantId,"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"12"}},[_c('visualization',{attrs:{"card":"","type":"pie","query":"psp_repartition_par_type_de_carte_graph","project":_vm.$store.getters.merchantId,"title":_vm.$t('distributionOfVolumesByBrand'),"options":{
          noData: {
            text: _vm.$t('emptyData'),
          },
          xAxes: ['Brand'],
          yAxes: ['Volume'],
          colors: _vm.paletteChart,
        },"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1)],1),_c('div',{staticClass:"divider-title"},[_vm._v(" "+_vm._s(_vm.$t('distributionOfVolumesByCountries'))+" (Top 10) ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6","md":"12"}},[_c('visualization',{attrs:{"card":"","type":"table","title":_vm.$t('distributionOfTransactionsByCountries'),"query":"psp_repartition_volumes_par_pays_top10","translations":_vm.translations,"project":_vm.$store.getters.merchantId,"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"12"}},[_c('visualization',{attrs:{"card":"","type":"bar","query":"psp_repartition_volumes_par_pays_top10","translations":_vm.translations,"project":_vm.$store.getters.merchantId,"title":_vm.$t('distributionOfTransactionsByCountries'),"options":{
          noData: {
            text: _vm.$t('emptyData'),
          },
          xAxes: [_vm.$t('country')],
          yAxes: ['Volume'],
          colors: _vm.paletteChart,
        },"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1)],1),_c('div',{staticClass:"divider-title"},[_vm._v(" "+_vm._s(_vm.$t('distributionOfVolumesByCountryOfIssue'))+" (Top 10) ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6","md":"12"}},[_c('visualization',{attrs:{"card":"","type":"table","title":_vm.$t('distributionByCountryOfIssueOfTheCard'),"query":"psp_repartition_volumes_par_pays_bin_top10","translations":_vm.translations,"project":_vm.$store.getters.merchantId,"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"12"}},[_c('visualization',{attrs:{"card":"","type":"bar","query":"psp_repartition_volumes_par_pays_bin_top10","translations":_vm.translations,"project":_vm.$store.getters.merchantId,"title":_vm.$t('distributionByCountryOfIssueOfTheCard'),"options":{
          noData: {
            text: _vm.$t('emptyData'),
          },
          xAxes: [_vm.$t('country')],
          yAxes: ['Volume'],
          colors: _vm.paletteChart,
        },"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1)],1),_c('div',{staticClass:"divider-title"},[_vm._v(" "+_vm._s(_vm.$t('distributionOfTransactionsByAuthenticationStatus'))+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6","md":"12"}},[_c('visualization',{attrs:{"card":"","type":"table","title":_vm.$t('distributionOfTransactionsByAuthenticationStatus'),"query":"psp_transactions_classees_par_holder_authent_statut_tableau","translations":_vm.translations,"project":_vm.$store.getters.merchantId,"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"12"}},[_c('visualization',{attrs:{"card":"","type":"pie","query":"psp_transactions_classees_par_holder_authent_status_graph","project":_vm.$store.getters.merchantId,"title":_vm.$t('distributionOfTransactionsByAuthenticationStatus'),"options":{
          noData: {
            text: _vm.$t('emptyData'),
          },
          xAxes: [`Statut d'authentification`],
          yAxes: ['Transaction'],
          colors: _vm.paletteChart,
        },"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1)],1),_c('div',{staticClass:"divider-title"},[_vm._v(" "+_vm._s(_vm.$t('distributionOfTransactionsByChallengeMode'))+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6","md":"12"}},[_c('visualization',{attrs:{"card":"","type":"table","title":_vm.$t('distributionOfTransactionsByChallengeMode'),"query":"psp_repartition_des_transactions_par_challenge_mode_tableau","translations":_vm.translations,"project":_vm.$store.getters.merchantId,"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"12"}},[_c('visualization',{attrs:{"card":"","type":"pie","query":"psp_repartition_des_transactions_par_challenge_mode","project":_vm.$store.getters.merchantId,"title":_vm.$t('distributionOfTransactionsByChallengeMode'),"options":{
          noData: {
            text: _vm.$t('emptyData'),
          },
          xAxes: ['Challenge mode'],
          yAxes: ['Transaction'],
          colors: _vm.paletteChart,
        },"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1)],1),_c('div',{staticClass:"divider-title"},[_vm._v(" "+_vm._s(_vm.$t('distributionOfTransactionsByResponseCodeDescription'))+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6","md":"12"}},[_c('visualization',{attrs:{"card":"","type":"table","title":_vm.$t('distributionOfTransactionsByResponseCodeDescription'),"query":"psp_repartition_des_transactions_par_response_code_description","translations":_vm.translations,"project":_vm.$store.getters.merchantId,"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"12"}},[_c('visualization',{attrs:{"card":"","type":"pie","query":"psp_repartition_des_transactions_par_response_code_description","project":_vm.$store.getters.merchantId,"title":_vm.$t('distributionOfTransactionsByResponseCodeDescription'),"options":{
          noData: {
            text: _vm.$t('emptyData'),
          },
          xAxes: ['Response code description'],
          yAxes: ['Volume'],
          colors: _vm.paletteChart,
        },"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1)],1),(_vm.$store?.getters?.merchantName == 'AIR_TAHITI')?_c('div',[_c('div',{staticClass:"divider-title"},[_vm._v(" "+_vm._s(_vm.$t('distributionOfAnomalies'))+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6","md":"12"}},[_c('visualization',{attrs:{"card":"","type":"table","title":_vm.$t('distributionOfAnomaliesDescription'),"query":"psp_repartition_des_anomalies","translations":_vm.translations,"project":_vm.$store.getters.merchantId,"external-filters-values":_vm.anomaliesFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"12"}},[_c('visualization',{attrs:{"card":"","type":"pie","query":"psp_repartition_des_anomalies","project":_vm.$store.getters.merchantId,"title":_vm.$t('distributionOfAnomaliesDescription'),"options":{
            noData: {
              text: _vm.$t('emptyData'),
            },
            xAxes: ['Type d\'anomalie'],
            yAxes: ['Volume'],
            colors: _vm.paletteChart,
          },"external-filters-values":_vm.anomaliesFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1)],1)],1):_vm._e(),_c('FiltersDrawer',{attrs:{"filters":_vm.filters(),"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null},on:{"update":(v) => {
        _vm.globalFilters = v
      }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }