<template>
  <v-app>
    <v-main>
      <v-container class="pa-0 fill-height" fluid>
        <v-row class="fill-height pa-0 ma-0">
          <v-col class="imredd-bg" cols="12" md="6" sm="12" lg="4" xl="4">
            <v-row
              class="py-7 fill-height pos-relative"
              justify="center"
              align="center"
            >
              <v-col cols="12" md="10" lg="9">
                <v-row align="center" justify="center">
                  <a :href="app.url" target="_blank">
                    <img src="@/assets/Logo_Color.png" height="55px" />
                  </a>
                </v-row>

                <div class="h4 font-weight-light my-5">
                  {{ $t('welcome') }} ! <br />
                  {{ $t('pleaseConnectToYourAccount') }}
                </div>

                <v-text-field
                  v-model="email"
                  name="email"
                  placeholder="E-mail"
                  :error-messages="$t(errors.email)"
                  autofocus
                  class="input-group--focused"
                  @blur="$v.email.$touch()"
                />
                <v-text-field
                  v-model="password"
                  name="password"
                  :error-messages="$t(errors.password)"
                  :type="passwordType"
                  autocomplete="off"
                  placeholder="Mot de passe"
                  :append-icon="eyeIcon"
                  @keyup.enter="submit"
                  @click:append="togglePassword"
                  @blur="$v.password.$touch()"
                />
                <v-btn
                  dark
                  large
                  block
                  :disabled="false"
                  color="primary"
                  class="my-3"
                  @click="submit"
                  @keyup.enter="submit"
                >
                  {{ $t('connect') }}
                </v-btn>
                <!-- <v-btn dark large block @click="submit" @keyup.enter="submit" disabled color="pink" class="my-3" >Sign in</v-btn> -->
                <ForgottenPasswordDialog /><br />
                <resend-activation-email />
                <div class="text-center">
                  <img
                    class="mt-2 powered_by"
                    src="@/assets/new_powered_by_himydata.png"
                    height="35px"
                    contain
                  />
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="bg-cover hidden-xs-only hidden-sm-only md-6 lg-8 xl-8">
            <v-col cols="12" class="d-flex justify-end">
              <v-select
                v-model="selectLanguage"
                :items="languages"
                @change="switchLanguage"
                solo
                style="max-width: 75px"
                class="mr-2"
              >
                <template v-slot:selection="{ item }">
                  <img style="width: 22px" :src="item.image" />
                </template>
                <template v-slot:item="{ item }">
                  <img style="width: 22px" :src="item.image" />
                </template>
              </v-select>
            </v-col>
          </v-col>
        </v-row>
        <error-handler />
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import ForgottenPasswordDialog from '@/components/utils/ForgottenPasswordDialog'
import ResendActivationEmail from '@/components/utils/ResendActivationEmail'
import ErrorHandler from '@/components/utils/ErrorHandler'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import { login } from '@/utils/auth'
const Config = require('@/utils/theme-config')
export default {
  components: {
    ErrorHandler,
    ForgottenPasswordDialog,
    ResendActivationEmail,
  },
  mixins: [validationMixin],
  validations: {
    password: { required },
    email: { email, required },
  },
  data: function () {
    return {
      app: {
        name: Config.app.name,
        url: Config.app.url,
      },
      email: '',
      password: '',
      eyeIcon: 'mdi-eye',
      passwordType: 'password',
      languages: [
        {
          name: 'fr',
          image: require('@/assets/fr-48.png'),
        },
        {
          name: 'en',
          image: require('@/assets/uk-48.png'),
        },
      ],
      selectLanguage: {
        name: this.$store.state.language.name,
        image: require('@/assets/fr-48.png'),
      },
    }
  },
  computed: {
    errors() {
      const errors = {
        password: [],
        email: [],
      }

      if (this.$v.password.$dirty) {
        !this.$v.password.required && errors.password.push('passwordRequired')
      }
      if (this.$v.email.$dirty) {
        if (!this.$v.email.email) {
          errors.email.push('emailMustBeValid')
        }
        if (!this.$v.email.required) {
          errors.email.push('emailIsRequired')
        }
      }

      return errors
    },
  },
  mounted: function () {
    this.clear()
  },

  methods: {
    switchLanguage(v) {
      if (this.$i18n.locale === 'fr') {
        this.$i18n.locale = 'en'
      } else {
        this.$i18n.locale = 'fr'
      }
      this.$store.commit('setLanguage', v)
    },
    submit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.call_login()
      }
    },
    call_login() {
      var credentials = {
        email: this.email,
        password: this.password,
      }
      login(this, credentials, this.$route.query.redirect).then((r) => {
        this.$store.commit('setUser', r.data)
      })
    },
    clear() {
      this.$v.$reset()
      this.email = ''
      this.password = ''
      this.checkbox = null
    },
    togglePassword() {
      this.passwordType = this.passwordType == 'password' ? 'text' : 'password'
      this.eyeIcon = this.eyeIcon == 'mdi-eye' ? 'mdi-eye-off' : 'mdi-eye'
    },
  },
}
</script>
<style>
.imredd-bg {
  background-color: white;
}
.pos-relative {
  position: relative;
}
.bg-cover {
  background-size: cover;
  background-position: 50% 50%;
  background-image: url(../../assets/signin_cover.png);
}
.powered_by {
  margin: 20px;
}
</style>
