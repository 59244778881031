<template>
  <v-container fluid>
    <top-header title="Organismes">
      <template v-slot:SideMenu>
        <v-btn
          color="white primary--text"
          :to="{ path: '/backoffice/create/organisme' }"
        >
          <v-icon left>
            mdi-plus
          </v-icon>
          Ajouter
        </v-btn>
      </template>
    </top-header>
    <v-row>
      <list-organisms />
    </v-row>
  </v-container>
</template>

<script>
import TopHeader from '@/components/base/TopHeader'
import ListOrganisms from '@/components/bnc/ListOrganisms'
export default {
  name: 'Organisms',
  components: {
    ListOrganisms,
    TopHeader,
  },
  data() {
    return {
      selectedMerchant: null,
    }
  },
}
</script>

<style scoped></style>
