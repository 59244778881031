<template>
  <v-data-table
    :loading="isLoading"
    hide-default-footer
    :headers="headers"
    :items="items"
  >
    <template v-slot:item.value_comparator="{ item }">
      <!-- <span >{{
        item.mesure.value.indexOf('%') >= 0
          ? item.mesure.value
          : numeral(toNumber(item.mesure.value)).format('0,0')
      }}</span> -->
      <span
        class="font-weight-bold"
        :style="{
          color: color(item.value_comparator) + ' !important',
        }"
        v-if="item.value_comparator"
        >{{ item.value_comparator.reference_value }}</span
      >
    </template>
    <template v-slot:item.tendency_comparator="{ item }">
      <span
        v-if="item.tendency_comparator"
        class="inline-block rule-tendency-icon"
      >
        <v-icon
          v-if="icon(item.tendency_comparator)"
          :color="color(item.tendency_comparator)"
          >{{ tendencyIcons[icon(item.tendency_comparator)] }}</v-icon
        >
        <v-icon v-else>mdi-minus</v-icon>
      </span>
    </template>
  </v-data-table>
</template>

<script>
import { groupByKpi } from '@/utils'
import numeral from '@/plugins/numeral'
import { toNumber } from 'lodash'
import { KpiService } from '@/services'
import { TENDENCY_ICONS } from '@/utils/constants'
export default {
  name: 'kpi-list',
  created() {
    this.getKpis()
  },
  methods: {
    icon(item) {
      if (item.color_rules.length <= 0) return null
      return item.color_rules.icon
    },
    color(item) {
      if (item.color_rules.length <= 0) return 'black'
      return item.color_rules.color
    },
    numeral,
    toNumber,

    getKpis() {
      this.isLoading = true
      KpiService.get(this)
        .then(r => {
          this.items = r.results
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  data() {
    return {
      tendencyIcons: TENDENCY_ICONS,
      isLoading: true,
      headers: [
        {
          text: 'Indicateur',
          value: 'name',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Mesure',
          value: 'value_comparator',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Tendance',
          value: 'tendency_comparator',
          align: 'center',
          sortable: false,
        },
        // {
        //   text: 'Tendance',
        //   value: 'tendance',
        //   align: 'center',
        //   sortable: false,
        // },
      ],
      items: [],
    }
  },
}
</script>

<style lang="scss" scoped>
.rule-tendency-icon {
  border-radius: 5px;
  width: 25px;
  height: 25px;
  display: inline-block;
}
</style>
