<template>
  <div>
    <!-- <v-fade-transition mode="in-out" v-if="$vuetify.breakpoint.smAndDown">
      <v-overlay
        @click="handleToggle(false)"
        v-show="$store.state.filtersDrawerExpanded"
        absolute
        z-index="99"
      >
      </v-overlay>
    </v-fade-transition> -->
    <v-expand-x-transition mode="in-out">
      <v-card
        class="filters-drawer"
        v-show="$store.state.filtersDrawerExpanded"
      >
        <v-navigation-drawer permanent right :style="styles">
          <!-- <div v-show="!filtersExpanded" class="px-5">
        <v-btn icon @click.stop="filtersExpanded = true" class="mt-5">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <div
          @click="filtersExpanded = !filtersExpanded"
          style="writing-mode: vertical-rl; text-orientation: mixed; width: 40px; height: 100px;"
        >
          <v-icon style="transform:rotate(90deg);" class="mb-2" left
            >mdi-filter</v-icon
          >
          <span class="grey--text">Filtres</span>
        </div>
      </div> -->
          <template>
            <div class="pr-9 py-5 text-center p-relative">
              <v-btn
                color="blue accent-3"
                absolute
                offset-y="5"
                float
                top
                left
                icon
                @click.stop="handleToggle(false)"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
              <span>
                <span class="primary--text">{{ $t('myFilters') }}</span>
              </span>
            </div>

            <visualization-filters
              ref="viz-filters"
              class="text-center px-4 py-4"
              @update="(v) => $emit('update', v)"
              :filters="filters"
              :forcedUser="
                $store.state.preview ? $store.state.preview.pk : null
              "
            ></visualization-filters>
          </template>
          <!-- <div class="d-flex align-baseline justify-center">
            <v-btn @click="resetFilters"> Effacer</v-btn>
            <v-btn color="warning"> Filtrer</v-btn>
          </div> -->
        </v-navigation-drawer>
      </v-card>
    </v-expand-x-transition>
  </div>
</template>

<script>
export default {
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      filtersExpanded: true,
    }
  },
  computed: {
    styles() {
      return {
        background: '#F3F3F7',
        width: '320px',
      }
    },
  },
  methods: {
    handleToggle(v) {
      this.$store.commit('setFiltersDrawerExpanded', v)
    },
    setFilterValue(filterName, value) {
      this.$refs['viz-filters'].setFilterValue(filterName, value)
    },
  },
}
</script>

<style>
.el-range-editor--mini.el-input__inner {
  height: 28px !important;
  width: auto !important;
}
</style>
<style lang="css">
.el-collapse-item__header,
.el-collapse-item__content,
.el-collapse-item__wrap {
  background-color: #f3f3f7 !important;
}

.hmd-query-parameter-input {
  width: auto !important;
  padding: 10px 5px 0px 5px !important;
  display: block !important;
}
</style>
<style lang="css" scoped>
.p-relative {
  position: relative;
}
.filters-drawer.v-sheet.v-card {
  border-radius: 0px !important;
}

.filters-drawer {
  height: 100%;
  z-index: 9999;
  position: fixed;
  width: auto;
  right: 0px;
  top: 0px;
}
</style>
