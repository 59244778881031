/* eslint-disable space-before-function-paren */
import { HTTP, DefaultErrorHandler, axios } from '@/utils/http-common'
const HMD_PARTNERS = process.env.HMD_PARTNERS || 'sandbox'

export default {
  get($, company) {
    //   GET /admin/companies/ (list all companies)
    return new Promise((resolve, reject) => {
      return HTTP.get(`/admin/companies/${company}/`)
        .then((r) => {
          resolve(r.data)
        })
        .catch((e) => {
          DefaultErrorHandler($, e, reject)
          reject(e)
        })
    })
  },
  list($) {
    //   GET /admin/companies/ (list all companies)
    return new Promise((resolve, reject) => {
      return HTTP.get('/admin/companies/')
        .then((r) => {
          resolve(r.data)
        })
        .catch((e) => {
          DefaultErrorHandler($, e, reject)
          reject(e)
        })
    })
  },
  create($, form) {
    // POST /admin/company/
    return new Promise((resolve, reject) => {
      return HTTP.post('/admin/companies/', form)
        .then((r) => {
          $.$store.commit('success', 'Compagnie Créée !')
          resolve(r.data)
        })
        .catch((e) => {
          DefaultErrorHandler($, e, reject)
          reject(e)
        })
    })
  },
  update($, form, id) {
    // PUT /admin/company/
    form = {
      name: form.name,
      email: form.email,
      phone_number: form.phone_number,
      website: form.website,
      address: form.address,
      zip_code: form.zip_code,
      city: form.city,
      region: form.region,
      country: form.country,
    }
    return new Promise((resolve, reject) => {
      return HTTP.patch(`/admin/companies/${id}/`, form)
        .then((r) => {
          $.$store.commit('success', 'Compagnie mis a jour !')
          resolve(r.data)
        })
        .catch((e) => {
          DefaultErrorHandler($, e, reject)
          reject(e)
        })
    })
  },
  update_by_invitation($, form, id, invitation_key) {
    // PUT /admin/users/
    // PUT /admin/company/
    form = {
      merchant: form.merchant,
      username: form.username,
      email: form.email,
      first_name: form.first_name,
      last_name: form.last_name,
      password: form.password,
      password2: form.password2,
      invitation_key: invitation_key,
    }
    return new Promise((resolve, reject) => {
      return HTTP.patch(`/admin/users/${id}/update_by_invitation/`, form)
        .then((r) => {
          $.$store.commit('success', 'Utilisateur mis a jour')
          resolve(r.data)
        })
        .catch((e) => {
          DefaultErrorHandler($, e, reject)
          reject(e)
        })
    })
  },
  delete($, id) {
    // DELETE /admin/company/:id
    return new Promise((resolve, reject) => {
      return HTTP.delete(`/admin/companies/${id}/`)
        .then((r) => {
          $.$store.commit('success', 'Compagnie supprimé !')
          resolve(r.data)
        })
        .catch((e) => {
          DefaultErrorHandler($, e, reject)
          reject(e)
        })
    })
  },
  preview($, id) {
    // DELETE /admin/company/:id
    return new Promise((resolve, reject) => {
      return HTTP.get(`/admin/companies/preview/${id}/`)
        .then((r) => {
          $.$store.commit('success', 'Compagnie supprimé !')
          resolve(r.data)
        })
        .catch((e) => {
          DefaultErrorHandler($, e, reject)
          reject(e)
        })
    })
  },
  User: {
    list_by_company($, company) {
      //   GET /admin/users/?company=himydata (list users of a company)
      return new Promise((resolve, reject) => {
        return HTTP.get('/admin/users/?company=' + company)
          .then((r) => {
            resolve(r.data)
          })
          .catch((e) => {
            DefaultErrorHandler($, e, reject)
            reject(e)
          })
      })
    },
    get($, id) {
      // GET /admin/users/:id (Not needed)
      return new Promise((resolve, reject) => {
        return HTTP.get(`/admin/users/${id}/`)
          .then((r) => {
            resolve(r.data)
          })
          .catch((e) => {
            DefaultErrorHandler($, e, reject)
            reject(e)
          })
      })
    },
    list($, search, page, size, cancelToken = null) {
      // GET /admin/users/?company=himydata (list of a certain company)
      // GET /admin/users/ (list of all companies)
      return new Promise((resolve, reject) => {
        return HTTP.get(
          '/admin/users/' + (page ? '?page=' + page : '') + (size ? '&size=' + size : '') + (search ? '&search=' + search : ''), {
          cancelToken: cancelToken,
        })
          .then((r) => {
            resolve(r.data)
          })
          .catch((e) => {
            if (!axios.isCancel(e)) {
              DefaultErrorHandler($, e, reject)
            } 
          })
      })
    },
    create($, form) {
      // POST /admin/users/
      form = {
        merchant: form.merchant, // slug
        username: form.username,
        email: form.email,
        first_name: form.first_name,
        last_name: form.last_name,
        company: $.$store.state.company,
        dashboard_default_period: form.dashboard_default_period,
      }
      return new Promise((resolve, reject) => {
        return HTTP.post('/admin/users/', form)
          .then((r) => {
            $.$store.commit('success', 'Utilisateur Créé !')
            resolve(r.data)
          })
          .catch((e) => {
            DefaultErrorHandler($, e, reject)
            reject(e)
          })
      })
    },
    update($, form, id) {
      // PUT /admin/users/
      // PUT /admin/company/
      form = {
        merchant:
          typeof form.merchant === 'object' ? form.merchant.id : form.merchant,
        username: form.username,
        email: form.email,
        first_name: form.first_name,
        last_name: form.last_name,
        dashboard_default_period: form.dashboard_default_period,
      }
      return new Promise((resolve, reject) => {
        return HTTP.patch(`/admin/users/${id}/`, form)
          .then((r) => {
            $.$store.commit('success', 'Utilisateur mis a jour')
            resolve(r.data)
          })
          .catch((e) => {
            DefaultErrorHandler($, e, reject)
            reject(e)
          })
      })
    },
    delete($, id) {
      // DELETE /admin/users/:id
      // DELETE /admin/company/:id
      return new Promise((resolve, reject) => {
        return HTTP.delete(`/admin/users/${id}/`)
          .then((r) => {
            $.$store.commit('success', 'Utilisateur supprimé')
            resolve(r.data)
          })
          .catch((e) => {
            DefaultErrorHandler($, e, reject)
            reject(e)
          })
      })
    },
  },
  Organism: {
    get($, id) {
      return new Promise((resolve, reject) => {
        const msg = 'Api non implémenté'
        $.$store.commit('error', { msg: msg, color: 'error' })
        reject(new Error(msg))
      })
    },
    list($) {
      return new Promise((resolve, reject) => {
        const organisms = [
          {
            name: 'BNP1',
            type: 'PSPS',
            slug: 'bnp1',
            companies: [],
          },
        ]
        resolve({ results: organisms })
        // const msg = 'Api non implémenté'
        // $.$store.commit('error', { msg: msg, color: 'error' })
        // reject(new Error(msg))
      })
    },
    delete($, id) {
      return new Promise((resolve, reject) => {
        const msg = 'Api non implémenté'
        $.$store.commit('error', { msg: msg, color: 'error' })
        reject(new Error(msg))
      })
    },
    update($, id, form) {
      return new Promise((resolve, reject) => {
        const msg = 'Api non implémenté'
        $.$store.commit('error', { msg: msg, color: 'error' })
        reject(new Error(msg))
      })
    },
    create($, form) {
      return new Promise((resolve, reject) => {
        const msg = 'Api non implémenté'
        $.$store.commit('error', { msg: msg, color: 'error' })
        reject(new Error(msg))
      })
    },
  },
}
