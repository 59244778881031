import { render, staticRenderFns } from "./ManageMerchantDatasets.vue?vue&type=template&id=b4a8c174&scoped=true&"
import script from "./ManageMerchantDatasets.vue?vue&type=script&lang=js&"
export * from "./ManageMerchantDatasets.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4a8c174",
  null
  
)

export default component.exports