<template>
  <v-card
    class="mb-3"
    :to="{ name: 'Organisme', params: { organism: organism.id } }"
  >
    <v-card-text class="pa-4">
      <v-menu bottom left offset-y>
        <template v-slot:activator="{ on }">
          <v-btn class="float-right" icon v-on="on" @click.stop @click.prevent>
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item-group>
            <v-list-item
              :to="{ name: 'Organisme', params: { organism: organism.id } }"
            >
              <v-list-item-icon>
                <v-icon>mdi-pencil</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Editer</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              :disabled="organism.merchants_count > 0"
              @click.stop.prevent="remove(organism)"
            >
              <v-list-item-icon>
                <v-icon>mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Supprimer</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-row class="mt-0">
        <v-col cols="5" sm="5" md="4" lg="3" class="text-center">
          <v-avatar color="grey lighten-5">
            <v-icon color="blue">
              {{ types[organism.type].icon }}
            </v-icon>
          </v-avatar>
        </v-col>
        <v-col>
          <v-row>
            <v-col
              class="text-h5 pt-1 text-truncate blue--text  text-uppercase"
            >
              {{ organism.name }}
            </v-col>
            <v-col cols="12" class="py-0 my-0">
              <div class="subtitle">
                {{ types[organism.type].label }}
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-divider class="mt-3" />
      <v-row class="text-center mt-0">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-col v-on="on">
              <v-icon large>
                mdi-content-copy
              </v-icon>
              <div class="mt-2">
                {{ organism.related_files_count }}
              </div>
            </v-col>
          </template>
          <span>Total fichiers importés</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-col v-on="on">
              <v-icon large>
                mdi-shield-account-outline
              </v-icon>
              <div class="mt-2">{{ organism.merchants_count }}</div>
            </v-col>
          </template>
          <span>Nombre de marchands connectés</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-col v-on="on">
              <v-icon large>
                mdi-file-check-outline
              </v-icon>
              <div class="mt-2">
                <span v-if="organism.last_uploaded_file_date">{{
                  formatDate(organism.last_uploaded_file_date)
                }}</span>
                <span v-else>-</span>
              </div>
            </v-col>
          </template>
          <span>Date du dernier fichier</span>
        </v-tooltip>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment'
import { ORGANISM_TYPES } from '@/utils/constants'
export default {
  props: {
    organism: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      types: ORGANISM_TYPES,
    }
  },
  methods: {
    formatDate(d) {
      return moment(d).format('YYYY-MM-DD')
    },
    remove() {
      this.$emit('remove')
    },
  },
}
</script>

<style></style>
