<template>
  <v-container fluid>
    <top-header title="Marchands">
      <template v-slot:SideMenu>
        <v-btn
          color="white primary--text"
          :to="{ path: '/backoffice/create/marchand' }"
        >
          <v-icon left>
            mdi-plus
          </v-icon>
          Ajouter
        </v-btn>
      </template>
    </top-header>
    <v-row>
      <list-merchants />
    </v-row>
  </v-container>
</template>

<script>
import TopHeader from '@/components/base/TopHeader'
import ListMerchants from '@/components/bnc/ListMerchants'
export default {
  name: 'Marchands',
  components: {
    ListMerchants,
    TopHeader,
  },
  data() {
    return {
      selectedMerchant: null,
    }
  },
}
</script>

<style scoped></style>
