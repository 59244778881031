module.exports = {
  app: {
    name: "BNC APP by Himydata",
    description:
      "Accompagner et former les commerçants sur la gestion des paiements",
    url: "http://www.baudon-nortier-consulting.com/fr/"
  },
  drawer: {
    bgColor: {
      admin: "#E5F1FA",
      default: "#E5F1FA"
    }
  }
};
