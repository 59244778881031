<template>
  <v-app light class="no-border">
    <v-toolbar>
      <v-toolbar-title>
        <v-img lazy-load height="38px" src="@/assets/bnc.png" contain />
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="">
        <v-btn text to="/signin">Sign in</v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-container fill-height grid-list-lg justify-center>
      <v-row>
        <v-col cols="12"><h1>Reset your password</h1></v-col>
        <v-col cols="12">
          <v-text-field
            label="Mot de passe"
            type="password"
            v-model="$v.resetForm.password1.$model"
            :error-messages="errors.password1"
            required
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            type="password"
            v-model="$v.resetForm.password2.$model"
            label="Confirm password"
            :error-messages="errors.password2"
            @keyup.enter="submit"
            required
          />
        </v-col>
        <v-col cols="12">
          <v-btn
            large
            @click="submit"
            @keyup.enter="submit"
            class="mb-3"
            :disabled="$v.resetForm.$invalid | loaderSaveButton"
            :loading="loaderSaveButton"
            color="success"
            >Save</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { login } from '@/utils/auth'
import AccountService from '@/services/AccountService'
import { validationMixin } from 'vuelidate'
import {
  required,
  url,
  numeric,
  alpha,
  sameAs,
  minLength,
} from 'vuelidate/lib/validators'

export default {
  name: 'ResetPassword',
  mixins: [validationMixin],
  validations: {
    resetForm: {
      password1: {
        required,
        minLength: minLength(8),
      },
      password2: {
        required,
        sameAsP1: sameAs(function() {
          return this.resetForm.password1
        }),
        minLength: minLength(8),
      },
    },
  },
  data: function() {
    return {
      resetForm: {
        password1: '',
        password2: '',
      },
      loaderSaveButton: false,
    }
  },
  mounted: function() {
    this.clear()
  },
  computed: {
    errors() {
      const errors = {
        password1: [],
        password2: [],
      }

      this.$v.resetForm.password1.$dirty &&
        !this.$v.resetForm.password1.required &&
        errors.password1.push('Le mot de passe est requis')

      this.$v.resetForm.password2.$dirty &&
        !this.$v.resetForm.password2.required &&
        errors.password2.push('La répétition du mot de passe est requise')

      this.$v.resetForm.password1.$dirty &&
        !this.$v.resetForm.password1.minLength &&
        errors.password1.push(
          'Le mot de passe doit être plus long que 8 caractères'
        )
      this.$v.resetForm.password2.$dirty &&
        !this.$v.resetForm.password2.minLength &&
        errors.password2.push(
          'Le mot de passe doit être plus long que 8 caractères'
        )

      this.$v.resetForm.password2.$dirty &&
        !this.$v.resetForm.password2.sameAsP1 &&
        errors.password2.push('Les mots de passe ne sont pas identiques')

      return errors
    },
  },
  methods: {
    submit() {
      if (!this.$v.resetForm.$invalid) {
        this.loaderSaveButton = true
        AccountService.sendNewPassword(
          this,
          this.resetForm.password1,
          this.resetForm.password2,
          this.$route.params.uid,
          this.$route.params.key
        )
          .then(r => {
            this.loaderSaveButton = false
          })
          .catch(error => {
            this.loaderSaveButton = false
          })
      }
    },
    clear() {
      this.$v.resetForm.$reset()
    },
  },
}
</script>
