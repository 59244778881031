import { HTTP, DefaultErrorHandler } from '@/utils/http-common'
import _ from 'lodash'
import store from '@/store'

export default {
  global_list(parent) {
    return HTTP.get('/dashboard/manage/').catch(function(error) {
      parent.$store.commit('push', {
        msg: 'Error ' + error.response.data,
        color: 'error',
      })
    })
  },
  global_delete(parent, id) {
    return HTTP.delete(`/dashboard/manage/${id}/`).catch(function(error) {
      parent.$store.commit('push', {
        msg: 'Error ' + error.response.data,
        color: 'error',
      })
    })
  },
  list(parent, project) {
    return HTTP.get(`/dashboard/manage/${project.id}/dashboard/`).catch(
      function(error) {
        parent.$store.commit('push', {
          msg: 'Error ' + error.response.data,
          color: 'error',
        })
      }
    )
  },
  delete(parent, id) {
    return HTTP.delete(`/dashboard/manage/${id}/`).catch(function(error) {
      parent.$store.commit('push', {
        msg: 'Error ' + error.response.data,
        color: 'error',
      })
    })
  },
  create(parent, project, dashboard) {
    return HTTP.post('/dashboard/manage/', {
      is_global: false,
      name: dashboard.name,
      project_id: project.id,
    }).catch(function(error) {
      parent.$store.commit('push', {
        msg:
          'Error :  \n' +
          _.join(
            _.map(error.response.data, (e, index, name) => {
              return `* ${_.join(e, '\n* ')}`
            }),
            '\n'
          ),
        color: 'error',
      })
    })
  },
  get(parent, id) {
    return HTTP.get(`/dashboard/manage/${id}/`).catch(function(error) {
      parent.$store.commit('push', {
        msg: 'Error ' + error.response.data,
        color: 'error',
      })
    })
  },
  update(parent, dashboard, id) {
    return new Promise((resolve, reject) => {
      return HTTP.put(`/dashboard/manage/${id}/`, dashboard)
        .then(res => {
          return resolve(res)
        })
        .catch(error => {
          parent.$store.commit('push', {
            msg: error.response.data.error,
            color: 'error',
          })
          return reject(error)
        })
    })
  },
  update_widget(parent, widget) {
    return HTTP.put(`/widget/manage/${widget.id}/`, widget).catch(function(
      error
    ) {
      parent.$store.commit('push', {
        msg: 'Error ' + error.response.data,
        color: 'error',
      })
    })
  },
  create_widget(parent, dashboard, widget) {
    return HTTP.post(`/dashboard/manage/${dashboard.id}/widget/`, widget).catch(
      function(error) {
        parent.$store.commit('push', {
          msg: 'Error ' + error.response.data,
          color: 'error',
        })
      }
    )
  },
  delete_widget(parent, widget) {
    return HTTP.delete(`/widget/manage/${widget.id}/`).catch(function(error) {
      parent.$store.commit('push', {
        msg: 'Error ' + error.response.data,
        color: 'error',
      })
    })
  },

  getAllWidgets($, dashboardName, forcedUser) {
    let url = `/bnc/merchant/${store.getters.merchantId}/dashboard/${dashboardName}/`
    if (forcedUser) {
      url += `${forcedUser}/`
    }
    return new Promise((resolve, reject) => {
      HTTP.get(url)
        .then(response => resolve(response.data))
        .catch(err => DefaultErrorHandler($, err, reject))
    })
  },

  getAvailableVisualizations($, dashboardName, forcedUser) {
    let url = `bnc/merchant/${store.getters.merchantId}/dashboard/${dashboardName}/available_visualizations/`
    if (forcedUser) {
      url += `${forcedUser}/`
    }
    return new Promise((resolve, reject) => {
      HTTP.get(url)
        .then(response => resolve(response.data))
        .catch(err => DefaultErrorHandler($, err, reject))
    })
  },

  getVisualization($, visualizationId) {
    return new Promise((resolve, reject) => {
      HTTP.get(`visualizations/${visualizationId}/`)
        .then(response => resolve(response.data))
        .catch(err => DefaultErrorHandler($, err, reject))
    })
  },
}
