<template>
  <v-row class="pa-2">
    <v-col cols="12" class="d-flex justify-space-between">
      <div class="text-h5">{{ $t('evaluationRulesManagement') }}</div>
      <!-- <div>
        <KpiRuleReportDialog>
          <template v-slot:activator="{ on }">
            <v-btn class="mr-3" v-on="on" color="primary" text
              ><v-icon left>mdi-email-check-outline</v-icon> Envoi de
              rapport</v-btn
            >
          </template>
        </KpiRuleReportDialog>
      </div> -->
    </v-col>

    <v-col cols="12">
      <v-data-table
        hide-default-footer
        :headers="headers"
        :items="items"
        :loading="isLoading"
      >
        <template v-slot:item.name="{ item }">
          <div>
            <div>
              <span>
                {{ getTranslation(item.name) }}
              </span>
            </div>
          </div>
        </template>
        <template v-slot:item.tendency_comparator="{ item }">
          <div class="rules" v-if="item.tendency_comparator">
            <div
              class="rule"
              v-for="(r, i) in item.tendency_comparator.color_rules"
              :key="i"
            >
              <span class="inline-block elevation-2 rule-icon">
                <v-icon v-if="r.icon">{{ r.icon }}</v-icon>
                <v-icon v-else>mdi-minus</v-icon>
              </span>
              <span
                class="inline-block elevation-2 rule-color"
                :style="{ backgroundColor: r.color }"
              ></span>
              <span
                class="inline-block rule-formula"
                v-html="styleFormula(r.formula)"
              ></span>
            </div>
          </div>
        </template>

        <template v-slot:item.value_comparator="{ item }">
          <div class="rules" v-if="item.value_comparator">
            <div
              class="rule"
              v-for="(r, i) in item.value_comparator.color_rules"
              :key="i"
            >
              <span class="inline-block elevation-2 rule-icon">
                <v-icon v-if="r.icon">{{ r.icon }}</v-icon>
                <v-icon v-else>mdi-circle-off-outline</v-icon>
              </span>
              <span
                class="inline-block elevation-2 rule-color"
                :style="{ backgroundColor: r.color }"
              ></span>
              <span
                class="inline-block rule-formula"
                v-html="styleFormula(r.formula)"
              ></span>
            </div>
          </div>
        </template>
        <template v-slot:item.action="{ item }">
          <CreateUpdateKpiRuleDialog :kpi="item" @updated="listKpis">
            <template v-slot:activator="{ on }">
              <v-btn fab x-small dark color="primary" class="mr-3" v-on="on">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
          </CreateUpdateKpiRuleDialog>
        </template>
        <template v-slot:item.enabled="{ item }">
          <div class="d-flex justify-center">
            <v-switch
              v-model="item.enabled"
              label=""
              color="success"
              hide-details
              class="my-1"
              v-on:change="switchKPI(item)"
            ></v-switch>
          </div>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
// import { patchKpiList } from '@/utils'
// import KpiRuleReportDialog from './KpiRuleReportDialog.vue'
import CreateUpdateKpiRuleDialog from './CreateUpdateKpiRuleDialog.vue'
import numeral from '@/plugins/numeral'
import { toNumber, isEmpty, groupBy, map } from 'lodash'
import { KpiService } from '@/services'

export default {
  components: {
    CreateUpdateKpiRuleDialog,
    // KpiRuleReportDialog,
  },
  name: 'kpi-rule-settings',

  data() {
    return {
      isLoading: true,
      headers: [
        {
          text: 'KPI',
          value: 'name',
          align: 'left',
          sortable: false,
        },
        {
          text: this.$t('kpiRules'),
          value: 'value_comparator',
          align: 'left',
          sortable: false,
        },
        // {
        //   text: 'Règles Tendance',
        //   value: 'tendency_comparator',
        //   align: 'left',
        //   sortable: false,
        // },
        {
          text: 'Actions',
          value: 'action',
          align: 'right',
          sortable: false,
        },
        {
          text: 'Status',
          value: 'enabled',
          align: 'center',
          sortable: false,
        },
      ],
      items: [],
    }
  },
  created() {
    this.listKpis()
  },
  computed: {
    translations() {
      let dict = {}
      dict[this.$t('evolutionCollectedVolume', 'fr')] = this.$t(
        'evolutionCollectedVolume'
      )
      dict[this.$t('evolutionTotalRateCommission', 'fr')] = this.$t(
        'evolutionTotalRateCommission'
      )
      dict[this.$t('acceptanceRateNetWithoutDup', 'fr')] = this.$t(
        'acceptanceRateNetWithoutDup'
      )
      dict[this.$t('collectedVolume', 'fr')] = this.$t('collectedVolume')
      dict[this.$t('vccRate', 'fr')] = this.$t('vccRate')
      dict[this.$t('averageCommissionRate', 'fr')] = this.$t(
        'averageCommissionRate'
      )
      dict[this.$t('corporateRate', 'fr')] = this.$t('corporateRate')
      dict[this.$t('ratesUnpaid', 'fr')] = this.$t('ratesUnpaid')
      dict[this.$t('averageInterchangeRate', 'fr')] = this.$t(
        'averageInterchangeRate'
      )
      dict[this.$t('transactionsVolume', 'fr')] = this.$t('transactionsVolume')

      return dict
    },
  },
  methods: {
    isEmpty,
    listKpis() {
      this.isLoading = true
      KpiService.get(this)
        .then((r) => {
          this.items = r.results
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    numeral,
    toNumber,
    styleFormula(f) {
      const formula = f
        .toUpperCase()
        .split('')
        .reduce((prev, curr) => {
          let append = ''
          if (['M', 'R'].includes(curr))
            append = `<span class='formula-parameter ${
              curr == 'M' ? 'current' : 'reference'
            }'>${curr}</span>`
          else append = curr
          return prev + append
        }, '')

      // let spacedFormula = formula.reduce((prev, curr) => {
      //   let append = ''
      //   if (['<', '>','<=','>=','*','+','-','/'].includes(curr))
      //     append = `<span class='formula-parameter ${
      //       curr == 'M' ? 'current' : 'reference'
      //     }'>${curr}</span>`
      //   else append = curr
      //   return prev + append
      // }, '')
      return formula
    },

    switchKPI(item) {
      KpiService.kpi.update(this, item.pk, { enabled: item.enabled })
    },
    getTranslation(val) {
      let translation = val

      if (this.translations[val]) {
        translation = this.translations[val]
      }

      return translation
    },
  },
}
</script>

<style lang="scss">
.rules {
  .rule {
    justify-content: left;
    margin: 10px 0px;
    display: flex;
    align-items: center;
    & > span {
      margin: 0px 5px;
    }
    .rule-color,
    .rule-icon {
      border-radius: 5px;
      width: 25px;
      height: 25px;
      display: inline-block;
    }
    .rule-formula {
      text-align: center;
      display: inline-block;
      margin-left: 15px !important;
      .formula-parameter {
        border-radius: 5px;
        padding: 2px;
        margin: 0px 5px;
        width: 25px;
        height: 25px;
        display: inline-block;
        color: white;

        &.reference {
          background-color: #8b8b8b;
        }
        &.current {
          background-color: #3a77ff;
        }
      }
    }
  }
}
</style>
