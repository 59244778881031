<template>
  <v-container fluid>
    <v-row>
      <dataset-crud
        title="Gestion des devises"
        :dataset="$store.state.devises"
      ></dataset-crud>
    </v-row>
  </v-container>
</template>

<script>
import DatasetCrud from '@/components/hmd/Dataset/DatasetCrud'
export default {
  components: {
    DatasetCrud,
  },
  name: 'Devises',
}
</script>

<style>
.v-data-table__wrapper {
  border-radius: 8px !important;
}
</style>
