<template>
  <v-container
    class="dash"
    fluid
    :style="{
      'padding-right': $store.state.filtersDrawerExpanded
        ? !$vuetify.breakpoint.smAndDown
          ? '320px'
          : '20px'
        : '12px',
    }"
  >
    <v-row no-gutters>
      <v-col cols="12" class="d-flex justify-end">
        <v-btn
          dark
          color="blue-grey lighten-2"
          class="d-print-none text-capitalize"
          @click="handleToggleFilters(true)"
        >
          <v-icon> mdi-filter-outline</v-icon>
          {{ $t('filters') }}
        </v-btn>
        <v-btn
          color="primary"
          class="d-print-none text-capitalize ml-2"
          @click="print"
        >
          <v-icon left>mdi-printer-outline</v-icon>
          {{ $t('print') }}
        </v-btn>
      </v-col>
    </v-row>
    <core-app-bar />
    <v-row>
      <v-col cols="12">
        <visualization
          card
          type="bar"
          query="taux_impayes_preleves"
          :translations="translations"
          :project="$store.getters.merchantId"
          :title="$t('rateOfDelinquenciesPerMonth')"
          :options="{
            xAxes: ['Mois'],
            yAxes: [$t('rate')],
            dataLabels: {
              formatter: percentFormatter,
            },
            colors: ['#32325D'],
          }"
          :external-filters-values="
            objectToArray(['reference_date', 'event_motive_label'])
          "
          :forcedUser="$store.state.preview ? $store.state.preview.pk : null"
          width="100%"
          height="auto"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <TCDImpayes
          :filters="objectToArray()"
          :data="impayesTCD"
          class="unpaid"
        />
      </v-col>
    </v-row>
    <FiltersDrawer :filters="filters()" @update="handleFilterValueUpdate" />
  </v-container>
</template>

<script>
import { groupBy } from 'lodash'
import TCDImpayes from '@/components/bnc/TCDImpayes'
import FiltersDrawer from '@/components/inputs/FiltersDrawer.vue'
import { palette1 } from '@/utils/theme-chart.js'
export default {
  name: 'Impayes',
  components: {
    FiltersDrawer,
    TCDImpayes,
    CoreAppBar: () => import('@/components/layouts/core/AppBar'),
  },
  data() {
    return {
      impayesTCD: [],
      globalFilters: [],
      paletteChart: palette1,
    }
  },
  mounted() {
    var thead = document.querySelector('.unpaid .has-gutter')
    var th = document.createElement('th')
    th.colSpan = 11
    th.style.textAlign = 'center'
    th.style.paddingTop = '20px'
    th.style.color = '#32325D'

    th.style.height = '10px'

    var newContent = document.createTextNode('Mois de réception')
    th.appendChild(newContent)

    thead.insertBefore(th, thead.firstChild)
  },
  computed: {
    translations() {
      let dict = {}

      dict[this.$t('rate', 'fr')] = this.$t('rate')

      return dict
    },
  },
  methods: {
    async print() {
      window.print()
    },
    objectToArray(exclude = []) {
      return Object.keys(this.globalFilters)
        .filter((v) => !exclude.includes(v))
        .map((v) => this.globalFilters[v][0])
    },
    handleFilterValueUpdate(v) {
      this.globalFilters = groupBy(v, 'column')
    },
    handleToggleFilters(v) {
      this.$store.commit('setFiltersDrawerExpanded', v)
    },
    adaptDatasetName(name) {
      return `consolidation_${name}_${this.lo
        .snakeCase(this.$store.getters.merchantName)
        .replace('_1', '1') // ToDo: This is an ugly fix. To improve
        .replace('_2', '2')
        .replace('_3', '3')
        .replace('_4', '4')
        .replace('_5', '5')
        .replace('_6', '6')
        .replace('_7', '7')
        .replace('_8', '8')
        .replace('_9', '9')}`
    },
    filters() {
      return [
        {
          name: 'reference_date',
          type: 'month',
          multiple: true,
          label: this.$t('month', 'en'),
          values: {
            project: this.$store.getters.merchantId,
            column: 'reference_date',
            dataset: this.adaptDatasetName('acquereurs'),
          },
          value: '',
        },
        {
          name: 'organism',
          type: 'checkbox',
          multiple: true,
          label: this.$t('serviceProvider', 'en'),
          values: {
            project: this.$store.getters.merchantId,
            column: 'organism',
            datasets: [
              this.adaptDatasetName('acquereurs'),
              this.adaptDatasetName('impayes'),
            ],
            conditionName: 'conditions',
          },
        },
        {
          name: 'sales_channel',
          type: 'list',
          multiple: true,
          label: this.$t('channel', 'en'),
          values: {
            project: this.$store.getters.merchantId,
            column: 'sales_channel',
            datasets: [
              this.adaptDatasetName('acquereurs'),
              this.adaptDatasetName('impayes'),
            ],
            conditionName: 'conditions',
          },
        },
        {
          name: 'merchant_ref_2',
          type: 'list',
          multiple: true,
          label: this.$t('merchantRef', 'en'),
          values: {
            project: this.$store.getters.merchantId,
            column: 'merchant_ref_2',
            datasets: [
              this.adaptDatasetName('acquereurs'),
              this.adaptDatasetName('impayes'),
            ],
            conditionName: 'conditions',
          },
        },
        {
          name: 'event_motive_label',
          type: 'list',
          multiple: true,
          label: this.$t('eventReason', 'en'),
          values: {
            project: this.$store.getters.merchantId,
            column: 'event_motive_label',
            dataset: this.adaptDatasetName('impayes'),
            conditionName: 'conditions',
          },
        },
      ]
    },
    thousandsSeparator(v) {
      return v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    percentFormatter(v) {
      return String(v) + ' %'
    },
  },
}
</script>

<style>
.fixed {
  z-index: 999;
  position: sticky;
  position: -webkit-sticky;
  top: 88px;
  /* width: calc(100% - 260px); */
}

/* HMD Table Format */
.unpaid .hmd-visualization-table .el-table__body-wrapper.is-scrolling-left {
  height: 100% !important;
}

.unpaid table.hmd-visualization-table tbody td {
  width: 80px;
}

.unpaid table.hmd-visualization-table tbody td {
  border-left: solid 1px #ebeef5;
}

.unpaid table.hmd-visualization-table tbody td:hover {
  background-color: #3ca0dc !important;
  color: white;
  font-weight: 500;
  cursor: pointer;
}

.unpaid table.hmd-visualization-table tbody td:first-child,
.el-table thead th {
  background-color: #f5f7fa;
  color: #909399;
  font-weight: 500;
}

.unpaid table.hmd-visualization-table tbody td {
  text-align: right;
  font-size: 1.1em;
}

.unpaid table.hmd-visualization-table th {
  text-align: center;
}

.unpaid table.hmd-visualization-table td:last-child {
  text-align: center !important;
}

.unpaid table.hmd-visualization-table th {
  font-size: 0.9em !important;
}

.unpaid table.hmd-visualization-table tr:last-child,
.unpaid table.hmd-visualization-table tr:nth-last-child(2) {
  font-size: 1.1em;
}

.unpaid table.hmd-visualization-table tr:last-child td:first-child,
.unpaid table.hmd-visualization-table tr:last-child,
.unpaid table.hmd-visualization-table tr:nth-last-child(2) td:first-child,
.unpaid table.hmd-visualization-table tr:nth-last-child(2) {
  background-color: #f5f7fa !important;
  font-weight: 500;
}

.unpaid table.hmd-visualization-table td:last-child,
.unpaid table.hmd-visualization-table td:nth-last-child(3),
.unpaid table.hmd-visualization-table td:nth-last-child(2) {
  background-color: #f5f7fa !important;
}

.unpaid table.hmd-visualization-table td:first-child,
.el-table thead th {
  background-color: #f5f7fa;
  color: #909399;
  font-weight: 500;
}

.unpaid table.hmd-visualization-table tr td:nth-last-child(1) {
  font-size: 1.1em !important;
  font-weight: 500 !important;
}
</style>
