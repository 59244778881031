/* eslint-disable space-before-function-paren */
import { HTTP, DefaultErrorHandler } from "@/utils/http-common";
import { isPlainObject } from "lodash";
const HMD_PARTNERS = process.env.HMD_PARTNERS || "sandbox";

export default {
  list($) {
    return new Promise((resolve, reject) => {
      HTTP.get("/bnc/contract/manage/")
        .then(r => {
          resolve(r.data);
        })
        .catch(err => DefaultErrorHandler($, err, reject));
    });
  },
  get($, id) {
    /**
     * id {String} : id du contrat
     */
    return new Promise((resolve, reject) => {
      HTTP.get(`/bnc/contract/manage/${id}/`)
        .then(r => {
          resolve(r.data);
        })
        .catch(err => DefaultErrorHandler($, err, reject));
    });
  },
  create($, form) {
    return new Promise((resolve, reject) => {
      HTTP.post("/bnc/contract/manage/", form)
        .then(r => {
          $.$store.commit("success", "Contrat créé !");
          resolve(r);
        })
        .catch(err => DefaultErrorHandler($, err, reject));
    });
  },
  update($, id, form) {
    if (isPlainObject(form.organism)) form.organism = form.organism.id;
    return new Promise((resolve, reject) => {
      HTTP.put(`/bnc/contract/manage/${id}/`, form)
        .then(r => {
          $.$store.commit("success", "Contrat mis a jour");
          resolve(r.data);
        })
        .catch(err => DefaultErrorHandler($, err, reject));
    });
  },
  delete($, id) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/bnc/contract/manage/${id}/`)
        .then(r => {
          $.$store.commit("success", "Contrat supprimé");
          resolve(r.data);
        })
        .catch(err => DefaultErrorHandler($, err, reject));
    });
  }
};
