<template>
  <v-select
    :items="organisms"
    v-model="selectedValue"
    item-text="name"
    return-object
    :multiple="multiple"
    :outlined="outlined"
    :error-messages="errorMessages"
    :label="$t('organism')"
    @input="handleInput"
    @blur="$emit('blur')"
  ></v-select>
</template>

<script>
import { OrganismService } from '@/services'
import MerchantService from '@/services/MerchantService'
export default {
  name: 'OrganismInput',
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: null,
    },
    merchant: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selectedValue: null,
      organisms: [],
    }
  },
  methods: {
    handleInput() {
      this.$emit('input', this.selectedValue)
    },
    retrieveOrganisms() {
      MerchantService.getOrganisms(this, this.merchant)
        .then((results) => {
          this.organisms = results.map((v) => ({
            name: v.name,
            id: v.id,
          }))
        })
        .catch((error) => {
          this.$emit('error_merchant')
        })
    },
  },
  watch: {
    value(newValue, oldValue) {
      this.selectedValue = newValue !== null ? newValue.name : null
    },
    merchant() {
      if (this.merchant) {
        this.retrieveOrganisms()
      }

      // If the merchant change, reset the selected organism
      this.selectedValue = null
      this.handleInput()
    },
  },
  mounted() {
    // Retrieve organisms from the merchant
    if (this.merchant) {
      this.retrieveOrganisms()
    }
  },
}
</script>

<style lang="scss" scoped></style>
