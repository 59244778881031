<template>
  <v-card style="height:200px;">
    <v-progress-circular
      class="absolute-center"
      indeterminate
      color="primary"
    ></v-progress-circular>
  </v-card>
</template>

<script>
export default {};
</script>

<style scoped>
.absolute-center {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
</style>
