<template>
  <div>
    <!-- <v-fade-transition mode="in-out" v-if="$vuetify.breakpoint.smAndDown">
      <v-overlay
        v-show="$store.state.customDrawerExpanded"
        absolute
        z-index="99"
      >
      </v-overlay>
    </v-fade-transition> -->
    <v-expand-x-transition mode="in-out">
      <v-card class="custom-drawer" v-show="$store.state.customDrawerExpanded">
        <v-navigation-drawer permanent right :style="styles">
          <template>
            <div class="pr-9 py-5 text-center p-relative mb-5">
              <v-btn color="blue accent-3" absolute offset-y="5" float top left icon @click.stop="handleToggle(false)">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
              <span>
                <span class="primary--text">{{ $t('myBoards') }}</span>
              </span>
            </div>
          </template>
          <v-container>
            <v-row class="d-flex justify-center">
              <v-progress-circular color="primary" indeterminate :size="50" style="margin-top: 100%" v-if="loading">
              </v-progress-circular>
            </v-row>
            <el-collapse v-if="!loading" v-model="isOpenVisualization">
              <el-collapse-item v-for="title in Object.keys(available_visualizations)" :key="title" :title="title"
                :name="title">
                <v-card v-for="(item, i) in available_visualizations[title]" :key="item.id" class="ma-3 pa-2" outlined
                  elevation="2" style="cursor: pointer" @click="addWidget(title, i)">
                  <v-row>
                    <v-col cols="12" class="d-flex justify-end">
                      <v-card-text class="
                                                                    primary--text
                                                                    text-subtitle-2
                                                                    font-weight-medium
                                                                    pa-0
                                                                    ma-0
                                                                  ">
                        {{ item.translation }}
                      </v-card-text>
                      <v-spacer></v-spacer>
                      <v-icon class="mr-2" color="accent" large>
                        mdi-plus-circle
                      </v-icon>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="ma-2 py-0">
                    <v-col cols="12" class="d-flex justify-left">
                      <v-avatar color="blue-grey lighten-3" v-if="item.type == 'kpi'" size="35">
                        <v-icon dark size="28">
                          mdi-emoticon-happy-outline
                        </v-icon>
                      </v-avatar>
                      <v-icon size="30" class="ml-5">{{
                        item.type == 'line'
                        ? 'mdi-chart-line'
                        : item.type == 'counter'
                          ? 'mdi-counter'
                          : 'mdi-sort-numeric-descending'
                      }}
                      </v-icon>
                    </v-col>
                    <v-col></v-col>
                  </v-row>
                </v-card>
              </el-collapse-item>
            </el-collapse>
            <!-- <v-expansion-panels accordion flat>
              <v-expansion-panel v-for="(item, i) in 5" :key="i">
                <v-expansion-panel-header>Item</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card v-for="(iterm, y) in 5" :key="y">
                    coucou
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels> -->
          </v-container>
        </v-navigation-drawer>
      </v-card>
    </v-expand-x-transition>
  </div>
</template>

<script>
import { Collapse, CollapseItem } from 'element-ui'
import DashboardService from '@/services/DashboardService.js'
import WIDGET_SHORCUT from '@/components/UIComponents/WidgetShorcuts.vue'
import { HOME_KPIS_DYNAMIC_MAPPING } from '@/utils/constants.js'
import { translateLabel } from "@/utils/translations.js"
import { mapState } from 'vuex'

import _ from 'lodash'
export default {
  props: {
    widgets: {
      type: Array,
      default: () => [],
    },
  },
  data: () => {
    return {
      available_visualizations: [],
      loading: false,
      isOpenVisualization: [],
      newVisualization: {},
    }
  },

  components: {
    'el-collapse': Collapse,
    'el-collapse-item': CollapseItem,
  },
  mounted() {
    this.init()
  },
  watch: {
    '$store.state.language.name': function () {
      this.init()
    }
  },
  computed: {
    forcedUser() {
      return this.$store.state.preview ? this.$store.state.preview.pk : null
    },
    styles() {
      return {
        background: '#F3F3F7',
        width: '320px',
      }
    },
  },
  methods: {
    handleToggle(v) {
      this.$store.commit('setCustomDrawerExpanded', v)
    },
    addWidget(title, index) {
      this.$emit('add', this.available_visualizations[title][index])
      this.available_visualizations[title].splice(index, 1)
    },

    // addWidgetAfterRemoved(el) {
    //   this.newVisualization = {
    //     id: el.visualization.id,
    //     label: el.visualization.label,
    //     type: el.visualization.type,
    //     isDeleted: true,
    //     visualization: el,
    //   }

    //   // Indicateurs
    //   if (this.newVisualization.type == 'kpi') {
    //     this.available_visualizations['Indicateurs'].push(this.newVisualization)
    //   }

    //   // chartsAndGraphs
    //   if (this.newVisualization.type == 'line') {
    //     this.available_visualizations[this.$t("chartsAndGraphs")].push(
    //       this.newVisualization
    //     )
    //   }
    // },

    hook_kpis_mapping(visualizations) {
      var available_visualizations = {}
      available_visualizations[this.$t('acquirerKpis')] = []
      available_visualizations[this.$t('pspKpis')] = []
      available_visualizations[this.$t('chartsAndGraphs')] = []


      var sorted = _(visualizations)
        .toPairs()
        .orderBy([1], ['desc'])
        .fromPairs()
        .value()
      // Process this.$t("chartsAndGraphs")
      visualizations[this.$t("chartsAndGraphs", "fr")]?.forEach((elem) => {
        elem["translation"] = translateLabel(elem.label, this)
        available_visualizations[this.$t("chartsAndGraphs")].push(elem)
      })

      // Process Indicators
      visualizations[this.$t("indicators", "fr")]?.forEach((elem) => {
        elem["translation"] = translateLabel(elem.label, this)
        if (HOME_KPIS_DYNAMIC_MAPPING.psp.includes(elem.label)) {
          available_visualizations[this.$t("pspKpis")].push(elem)
        } else {
          available_visualizations[this.$t("acquirerKpis")].push(elem)
        }
      })

      return available_visualizations
    },

    init() {
      let t = this
      this.loading = true

      DashboardService.getAvailableVisualizations(
        this,
        'index',
        this.forcedUser
      )
        .then((r) => {
          t.available_visualizations = this.hook_kpis_mapping(
            r.available_visualizations
          )

          if (_.isEmpty(t.available_visualizations[this.$t("acquirerKpis")])) {
            t.$set(t.available_visualizations, this.$t("acquirerKpis"), [])
          }
          if (_.isEmpty(t.available_visualizations[this.$t("pspKpis")])) {
            t.$set(t.available_visualizations, this.$t("pspKpis"), [])
          }

          if (_.isEmpty(t.available_visualizations[this.$t("chartsAndGraphs")])) {
            t.$set(t.available_visualizations, this.$t("chartsAndGraphs"), [])
          }

          this.isOpenVisualization = Object.keys(r.available_visualizations)
        })
        .catch(function (error) {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style>
.el-collapse-item__header,
.el-collapse-item__content,
.el-collapse-item__wrap {
  background-color: #f3f3f7;
}
</style>

<style lang="css" scoped>
.p-relative {
  position: relative;
}

.custom-drawer.v-sheet.v-card {
  border-radius: 0px !important;
}

.custom-drawer {
  height: 100%;
  z-index: 9999;
  position: fixed;
  width: auto;
  right: 0px;
  top: 0px;
}
</style>
