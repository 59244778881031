<template>
  <v-data-table
    :loading="is.loading"
    :headers="table.cols"
    :items="table.rows"
    :fixed-header="true"
    class="rounded-lg"
    :options.sync="options"
    :footer-props="{
      'items-per-page-options': [5, 10, 20, 50, 100],
    }"
    :server-items-length="totalItems"
  >
    <template v-slot:item.merchant="{ item }">
      {{ item.merchant.name }}</template
    >
    <template v-slot:top>
      <v-toolbar class="rounded-lg" flat color="white" height="90">
        <v-container fluid class="rounded-lg">
          <v-row>
            <v-col
              :cols="filterByMerchant ? 5 : 10"
              :sm="filterByMerchant ? 4 : 8"
              :md="filterByMerchant ? 3 : 6"
              :lg="filterByMerchant ? 3 : 6"
            >
              <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                placeholder="Recherche..."
                clearable
              />
            </v-col>
            <v-col v-if="filterByMerchant" cols="5" sm="4" md="3" lg="3">
              <MerchantInput
                v-model="filter.merchant"
                icon
                @change="handleFilterChange"
              />
            </v-col>
            <v-col cols="2" sm="4" md="6" lg="6" class="text-right">
              <user-create-update-dialog
                v-model="editedItem"
                :merchant="merchant"
                @created="get"
                @updated="get"
                @closed="
                  editedIndex = -1
                  editedItem = {}
                "
              />
            </v-col>
          </v-row>
        </v-container>
      </v-toolbar>
    </template>
    <template v-slot:item.first_name="{ item }">
      {{ fullName(item) }}
    </template>
    <template v-slot:item.merchant="{ item }">
      <v-chip color="primary" v-if="!!item.merchant">
        {{ item.merchant.name }}
      </v-chip>
    </template>
    <template v-slot:item.groups="{ item }">
      <v-chip v-if="item.is_admin" color="success" class="mx-1"> Admin </v-chip>
      <v-chip v-else-if="item.groups.length == 0" color="default" class="mx-1">
        Global
      </v-chip>
      <v-chip v-else outlined class="mx-1" :key="item.id" color="default">
        {{ item.groups.length }}
        {{ item.groups.length > 1 ? 'delegations' : 'delegation' }}
      </v-chip>
    </template>
    <template v-slot:item.action="{ item }">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            :disabled="!item.merchant || item.is_admin"
            rounded
            fab
            color="grey white--text"
            class="mr-3"
            x-small
            v-on="on"
            @click="preview(item)"
            target="_blank"
          >
            <v-icon small> mdi-eye </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('preview') }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            :disabled="item.is_admin"
            rounded
            fab
            color="primary"
            class="mr-3"
            x-small
            v-on="on"
            @click="editItem(item)"
          >
            <v-icon small> mdi-pencil </v-icon>
          </v-btn>
        </template>
        <span>Editer</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            :disabled="item.is_admin"
            rounded
            fab
            color="error"
            x-small
            v-on="on"
            @click="deleteItem(item)"
          >
            <v-icon small> mdi-delete </v-icon>
          </v-btn>
        </template>
        <span>Supprimer</span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>

<script>
import MerchantInput from '@/components/inputs/MerchantInput'
import CompanyService from '@/services/CompanyService'
import UserCreateUpdateDialog from '@/components/hmd/Users/UserCreateUpdateDialog'
import axios from 'axios'
import _ from 'lodash'
export default {
  components: {
    UserCreateUpdateDialog,
    MerchantInput,
  },
  props: {
    merchant: {
      type: String,
      default: '',
    },
    height: {
      type: Number,
      default: 300,
    },
    filterByMerchant: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    options: {},
    search: '',
    totalItems: 0,
    table: {
      rows: [],
      cols: [
        { text: 'Nom', value: 'first_name', sort: false },
        // { text: 'Prénom', value: 'last_name', sort: false },
        { text: 'Email', value: 'email', sort: false },
        // { text: 'Utilisateur', value: 'username', sort: false },
        { text: 'Marchand', value: 'merchant', sort: false },
        { text: 'Délégations', value: 'groups', sort: false },
        { text: 'Actions', value: 'action', sort: false },
      ],
    },
    editedIndex: -1,
    editedItem: {},
    is: {
      loading: false,
    },
    request: null,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    isActionsDisabled() {
      return this.is.loading
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    options() {
      this.get()
    },
    search() {
      this.options.page = 1
      this.get()
    },
  },

  mounted() {
    const $ = this
    this.get()
    this.editedItem.merchant = this.merchant
  },

  methods: {
    fullName(item) {
      let fullname = item.first_name
      if (item.last_name) fullname += ' ' + item.last_name.charAt(0) + '.'
      return fullname
    },
    preview(user) {
      this.$store.commit('setPreview', user)
      this.$router.push('Index').catch(() => {})
    },
    handleFilterChange() {
      const self = this
      this.$nextTick(() => {
        self.get()
      })
    },
    cancel() {
      if (this.request) {
        this.request.cancel()
      }
    },
    get() {
      this.is.loading = true
      let getUsers = _.debounce(() => {
        const $ = this
        this.cancel()
        const axiosSource = axios.CancelToken.source()
        this.request = { cancel: axiosSource.cancel }
        CompanyService.User.list(
          $,
          this.search,
          this.options.page,
          this.options.itemsPerPage,
          axiosSource.token
        )
          .then((r) => {
            this.table.rows = r.results
            this.totalItems = r.count
          })
          .finally(() => {
            this.is.loading = false
          })
      }, 1000)
      getUsers()
    },

    editItem(item) {
      this.editedIndex = this.table.rows.indexOf(item)
      this.editedItem = Object.assign({}, item)
    },

    deleteItem(item) {
      const index = this.table.rows.indexOf(item)
      if (confirm('Êtes-vous sûr de vouloir supprimer cet élement ?')) {
        CompanyService.User.delete(this, item.pk).then(() => {
          this.table.rows.splice(index, 1)
        })
      }
    },
    handleSearch() {
      this.get()
    },
  },
}
</script>

<style scoped></style>
