// https://vuex.vuejs.org/en/mutations.html
import { uniqueId } from 'lodash'
import router from '@/router'
import getters from './getters'
export default {
  setUser(state, user) {
    state.user = user
    if (router.currentRoute.meta.requireAdmin) {
      if (!getters.isUserAdmin(state)) router.push({ name: 'Analytics' })
    } else {
      if (getters.isUserAdmin(state) && !getters.isPreviewing(state))
        router.push({ name: 'IndexAdmin' })
    }
  },
  setPreview(state, user) {
    state.preview = user
    localStorage.setItem('preview', JSON.stringify(state.preview))
    if (!router.currentRoute.meta.requireAdmin && !getters.isPreviewing(state))
      router.push({ name: 'IndexAdmin' })
  },
  setFiltersDrawerExpanded(state, v) {
    state.filtersDrawerExpanded = v
    localStorage.setItem(
      'filtersDrawerExpanded',
      JSON.stringify(state.filtersDrawerExpanded)
    )
  },
  setMailsDrawerExpanded(state, v) {
    state.mailsDrawerExpanded = v
    localStorage.setItem(
      'mailsDrawerExpanded',
      JSON.stringify(state.mailsDrawerExpanded)
    )
  },
  setCustomDrawerExpanded(state, v) {
    state.customDrawerExpanded = v
    localStorage.setItem(
      'customDrawerExpanded',
      JSON.stringify(state.customDrawerExpanded)
    )
  },
  setLanguage(state, v) {
    state.language = v
    localStorage.setItem('language', JSON.stringify(state.language))
  },
  error(state, ml) {
    return state.errorLine.push({
      id: uniqueId(),
      text: ml.msg,
      color: 'error',
      isVisible: true,
      createdAt: new Date().getTime(),
      timeout: 3000,
    })
  },
  success(state, msg) {
    return state.errorLine.push({
      id: uniqueId(),
      text: msg,
      color: 'success',
      isVisible: true,
      createdAt: new Date().getTime(),
      timeout: 3000,
    })
  },
  markAllNotificationsAsSeen(state) {
    state.notifications.map((n) => {
      n.seen = true
    })
  },
  notify(state, n) {
    return state.notifications.push({
      id: uniqueId(),
      message: n,
      seen: false,
      createdAt: new Date().getTime(),
    })
  },
  online(state) {
    state.isOnline = true
  },
  offline(state) {
    state.isOnline = false
  },
}
