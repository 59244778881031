import { HTTP, DefaultErrorHandler } from '@/utils/http-common'
export default {
  stats($) {
    return new Promise((resolve, reject) => {
      HTTP.get(`bnc/home/`)
        .then(response => resolve(response.data))
        .catch(err => DefaultErrorHandler($, err, reject))
    })
  },
  impayesTCD($, project, params) {
    return new Promise((resolve, reject) => {
      let args = ''
      if (params)
        args +=
          '?' +
          Object.keys(params)
            .map(p =>
              typeof params[p] == 'object'
                ? params[p].map(v => p + '=' + v).join('&')
                : p + '=' + params[p]
            )
            .join('&')
      HTTP.get(`/bnc/merchant/${project}/impayes_tcd/${args}`)
        .then(response => resolve(response.data))
        .catch(err => DefaultErrorHandler($, err, reject))
    })
  },
}
