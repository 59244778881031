<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on }">
      <template v-if="!!$slots['activator']">
        <slot name="activator" @click="dialog = !dialog" />
      </template>
      <v-btn
        v-else
        color="primary"
        :fab="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
        :small="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
        v-on="on"
        @click="open"
        :width="homeBtnSize ? '100%' : ''"
        :block="homeBtnSize"
      >
        <v-icon :left="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)">
          mdi-plus
        </v-icon>
        <template v-if="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)">
          {{ $t('add') }}
        </template>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t('addFile') }}</span>
      </v-card-title>
      <v-card-text justify="center">
        <v-container>
          <v-row>
            <v-col>
              <merchant-input
                v-model="$v.merchant.$model"
                :error-messages="errors.merchant"
                outlined
                @blur="$v.merchant.$touch()"
              />
              <organism-input
                v-model="$v.organism.$model"
                :error-messages="errors.organism"
                outlined
                :disabled="!merchant"
                :merchant="$v.merchant.$model"
                @blur="$v.organism.$touch()"
              />
              <v-file-input
                v-model="file"
                :error-messages="errors.file"
                :accept="lo.map(acceptedTypes, (at) => '.' + at).join(',')"
                class="files-upload"
                color="primary"
                counter
                :label="$t('selectFile')"
                required
                outlined
                :show-size="1000"
                @blur="$v.file.$touch()"
              >
                <template v-slot:selection="{ index, text }">
                  <v-chip v-if="index < 2" color="primary" dark label small>
                    {{ text }}
                  </v-chip>

                  <span
                    v-else-if="index === 2"
                    class="overline grey--text text--darken-3 mx-2"
                  >
                    +{{ files.length - 2 }} File(s)
                  </span>
                </template>
              </v-file-input>
              <v-col cols="12" v-if="dateFieldRequired">
                <v-dialog
                  ref="dialog"
                  v-model="dateModal"
                  :return-value.sync="dateVal"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateVal"
                      label="Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      clearable
                      v-bind="attrs"
                      v-on="on"
                      :error-messages="errors.dateVal"
                      @blur="$v.dateVal.$touch()"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="dateVal" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="dateModal = false">
                      Annuler
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(dateVal)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="clear"> Annuler </v-btn>
        <v-btn
          :disabled="sendLoader"
          :loading="sendLoader"
          class="primary--text"
          depressed
          @click="submit"
        >
          Envoyer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import OrganismInput from '@/components/inputs/OrganismInput'
import MerchantInput from '@/components/inputs/MerchantInput'
import FileService from '@/services/FileService'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
export default {
  components: {
    OrganismInput,
    MerchantInput,
  },
  mixins: [validationMixin],
  props: {
    label: {
      type: String,
      default: 'Ajouter',
    },
    homeBtnSize: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      acceptedTypes: ['pdf', 'xlsx', 'xls', 'xlsm', 'csv'],
      file: null,
      merchant: null,
      organism: null,
      sendLoader: false,
      dateModal: false,
      dateVal: null,
    }
  },
  validations() {
    return {
      file: { required },
      organism: { required },
      merchant: { required },
      dateVal: {
        required: this.dateFieldRequired ? required : false,
      },
    }
  },
  computed: {
    form() {
      return this.$v.$flattenParams()
    },
    dateFieldRequired() {
      return (
        !!this.organism &&
        (this.organism.name == 'cmcic' ||
          this.organism.name == 'sg' ||
          this.organism.name == 'app')
      )
    },
    errors() {
      const errors = {
        file: [],
        organism: [],
        merchant: [],
        dateVal: [],
      }

      if (this.$v.file.$dirty) {
        !this.$v.file.required && errors.file.push(this.$t('fileRequired'))
      }
      if (this.$v.organism.$dirty) {
        !this.$v.organism.required &&
          errors.organism.push(this.$t('organismRequired'))

        if (this.$v.merchant.$dirty) {
          !this.$v.merchant.required &&
            errors.merchant.push(this.$t('merchantRequired'))
        }

        if (this.$v.dateVal.$dirty) {
          !this.$v.dateVal.required &&
            errors.dateVal.push(this.$t('dateRequired'))
        }
      }
      return errors
    },
  },
  methods: {
    validatedDateVal() {
      return this.dateFieldRequired
    },
    open() {},
    submit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.sendLoader = true
        FileService.create(this, this.merchant, this.file, {
          process_func: this.$store.state.processFunction,
          details: {
            organism: this.organism,
            merchant: this.merchant,
            date: this.dateVal,
          },
        })
          .then((r) => {
            this.$emit('create')
            this.clear()
            this.sendLoader = false
          })
          .catch((error) => {
            this.sendLoader = false
          })
      }
    },
    clear() {
      this.dialog = false
      this.file = null
      this.dateVal = null
      this.$v.organism.$model = null
      this.$v.merchant.$model = null
      this.$v.$reset()
    },
  },
}
</script>

<style scoped></style>
