<template>
  <v-dialog persistent scrollable v-model="dialog" max-width="80%">
    <template v-slot:activator="{ on }">
      <slot name="activator" :on="on" />
    </template>
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <span class="headline">{{ $t('evaluationOfPerformance') }}</span>
        <v-btn small @click="clear" fab>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text justify="center" style="max-height: 100%">
        <v-container>
          <v-row>
            <v-col>
              <CreateUpdateKpiRule
                @valid="(v) => (isValid = v)"
                v-if="dialog"
                v-model="kpiValue"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider />
      <v-card-actions class="py-4 px-6">
        <v-spacer />
        <v-btn text @click="clear">
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          :disabled="sendLoader"
          :loading="sendLoader"
          class="primary--text"
          depressed
          @click="submit"
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CreateUpdateKpiRule from './CreateUpdateKpiRule'
import { KpiService } from '@/services'
import { cloneDeep } from 'lodash'
export default {
  props: {
    kpi: {
      type: Object,
      default: null,
    },
  },
  components: { CreateUpdateKpiRule },
  data() {
    return {
      isValid: false,
      kpiValue: null,
      dialog: false,
      sendLoader: false,
    }
  },
  watch: {
    kpi(newValue, oldValue) {
      this.kpiValue = cloneDeep(this.kpi)
    },
  },
  created() {
    this.kpiValue = cloneDeep(this.kpi)
  },
  methods: {
    createUpdateComparator() {
      let $vue = this
      let promises = []
      if ($vue.kpiValue.value_comparator.pk != null)
        promises.push(
          new Promise((resolve, reject) => {
            KpiService.comparator
              .update($vue, $vue.kpiValue.value_comparator.pk, {
                reference_value: $vue.kpiValue.value_comparator.reference_value,
              })
              .then(async (result) => {
                await $vue.createUpdateDeleteColorRules(
                  $vue.kpiValue.value_comparator.color_rules
                )
                return resolve()
              })
              .catch((err) => {
                this.sendLoader = false
                reject(err)
              })
          })
        )

      return promises
    },
    async createUpdateDeleteColorRules(colorRules) {
      for (let i = 0; i < colorRules.length; i++) {
        const o = colorRules[i]

        if (o.delete) {
          if (o.pk !== null) {
            await KpiService.comparator.colorRule.delete(this, o.pk)
          }
        } else {
          if (o.pk !== null) {
            await KpiService.comparator.colorRule.update(this, o.pk, {
              color: o.color,
              formula: o.formula,
              icon: o.icon,
            })
          } else {
            await KpiService.comparator.colorRule.create(
              this,
              this.kpiValue.value_comparator.pk,
              {
                color: o.color,
                formula: o.formula,
                icon: o.icon,
              }
            )
          }
        }
      }
    },
    submit() {
      if (!this.isValid) {
        this.$store.commit('error', {
          msg: this.$t('pleaseVerifyRegisteredData'),
          color: 'red',
        })
        return
      }

      const $vue = this
      this.sendLoader = true
      Promise.all([
        ...this.createUpdateComparator(),
        // ...this.createUpdateComparator('tendency_comparator'),
      ])
        .then((values) => {
          $vue.$store.commit('success', this.$t('kpiUpdated'))
          $vue.clear()
          $vue.$emit('updated')
          this.sendLoader = false
        })
        .catch((err) => {
          console.log(err)
        })
    },
    clear() {
      this.dialog = false
    },
  },
}
</script>

<style scoped></style>
