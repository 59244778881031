import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
      themes: {
        light: {
          background: '#FFFFFF',
          primary: '#2d62ed',
          /*primary: '#00A390',*/
          secondary: '#dae1f1',
          tertiary: '#495057',
          accent: '#ff4300',
          error: '#dd6557',
          info: '#00d3ee',
          success: '#01B7A8',
          warning: '#ffa21a'
        }
      }
    },
    icons: {
      iconfont: 'mdi'
    }
  })


