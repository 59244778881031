<template>
  <div>
    <v-fade-transition mode="in-out" v-if="$vuetify.breakpoint.smAndDown">
      <v-overlay
        @click="handleToggle(false)"
        v-show="$store.state.mailsDrawerExpanded"
        absolute
        z-index="99"
      >
      </v-overlay>
    </v-fade-transition>
    <v-expand-x-transition mode="in-out">
      <v-card class="mails-drawer" v-show="$store.state.mailsDrawerExpanded">
        <v-navigation-drawer permanent right>
          <template>
            <div class="pr-9 py-3 p-relative">
              <v-btn
                class="pt-0"
                color="blue accent-3"
                absolute
                offset-y="5"
                float
                top
                left
                icon
                @click.stop="handleToggle(false)"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <div class="ml-5 text-center">
              <span>
                <span class="primary--text">Programmer mailings</span>
              </span>
            </div>
          </template>

          <MailsForm />
        </v-navigation-drawer>
      </v-card>
    </v-expand-x-transition>
  </div>
</template>

<script>
import MailsForm from '@/components/inputs/MailsForm.vue'
export default {
  components: {
    MailsForm,
  },
  methods: {
    handleToggle(v) {
      this.$store.commit('setMailsDrawerExpanded', v)
    },
  },
}
</script>

<style lang="css" scoped>
.p-relative {
  position: relative;
}
.mails-drawer.v-sheet.v-card {
  border-radius: 0px !important;
}

.mails-drawer {
  height: 100%;
  z-index: 9999;
  position: fixed;
  width: auto;
  right: 0px;
  top: 0px;
}
</style>
