var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-btn',{staticClass:"d-print-none",staticStyle:{"top":"30px","left":"-20px","background-color":"#dae1f1"},attrs:{"absolute":"","fab":"","left":"","elevation":5,"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onClick.apply(null, arguments)}}},[_c('v-icon',{attrs:{"color":"blue accent-3"}},[_vm._v(" mdi-menu ")])],1):_vm._e(),_c('v-card',{attrs:{"color":"transparent","dark":""}},[_c('v-card-title',[_c('h2',{staticClass:"text-capitalize"},[_vm._v(" Bienvenue "+_vm._s(_vm.$store.state.user.username)+" ")])]),_c('v-card-text',[_c('p',{staticClass:"subtitle-1"},[_vm._v(" Gérez les organismes, les marchands. Suivez l'état d'avancement de tous vos flux bancaires et les alertes en temps réel. ")])])],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"6","md":"6","lg":"3"}},[_c('visualization',{attrs:{"type":"counter","card":"","width":"100%","data":{
          columns: [{ name: 'count' }],
          rows: [{ count: _vm.stats.organisms_count }],
        },"options":{
          rowIndex: 0,
          column: 'count',
          subTitle: {
            size: 18,
            value: 'Organismes',
          },
          icon: {
            bgColor: '#09d0a9',
            value: 'mdi-domain',
            size: 36,
          },
        }}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"6","md":"6","lg":"3"}},[_c('visualization',{attrs:{"type":"counter","card":"","width":"100%","data":{
          columns: [{ name: 'count' }],
          rows: [{ count: _vm.stats.merchants_count }],
        },"options":{
          rowIndex: 0,
          column: 'count',
          subTitle: {
            size: 18,
            value: 'Marchands',
          },
          icon: {
            bgColor: '#DB5461',
            value: 'mdi-shield-account-outline',
            size: 36,
          },
        }}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"6","md":"6","lg":"3"}},[_c('visualization',{attrs:{"type":"counter","card":"","width":"100%","data":{
          columns: [{ name: 'count' }],
          rows: [{ count: _vm.stats.users_count }],
        },"options":{
          rowIndex: 0,
          column: 'count',
          subTitle: {
            size: 18,
            value: 'Utilisateurs',
          },
          icon: {
            bgColor: '#E9CE2C',
            value: 'mdi-account-group-outline',
            size: 36,
          },
        }}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"6","md":"6","lg":"3"}},[_c('visualization',{attrs:{"type":"counter","card":"","width":"100%","data":{
          columns: [{ name: 'count' }],
          rows: [{ count: _vm.stats.files_count }],
        },"options":{
          rowIndex: 0,
          column: 'count',
          subTitle: {
            size: 18,
            value: 'Fichiers consolidés',
          },
          icon: {
            bgColor: '#86BBD8',
            value: 'mdi-content-copy',
            size: 36,
          },
        }}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v("Alertes")]),_c('v-card-text',[_c('v-alert',{attrs:{"text":"","color":"warning","border":"left","elevation":"0","icon":"mdi-shield-airplane"}},[_vm._v(" Impayés en progression ")]),_c('v-alert',{attrs:{"text":"","color":"warning","border":"left","elevation":"0","bordered":"","icon":"mdi-shield-airplane"}},[_vm._v(" Attention aucun fichier traité depuis le 14/04/2020 ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"py-0 px-4",attrs:{"cols":"8"}},[_vm._v(" Conversion de devises ")]),_c('v-col',{staticClass:"py-0 px-4 text-right",attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"to":{ name: 'Devises' },"text":"","color":"primary"}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-cog")]),_vm._v(" Gérer ")],1)],1)],1)],1),_c('v-card-text',[_c('CurrencyConversionTool')],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',[_c('v-card-title',{staticClass:"pb-0"},[_c('v-row',[_c('v-col',{staticClass:"py-0 px-4",attrs:{"cols":"8"}},[_vm._v(" Fichiers consolidés ")]),_c('v-col',{staticClass:"py-0 px-4",attrs:{"cols":"4"}},[_c('merchant-input',{attrs:{"select-first":"","small":""},model:{value:(_vm.selectedMerchant),callback:function ($$v) {_vm.selectedMerchant=$$v},expression:"selectedMerchant"}})],1)],1)],1),_c('v-card-text',{staticClass:"pt-0 px-1"},[_c('manage-merchant-datasets',{attrs:{"download":"","merchant":_vm.selectedMerchant}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }