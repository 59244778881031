<template>
  <v-card
    class="mb-3"
    :to="{ name: 'Marchand', params: { merchant: merchant.id } }"
  >
    <v-card-text class="pa-4">
      <v-menu bottom left offset-y>
        <template v-slot:activator="{ on }">
          <v-btn
            class="top-right-menu"
            icon
            v-on="on"
            @click.stop
            @click.prevent
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item-group>
            <v-list-item
              :to="{ name: 'Marchand', params: { merchant: merchant.id } }"
            >
              <v-list-item-icon>
                <v-icon>mdi-pencil</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Editer</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              @click.prevent="remove(merchant)"
              :disabled="merchant.name == 'CBN'"
            >
              <v-list-item-icon>
                <v-icon>mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Supprimer</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-row class="mt-0">
        <v-col cols="5" sm="5" md="4" lg="3" class="text-center">
          <v-avatar color="grey lighten-5">
            <v-icon color="blue">
              <template v-if="merchant.info">
                <template
                  v-if="merchant.info.type && types[merchant.info.type]"
                >
                  {{ types[merchant.info.type].icon }}
                </template>
                <template v-else> mdi-shield-account-outline </template>
              </template>
              <template v-else> mdi-shield-account-outline </template>
            </v-icon>
          </v-avatar>
        </v-col>
        <v-col>
          <v-row>
            <v-col class="text-h5 pt-1 text-truncate blue--text text-uppercase">
              {{ merchant.name }}
            </v-col>
            <v-col cols="12" class="py-0 my-0">
              <div class="subtitle">
                <template v-if="merchant.info">
                  <template
                    v-if="merchant.info.type && types[merchant.info.type]"
                  >
                    {{ types[merchant.info.type].label }}
                  </template>
                  <template v-else> Sans catégorie </template>
                </template>
                <template v-else> Sans catégorie </template>
              </div>
              <!-- <v-chip
                      color="success"
                    >
                      Services
                    </v-chip>
                    <v-chip
                      color="warning"
                      class="ml-2"
                    >
                      new
                    </v-chip> -->
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-divider class="mt-3" />
      <v-row class="text-center mt-0">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-col v-on="on">
              <v-icon large> mdi-account </v-icon>
              <div class="mt-2">{{ merchant.members_count }}</div>
            </v-col>
          </template>
          <span>Nombre d'utilisateurs</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-col v-on="on">
              <v-icon large> mdi-domain </v-icon>
              <div class="mt-2">{{ merchant.contracts_count }}</div>
            </v-col>
          </template>
          <span></span>
          <span>Liste des contrats affiliés</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-col v-on="on">
              <v-icon large> mdi-file-check-outline </v-icon>
              <div class="mt-2">
                <span v-if="merchant.last_uploaded_file_date">{{
                  formatDate(merchant.last_uploaded_file_date)
                }}</span>
                <span v-else>-</span>
              </div>
            </v-col>
          </template>
          <span>Date du dernier fichier</span>
        </v-tooltip>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment'
import { MERCHANT_TYPES } from '@/utils/constants'
export default {
  props: {
    merchant: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      types: MERCHANT_TYPES,
    }
  },
  methods: {
    formatDate(d) {
      return moment(d).format('YYYY-MM-DD')
    },
    preview() {
      this.$emit('preview')
    },
    remove() {
      this.$emit('remove')
    },
    edit() {
      this.$emit('edit')
    },
  },
}
</script>

<style scoped>
.top-right-menu {
  position: absolute;
  right: 12px;
  top: 10px;
}
</style>
