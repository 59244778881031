import { HTTP, DefaultErrorHandler } from '@/utils/http-common'
import store from '@/store'
export default {
  ComparatorTemplate: {
    get($) {
      return new Promise((resolve, reject) => {
        HTTP.get(`/dashboard/manage/comparator_template/`)
          .then(response => resolve(response.data))
          .catch(err => DefaultErrorHandler($, err, reject))
      })
    },
  },
  get($) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/projects/manage/${store.getters.merchantId}/kpi/`)
        .then(response => resolve(response.data))
        .catch(err => DefaultErrorHandler($, err, reject))
    })
  },
  render_kpi($) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/projects/manage/${store.getters.merchantId}/render_kpi/`)
        .then(response => resolve(response.data))
        .catch(err => DefaultErrorHandler($, err, reject))
    })
  },

  comparator: {
    get($, kpi) {
      return new Promise((resolve, reject) => {
        HTTP.get(`/dashboard/manage/kpi/${kpi}/comparator/`)
          .then(response => resolve(response.data))
          .catch(err => DefaultErrorHandler($, err, reject))
      })
    },

    update($, comparator, body) {
      return new Promise((resolve, reject) => {
        HTTP.patch(`/dashboard/manage/comparator/${comparator}/`, body)
          .then(response => resolve(response.data))
          .catch(err => DefaultErrorHandler($, err, reject))
      })
    },
    delete($, comparator) {
      return new Promise((resolve, reject) => {
        HTTP.delete(`/dashboard/manage/comparator/${comparator}/`)
          .then(response => resolve(response.data))
          .catch(err => DefaultErrorHandler($, err, reject))
      })
    },
    colorRule: {
      get($, colorRule = null) {
        return new Promise((resolve, reject) => {
          HTTP.get(
            `/dashboard/manage/color_rules/${
              colorRule != null ? colorRule + '/' : ''
            }`
          )
            .then(response => resolve(response.data))
            .catch(err => DefaultErrorHandler($, err, reject))
        })
      },
      create($, comparator, body) {
        return new Promise((resolve, reject) => {
          HTTP.post(
            `/dashboard/manage/comparator/${comparator}/color_rules/`,
            body
          )
            .then(response => resolve(response.data))
            .catch(err => DefaultErrorHandler($, err, reject))
        })
      },
      update($, colorRule, body) {
        return new Promise((resolve, reject) => {
          HTTP.patch(`/dashboard/manage/color_rules/${colorRule}/`, body)
            .then(response => resolve(response.data))
            .catch(err => DefaultErrorHandler($, err, reject))
        })
      },
      delete($, colorRule) {
        return new Promise((resolve, reject) => {
          HTTP.delete(`/dashboard/manage/color_rules/${colorRule}/`)
            .then(response => resolve(response.data))
            .catch(err => DefaultErrorHandler($, err, reject))
        })
      },
    },
  },
  kpi: {
    update($, id, body) {
      return new Promise((resolve, reject) => {
        HTTP.patch(`/dashboard/manage/kpi/${id}/`, body)
          .then(response => {
            resolve(response.data)
          })
          .catch(err => DefaultErrorHandler($, err, reject))
      })
    },
  },
}
