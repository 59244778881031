<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-col cols="12" sm="6" class="pl-0">
          <v-text-field
            v-model="$v.merchantModel.name.$model"
            outlined
            :counter="50"
            label="Nom complet"
            placeholder="Baudon Nortier"
            :error-messages="errors.name"
            @blur="$v.merchantModel.name.$touch()"
          />
        </v-col>
        <v-col cols="12" sm="6" class="pl-0 pb-0">
          <div class="text-h6">
            Types
          </div>
        </v-col>
        <div>
          <v-radio-group
            v-model="$v.merchantModel.info.type.$model"
            :error-messages="errors.type"
          >
            <v-row>
              <v-col
                v-for="(t, i) in Object.keys(types)"
                :key="i"
                cols="12"
                sm="6"
                md="3"
              >
                <v-radio :label="types[t].label" :value="t" />
              </v-col>
            </v-row>
          </v-radio-group>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" class="pl-0">
        <v-col cols="12">
          <div class="text-h6">
            Détails
          </div>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="$v.merchantModel.info.phone_number.$model"
            outlined
            label="N° téléphone"
            required
            :error-messages="errors.phone_number"
            placeholder="+33600000000"
            @blur="$v.merchantModel.info.phone_number.$touch()"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="$v.merchantModel.info.website.$model"
            outlined
            :error-messages="errors.website"
            label="Site web"
            placeholder="http://www.bnc.fr"
            @blur="$v.merchantModel.info.website.$touch()"
          />
        </v-col>
      </v-col>
      <v-col cols="12" sm="6" class="pl-0">
        <v-col cols="12">
          <div class="text-h6">
            Adresse
          </div>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="$v.merchantModel.info.address.$model"
            outlined
            :counter="100"
            label="Adresse"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="$v.merchantModel.info.city.$model"
            outlined
            label="Ville"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="$v.merchantModel.info.region.$model"
            outlined
            placeholder=""
            label="Etat/Province/Région"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="$v.merchantModel.info.zip_code.$model"
            outlined
            placeholder=""
            label="Code postal"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="$v.merchantModel.info.country.$model"
            outlined
            placeholder=""
            label="Pays"
            :error-messages="errors.country"
            @blur="$v.merchantModel.country.$touch()"
          />
        </v-col>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-center">
        <!-- <v-btn
          v-if="this.merchant"
          color="red"
          dark
          @click="remove"
          class="mx-3"
          width="250px"
        >
          Supprimer
        </v-btn> -->
        <v-btn color="success" @click="submit" class="mx-3" width="250px">
          Enregistrer
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { MerchantService } from '@/services'
import { MERCHANT_TYPES } from '@/utils/constants'
import { validationMixin } from 'vuelidate'
import { required, url, numeric, alpha } from 'vuelidate/lib/validators'
export default {
  name: 'ManageMerchantDetails',
  mixins: [validationMixin],
  validations: {
    merchantModel: {
      name: { required },
      info: {
        type: { required },
        website: { url },
        phone_number: {
          required,
          correctPhoneNumber(phone_number) {
            return /^\+(?:[0-9]●?){6,14}[0-9]$/.test(phone_number)
          },
        },
        country: { alpha },
        email: {},
        address: {},
        zip_code: {},
        city: {},
        region: {},
      },
    },
  },
  props: {
    merchant: {
      type: String,
      default: null,
    },
    touch: {
      type: Number,
      default: 0,
    },
    value: {
      type: Object,
      default: () => {
        return {
          name: '',
          info: {
            type: '',
            email: '',
            phone_number: '',
            website: '',
            address: '',
            zip_code: '',
            city: '',
            region: '',
            country: '',
          },
        }
      },
    },
  },
  data: () => ({
    merchantToEdit: null,
    merchantModel: {},
    types: MERCHANT_TYPES,
  }),
  computed: {
    errors() {
      const errors = {
        name: [],
        type: [],
        website: [],
        phone_number: [],
        country: [],
      }
      if (!this.$v.merchantModel.$anyDirty) {
        return errors
      }
      this.$v.merchantModel.name.$dirty &&
        !this.$v.merchantModel.name.required &&
        errors.name.push('Nom est requis.')

      this.$v.merchantModel.info.type.$dirty &&
        !this.$v.merchantModel.info.type.required &&
        errors.type.push('Type est requis.')

      this.$v.merchantModel.info.website.$dirty &&
        !this.$v.merchantModel.info.website.url &&
        errors.website.push(
          'Le site web doit etre un URL (il doit commencer par http://).'
        )

      this.$v.merchantModel.info.phone_number.$dirty &&
        !this.$v.merchantModel.info.phone_number.required &&
        errors.phone_number.push('Le numéro de tél est requis.')

      this.$v.merchantModel.info.phone_number.$dirty &&
        !this.$v.merchantModel.info.phone_number.correctPhoneNumber &&
        errors.phone_number.push("Le numéro de tél n'est pas valide.")

      this.$v.merchantModel.info.country.$dirty &&
        !this.$v.merchantModel.info.country.alpha &&
        errors.country.push("Le Pays n'est pas valide")
      this.$emit('hasErrors', this.$v.$anyError)
      return errors
    },
  },
  watch: {
    merchantModel: {
      deep: true,
      handler(nv, ov) {
        this.$emit('input', nv)
      },
    },
  },
  mounted() {
    if (this.merchant) {
      MerchantService.get(this, this.merchant).then(r => {
        this.merchantToEdit = this.lo.cloneDeep(r)
        this.merchantModel = this.lo.cloneDeep(r)
      })
    } else {
      this.merchantModel = this.lo.cloneDeep(this.value)
    }
    this.$v.merchantModel.$reset()
  },
  methods: {
    clear() {
      this.merchantModel = this.merchantToEdit || {
        name: '',
        info: {
          type: '',
          email: '',
          phone_number: '',
          website: '',
          address: '',
          zip_code: '',
          city: '',
          region: '',
          country: '',
        },
      }
    },
    remove() {
      if (confirm('Êtes-vous sûr de vouloir supprimer cet élement ?')) {
        MerchantService.delete(this, this.merchant).then(r => {
          this.$router.go(-1)
        })
      }
    },
    submit() {
      this.$v.merchantModel.$touch()
      if (!this.$v.merchantModel.$anyError) {
        if (this.merchant) {
          MerchantService.update(this, this.merchant, this.merchantModel).then(
            r => {
              // this.$router.push({ name: "Merchants" });
            }
          )
        } else {
          MerchantService.create(this, this.merchantModel).then(r => {
            this.$router.go(-1)
          })
        }
      }
    },
  },
}
</script>

<style scoped></style>
