<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <div class="text-h5">Gestion des organismes</div>
      </v-col>
      <v-col class="text-right">
        <v-btn :to="{ path: '/backoffice/create/organisme' }" color="primary">
          <v-icon left> mdi-plus </v-icon>
          Ajouter
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="(a, i) in organisms" :key="i" cols="12">
        <v-card class="px-2">
          <v-card-title class="py-2">
            <v-row>
              <v-col class="text-h5 font-weight-light">
                <v-icon left> mdi-domain </v-icon>
                {{ a.name }}
              </v-col>
              <v-col class="text-right">
                <v-chip
                  v-if="a.contract"
                  :color="a.contract.id ? 'success' : 'secondary'"
                  class="mr-5"
                >
                  <span>{{ a.contract.id ? 'Actif' : 'Non affilié' }}</span>
                </v-chip>
                <v-btn
                  small
                  text
                  color="grey lighten-1"
                  @click="expanded = expanded == i ? -1 : i"
                >
                  <v-icon left>
                    mdi-chevron-{{ expanded == i ? 'up' : 'down' }}
                  </v-icon>
                  Configurer
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>

          <v-divider v-if="expanded == i"></v-divider>
          <v-card-text v-if="expanded == i">
            <div class="text-h6">Configuration de l'association</div>
            <v-row>
              <v-col cols="12" sm="6">
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <v-text-field
                      label="Type de contrat"
                      outlined
                      v-model="a.contract.type"
                    />
                  </v-col>
                  <v-col cols="12" class="pb-0">
                    <v-menu
                      ref="menu1"
                      :return-value.sync="a.contract.contract_signing_date"
                      transition="scale-transition"
                      offset-y
                      :close-on-content-click="false"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          v-model="a.contract.contract_signing_date"
                          label="Date de signature"
                          append-icon="mdi-event"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="a.contract.contract_signing_date"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="
                            $refs.menu1[0].save(
                              a.contract.contract_signing_date
                            )
                          "
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" class="pb-0">
                    <v-text-field
                      type="number"
                      label="Délai de dénonciation  (en mois)"
                      v-model="a.contract.notice_delay"
                      outlined
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="6">
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <v-text-field
                      label="SLA"
                      outlined
                      v-model="a.contract.sla"
                    />
                  </v-col>
                  <v-col cols="12" class="pb-0">
                    <v-menu
                      ref="menu2"
                      :return-value.sync="a.contract.contract_end_date"
                      transition="scale-transition"
                      offset-y
                      :close-on-content-click="false"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          v-model="a.contract.contract_end_date"
                          label="Date de fin du contrat"
                          append-icon="mdi-event"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="a.contract.contract_end_date"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="
                            $refs.menu2[0].save(a.contract.contract_end_date)
                          "
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" class="pb-0">
                    <v-text-field
                      label="Spécificités"
                      outlined
                      v-model="a.contract.specificity"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <div class="text-center">
              <v-btn
                class="ma-2"
                color="warning"
                @click="cancel(a)"
                :disabled="a.contract ? !a.contract.id : true"
              >
                dissocier
              </v-btn>
              <v-btn class="ma-2" color="success" @click="submit(a)">
                {{
                  a.contract
                    ? a.contract.id
                      ? 'Mettre a jour'
                      : 'Créer le contrat'
                    : 'Créer le contrat'
                }}
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-alert
          border="left"
          colored-border
          elevation="2"
          icon="mdi-information-outline"
          type="warning"
        >
          Si un organisme n’est pas disponible, envoyez un mail à
          <strong>support@himydata.com</strong> pour l'intégrer.
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { MerchantService, OrganismService, ContractService } from '@/services'
import { cloneDeep } from 'lodash'
import { ORGANISM_TYPES } from '@/utils/constants'

import { validationMixin } from 'vuelidate'

export default {
  props: {
    merchant: {
      type: String,
      default: null,
    },
  },
  mixins: [validationMixin],
  validations: {
    organisms: {
      $each: {
        type: {},
        sla: {},
        specificity: {},
        notice_delay: {},
        contract_signing_date: {
          validationSigningDate(value) {
            return
          },
        },
        contract_end_date: {
          validationEndDate(value) {
            let signature = new Date()
            let fin = new Date()
            return fin > signature
          },
        },
      },
    },
  },
  data() {
    return {
      types: ORGANISM_TYPES,
      expanded: -1,
      contracts: [],
      contractModel: {
        organism: null,
        merchant: null,
        type: null,
        contract_signing_date: null,
        contract_end_date: null,
        notice_delay: null,
        sla: null,
        specificity: null,
      },
      organisms: [],
      isLoading: true,
    }
  },

  mounted() {
    this.getOrganisms()
  },
  methods: {
    cancel(organism) {
      if (confirm('Êtes-vous sûr de vouloir supprimer ce contrat ?')) {
        ContractService.delete(this, organism.contract.id).then((r) => {
          this.getOrganisms()
        })
      }
    },
    submit(organism) {
      if (organism.contract.id) {
        ContractService.update(
          this,
          organism.contract.id,
          organism.contract
        ).then((r) => {})
      } else {
        organism.contract.organism = organism.id
        organism.contract.merchant = this.merchant
        ContractService.create(this, organism.contract).then((r) => {
          this.getOrganisms()
        })
      }
    },
    getContracts(merchantId) {
      const self = this
      self.isLoading = true
      MerchantService.getContracts(self, merchantId)
        .then((r) => {
          self.organisms.map((o) => {
            o.contract =
              r.find(
                (c) => c.organism.id == o.id && c.merchant == merchantId
              ) || cloneDeep(self.contractModel)
            return o
          })
          self.contracts = r.contracts
        })
        .finally(() => {
          self.isLoading = false
          this.$forceUpdate() // To display the chip "actif / Non affilié" because organism was render previously before the contract attribute was filled
        })
    },
    getOrganisms() {
      this.isLoading = true
      OrganismService.list(this)
        .then((r) => {
          this.organisms = r.results
          this.getContracts(this.merchant)
        })
        .finally(() => {
          setTimeout(() => {
            this.isLoading = false
          }, 2000)
        })
    },
  },
}
</script>

<style scoped></style>
