import { HTTP } from "@/utils/http-common";

export default {
  emailNotReceived(email, $) {
    return new Promise((resolve, reject) => {
      HTTP.post("/account/registration/resend-confirm-email/", {
        email: email
      })
        .then(response => {
          $.$store.commit("success", "E-mail envoyé");
          resolve(response);
        })
        .catch(error => {
          $.$store.commit("error", {
            msg: error.response.data.error.join("\n"),
            color: "error"
          });
          reject(error);
        });
    });
  },
  profile($) {
    return new Promise((resolve, reject) => {
      HTTP.get("/account/settings/user/")
        .then(r => {
          resolve(r.data);
        })
        .catch(e => {
          $.$store.commit("error", {
            msg:
              "Echec de la requête : Récupération des informations de l'utilisateur",
            color: "error"
          });
          reject(e);
        });
    });
  },
  updateProfileInfo($, firstName, lastName, username) {
    return new Promise((resolve, reject) => {
      HTTP.put("/account/settings/user/", {
        first_name: firstName,
        last_name: lastName,
        username: username
      })
        .then(function(response) {
          $.$store.state.user.username = username;
          $.$store.state.user.first_name = firstName;
          $.$store.state.user.last_name = lastName;
          $.$store.commit("success", "Profile mis a jour");
          resolve(response);
        })
        .catch(function(error) {
          var err = error.response.data;
          for (var prop in err) {
            $.$store.commit("error", {
              msg: prop + " : " + err[prop],
              color: "error"
            });
          }
          reject(error);
        });
    });
  },
  changePassword($, old, new1, new2) {
    return new Promise((resolve, reject) => {
      HTTP.post("/account/password/change/", {
        new_password1: new1,
        new_password2: new2,
        old_password: old
      })
        .then(function(response) {
          $.$store.commit("success", "Mot de passe mis a jour");
          resolve(response);
        })
        .catch(function(error) {
          var err = error.response.data;
          for (var prop in err) {
            $.$store.commit("error", {
              msg: prop + " : " + err[prop],
              color: "error"
            });
          }
          reject(error);
        });
    });
  },
  resetPassword(email, $) {
    return new Promise((resolve, reject) => {
      HTTP.post("/account/password/reset/", { email: email })
        .then(response => {
          $.$store.commit("success", "E-mail envoyé");
          resolve(response);
        })
        .catch(error => {
          $.$store.commit("error", { text: error, color: "error" });
          reject(error);
        });
    });
  },
  sendNewPassword(parent, password1, password2, uid, key) {
    return new Promise((resolve, reject) => {
      HTTP.post("/account/password/reset/confirm/", {
        uid: uid,
        token: key,
        new_password1: password1,
        new_password2: password2
      })
        .then(response => {
          resolve(response);
          parent.$store.commit("success", "Le mot de passe a été mis à jour.");
          setTimeout(() => {
            parent.$router.replace("/signin");
          }, 1000);
        })
        .catch(error => {
          console.log(error);
          parent.$store.commit("error", { text: error, color: "error" });
          reject(error);
        });
    });
  },
  getInvitation(parent, invitation_key) {
    return new Promise((resolve, reject) => {
      HTTP.post("/invitations/get_invitation/", {
        key: invitation_key
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          parent.$store.commit("error", { text: error, color: "error" });
        });
    });
  },
  acceptInvitation(parent, invitation_key) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/invitations/accept/${invitation_key}/`)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
