<template>
  <v-container fluid>
    <core-app-bar />
    <v-row>
      <v-col>
        <v-card class="px-4 py-3">
          <v-tabs v-model="tab" icons-and-text>
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#tab-2">
              <span>
                <v-icon left>mdi-file-multiple</v-icon>
                <span> {{ $t('consolidatedFiles') }}</span>
              </span>
            </v-tab>
            <v-tab href="#tab-1">
              <span>
                <v-icon left>mdi-currency-eur</v-icon>
                <span> {{ $t('currency') }}</span>
              </span>
            </v-tab>
            <v-tab href="#tab-4">
              <span>
                <v-icon left>mdi-bank-transfer</v-icon>
                <span> Bin Mapping</span>
              </span>
            </v-tab>
            <v-tab href="#tab-3">
              <span>
                <v-icon left>mdi-ballot</v-icon>
                <span>{{ $t('evaluationRules') }}</span>
              </span>
            </v-tab>
            <!-- <v-tab href="#tab-5">
              <span>
                <v-icon left>mdi-content-copy</v-icon>
                <span>{{ $t('files') }}</span>
              </span>
            </v-tab> -->
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1">
              <dataset-crud
                readOnly
                searchable
                :dataset="$store.state.devises"
              ></dataset-crud>
            </v-tab-item>
            <v-tab-item value="tab-2">
              <manage-merchant-datasets
                download
                :merchant="$store.getters.merchantId"
              ></manage-merchant-datasets>
            </v-tab-item>
            <v-tab-item value="tab-3">
              <KpiRuleSettings />
            </v-tab-item>
            <v-tab-item value="tab-4">
              <dataset-crud
                readOnly
                searchable
                :dataset="$store.state.bin_mapping"
              ></dataset-crud>
            </v-tab-item>
            <!-- <v-tab-item value="tab-5">
              <manage-files
                fill-height
                searchable
                :height="$vuetify.breakpoint.height - 380"
                :merchantId="$store.getters.merchantId"
              ></manage-files>
            </v-tab-item> -->
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import KpiRuleSettings from '@/components/bnc/kpi-rules/KpiRuleSettings.vue'
import DatasetCrud from '@/components/hmd/Dataset/DatasetCrud'
// import FilesCrud from '@/components/hmd/Files/FilesCrud'
import ManageMerchantDatasets from '@/components/bnc/ManageMerchantDatasets'
export default {
  components: {
    DatasetCrud,
    ManageMerchantDatasets,
    KpiRuleSettings,
    // 'manage-files': FilesCrud,
    CoreAppBar: () => import('@/components/layouts/core/AppBar'),
  },
  name: 'Parametres',
  data() {
    return {
      tab: null,
    }
  },
}
</script>

<style scoped></style>
