/* eslint-disable space-before-function-paren */
import { HTTP, DefaultErrorHandler } from '@/utils/http-common'
const HMD_PARTNERS = process.env.HMD_PARTNERS || 'sandbox'
import { MERCHANT_TYPES } from '@/utils/constants'
export default {
  list($, simplified = false) {
    return new Promise((resolve, reject) => {
      HTTP.get(
        simplified ? '/bnc/merchant/manage/?simple' : '/bnc/merchant/manage/'
      )
        .then((r) => {
          resolve(r.data)
        })
        .catch((err) => DefaultErrorHandler($, err, reject))
    })
  },
  get($, id) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/bnc/merchant/${id}/`)
        .then((r) => {
          resolve(r.data)
        })
        .catch((err) => DefaultErrorHandler($, err, reject))
    })
  },
  create($, form) {
    return new Promise((resolve, reject) => {
      HTTP.post('/bnc/merchant/manage/', form)
        .then((r) => {
          $.$store.commit('success', 'Marchand créé')
          resolve(r)
        })
        .catch((err) => DefaultErrorHandler($, err, reject))
    })
  },
  update($, id, form) {
    return new Promise((resolve, reject) => {
      form = {
        name: form.name,
        info: form.info,
      }
      HTTP.patch(`/projects/manage/${id}/`, form)
        .then((r) => {
          $.$store.commit('success', 'Marchand mis a jour')
          resolve(r.data)
        })
        .catch((err) => DefaultErrorHandler($, err, reject))
    })
  },
  delete($, id) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/projects/manage/${id}/`)
        .then((r) => {
          $.$store.commit('success', 'Marchand supprimé')
          resolve(r.data)
        })
        .catch((err) => DefaultErrorHandler($, err, reject))
    })
  },
  getOrganisms($, id) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/bnc/merchant/${id}/organisms/`)
        .then((r) => {
          resolve(r.data)
        })
        .catch((err) => DefaultErrorHandler($, err, reject))
    })
  },
  getConsolidatedFiles($, id) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/bnc/merchant/${id}/consolidated-files/`)
        .then((r) => {
          resolve(r.data)
        })
        .catch((err) => DefaultErrorHandler($, err, reject))
    })
  },
  getContracts($, id) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/bnc/merchant/${id}/contracts/`)
        .then((r) => {
          resolve(r.data)
        })
        .catch((err) => DefaultErrorHandler($, err, reject))
    })
  },
}
