// https://vuex.vuejs.org/en/getters.html

export default {
  merchantName(state) {
    return state.preview
      ? state.preview.merchant.name
      : state.user.merchant.name
  },
  merchantId(state) {
    return state.preview ? state.preview.merchant.id : state.user.merchant.id
  },
  isUserAdmin(state) {
    return state.user.admin
  },
  isPreviewing(state) {
    return !!state.preview && state.user.admin
  },
}
