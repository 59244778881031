// https://vuex.vuejs.org/en/state.html

export default {
  errorLine: [],
  isOnline: true,
  user: JSON.parse(localStorage.getItem('user') || 'null') || {
    username: null,
    key: null,
    admin: false,
  },
  project: null,
  company: null,
  preview: JSON.parse(localStorage.getItem('preview') || 'null'),
  notifications: [
    { message: 'Organisme BNP  ajouté', seen: false },
    { message: 'Utilisateur Ajouté', seen: false },
  ],
  filtersDrawerExpanded: false,
  mailsDrawerExpanded: false,
  customDrawerExpanded: false,
  language: JSON.parse(localStorage.getItem('language'))
    ? {
        name: JSON.parse(localStorage.getItem('language')).name,
        image: JSON.parse(localStorage.getItem('language')).image,
      }
    : {
        name: 'fr',
        image: require('@/assets/fr-48.png'),
      },
}
