<template>
  <v-container
    fluid
    :style="{
      'padding-right':
        $store.state.customDrawerExpanded || $store.state.filtersDrawerExpanded
          ? !$vuetify.breakpoint.smAndDown
            ? '320px'
            : '20px'
          : '12px',
    }"
    id="homepage"
  >
    <core-app-bar />
    <v-row>
      <v-col cols="12" class="d-flex justify-end pt-0">
        <v-spacer></v-spacer>
        <div>
          <v-btn
            dark
            color="blue-grey lighten-2"
            class="d-print-none text-capitalize mr-2"
            @click="handleToggleFilters(true)"
          >
            <v-icon> mdi-filter-outline</v-icon>
            {{ $t('filters') }}
          </v-btn>
          <v-btn
            v-if="!isEditing.widgets && dashboard.widgets.length > 0"
            color="accent"
            @click="
              handleToggleCustom(true), (isEditing.widgets = !isEditing.widgets)
            "
            class="d-print-none text-capitalize"
          >
            <v-icon left>mdi-pencil-outline</v-icon>
            {{ $t('edit') }}
          </v-btn>
          <v-btn
            v-if="
              !isEditing.widgets &&
              dashboard.widgets.length > 0 &&
              isAllowedToPrint
            "
            color="primary"
            @click="print"
            class="d-print-none text-capitalize ml-2"
          >
            <v-icon left>mdi-printer-outline</v-icon>
            {{ $t('print') }}
          </v-btn>
          <v-btn
            v-if="isEditing.widgets"
            text
            outlined
            color="primary"
            @click="saveChanges()"
          >
            {{ $t('save') }}
          </v-btn>
          <v-btn
            v-if="isEditing.widgets"
            text
            outlined
            class="ml-2"
            @click="cancelChanges()"
          >
            {{ $t('cancel') }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <!-- Loading component -->
    <v-row
      style="margin-top: 15%"
      v-if="loading || dashboard.widgets.length == 0"
    >
      <template v-if="loading">
        <v-col cols="12" class="text-center">
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </template>

      <template v-if="!loading && dashboard.widgets.length == 0">
        <v-col cols="12" class="text-center"> </v-col>
        <v-col cols="12" class="text-center">
          <v-icon style="font-size: 68px" class="grey--text text--lighten-1"
            >mdi-monitor-dashboard</v-icon
          >
          <br />
          <span class="text-h5 grey--text text--lighten-1">
            {{ $t('emptyDashboard') }}</span
          >
        </v-col>
        <v-col
          cols="12"
          class="text-center"
          v-if="!$store.state.customDrawerExpanded"
        >
          <v-btn
            color="primary"
            large
            outlined
            dark
            @click="handleToggleCustom(true)"
          >
            <v-icon left> mdi-plus</v-icon>
            {{ $t('addIndicators') }}
          </v-btn>
        </v-col>
      </template>
    </v-row>
    <!-- End Loading component -->

    <div style="width: 100%; margin-top: 10px; height: 100%">
      <grid-layout
        v-if="renderLayout"
        :layout.sync="dashboard.widgets"
        :margin="[10, 10]"
        :col-num="12"
        :row-height="34"
        :responsive="withResponsive"
        :is-draggable="isEditing.widgets"
        :is-resizable="false"
        :vertical-compact="true"
        :use-css-transforms="true"
      >
        <grid-item
          v-for="(el, el_index) in dashboard.widgets"
          :key="el.id"
          :x="el.x"
          :y="el.y"
          :w="el.w"
          :h="el.h"
          :i="el.i"
        >
          <dashboard-element
            fill-parent
            depressed
            :removable="isEditing.widgets"
            :visualization="el.visualization"
            :globalFilters="globalFilters"
            @remove="removeWidget(el, el_index)"
          />
        </grid-item>
      </grid-layout>
    </div>
    <FiltersDrawer
      :filters="filters()"
      ref="viz-filters"
      @update="updateGlobalFilters"
      v-if="!loading"
    />
    <CustomDrawer :widgets="listWidgets" @add="addWidget" ref="customDrawer" />
  </v-container>
</template>

<script>
import KpiList from '../components/bnc/kpi-rules/KpiList.vue'
import FiltersDrawer from '@/components/inputs/FiltersDrawer.vue'
import MailsDrawer from '@/components/inputs/MailsDrawer.vue'
import CustomDrawer from '@/components/inputs/CustomDrawer.vue'
import VueGridLayout from 'vue-grid-layout'
import DashboardService from '@/services/DashboardService'
import { DEFAULT_SIZES } from '@/constants/GridLayoutDefaultConstants'
import Element from '@/components/UIComponents/Element.vue'
import moment from 'moment'
import _ from 'lodash'
import map from 'lodash/map'

export default {
  name: 'Index',
  data: () => {
    return {
      loading: true,
      renderLayout: true,
      dashboard_default_period: null,
      globalFilters: [],
      selectedItem: 1,
      isEditing: {
        widgets: false,
      },
      listWidgets: [],
      dashboard: {
        widgets: [],
      },
      bufferedIndex: 0,
    }
  },

  components: {
    // TopHeader,
    // KpiList,
    FiltersDrawer,
    // MailsDrawer,
    CustomDrawer,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    DashboardElement: Element,
    CoreAppBar: () => import('@/components/layouts/core/AppBar'),
  },

  computed: {
    user() {
      return this.$store.state[
        this.$store.getters.isPreviewing ? 'preview' : 'user'
      ]
    },
    forcedUser() {
      return this.$store.state.preview ? this.$store.state.preview.pk : null
    },
    styles() {
      return {
        background: '#dae1f1',
        boxShadow: '0 0 2rem 0 rgba(136,152,170,.15)',
      }
    },
    withResponsive() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return true
      }
      return false
    },
  },
  mounted() {
    this.getAllWidgets()
  },
  methods: {
    handleToggleFilters(v) {
      this.$store.commit('setFiltersDrawerExpanded', v)
    },
    handleToggleMails(v) {
      this.$store.commit('setMailsDrawerExpanded', v)
    },
    handleToggleCustom(v) {
      this.$store.commit('setCustomDrawerExpanded', v)
    },
    thousandsSeparator(v) {
      return !v ? '-' : v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    currencyFormatter(v) {
      return (!v ? '-' : this.thousandsSeparator(v)) + ' €'
    },
    percentFormatter(v) {
      return (!v ? '-' : v.toString()) + ' %'
    },
    adaptDatasetName(name) {
      return `consolidation_${name}_${this.lo
        .snakeCase(this.$store.getters.merchantName)
        .replace('_1', '1') // ToDo: This is an ugly fix. To improve
        .replace('_2', '2')
        .replace('_3', '3')
        .replace('_4', '4')
        .replace('_5', '5')
        .replace('_6', '6')
        .replace('_7', '7')
        .replace('_8', '8')
        .replace('_9', '9')}`
    },
    updateGlobalFilters(v) {
      this.globalFilters = v
    },
    isAllowedToPrint() {
      return (
        this.$store.getters.isPreviewing || !this.$store.getters.isUserAdmin
      )
    },
    async print() {
      window.print()
    },

    // CUSTOM
    cancelChanges(attr) {
      this.getAllWidgets()
      this.isEditing.widgets = false
      this.handleToggleCustom(false)
    },
    saveChanges() {
      _.forEach(this.dashboard.widgets, (widget) => {
        DashboardService.update_widget(self, widget)
      })
      this.isEditing.widgets = false
      this.handleToggleCustom(false)
    },
    getBufferizedIndex() {
      // Get the higher widget's i
      let max = 0
      this.dashboard.widgets.forEach((widget) => {
        if (widget.i >= max) {
          max = widget.i + 1
        }
      })

      return max
    },

    getAllWidgets() {

      const self = this
      DashboardService.getAllWidgets(self, 'index', this.forcedUser)
        .then((r) => {
          self.dashboard_default_period = r.dashboard_default_period
          self.dashboard = _.cloneDeep(r)
          self.dashboardOld.widgets = _.cloneDeep(r.widgets)
        })
        .finally(() => {
          this.bufferedIndex = this.getBufferizedIndex()
          this.loading = false
        })
    },
    removeWidget(el, index) {
      DashboardService.delete_widget(self, el).then((res) => {
        this.dashboard.widgets.splice(index, 1)
        this.$refs['customDrawer'].init()
        if (this.dashboard.widgets.length == 0) {
          // No more Widget, remove edition mode
          this.isEditing.widgets = false
        }
        //this.$refs['customDrawer'].showWidget(el, show=true)
      })
    },

    addWidget(widget) {
      const self = this
      const dashboardWidget = {
        x: 0,
        y: 0,
        w: DEFAULT_SIZES[widget.type].w,
        h: DEFAULT_SIZES[widget.type].h,
        minH: DEFAULT_SIZES[widget.type].minH,
        minW: DEFAULT_SIZES[widget.type].minW,
        i: this.bufferedIndex,
        visualization_id: widget.id,
        type: widget.type,
        label: widget.label,
      }
      DashboardService.create_widget(
        self,
        self.dashboard,
        dashboardWidget
      ).then((res) => {
        self.dashboard.widgets.push(res.data)
        this.bufferedIndex = this.getBufferizedIndex()
        //this.$refs['customDrawer'].init()
        //this.$refs['customDrawer'].showWidget(el, show=false)
      })
    },
    filters() {
      let defaultPeriod, dateStart, dateEnd
      if (
        this.$store.getters.merchantName &&
        this.$store.getters.merchantName.toLowerCase() === 'air_tahiti'
      ) {
        // Date filter for Air Tahiti users is set by default to J-3
        defaultPeriod = 'day'
        dateStart = moment()
          .subtract(3, `${defaultPeriod}s`)
          .startOf(`${defaultPeriod}`)
          .format('YYYY-MM-DD')
        dateEnd = moment()
          .subtract(3, `${defaultPeriod}s`)
          .endOf(`${defaultPeriod}`)
          .format('YYYY-MM-DD')
      } else {
        defaultPeriod = this.dashboard_default_period || 'month'
        // Setup default Filters
        dateStart = moment()
          .subtract(1, `${defaultPeriod}s`)
          .startOf(`${defaultPeriod}`)
          .format('YYYY-MM-DD')
        dateEnd = moment()
          .subtract(1, `${defaultPeriod}s`)
          .endOf(`${defaultPeriod}`)
          .format('YYYY-MM-DD')
        //listFilters.find(x => x.name === 'fulldata').value = [dateStart, dateEnd];
        //console.log(listFilters.find(x => x.name === 'fulldata'))
      }

      return [
        {
          name: 'full_date',
          type: 'daterange',
          label: 'Date',
          values: {
            project: this.$store.getters.merchantId,
            column: 'year',
            dataset: this.adaptDatasetName('acquereurs'),
          },
          value: [dateStart, dateEnd],
        },
        {
          name: 'full_date_ref',
          type: 'daterange',
          multiple: true,
          label: this.$t('referenceDate', 'en'),
          values: {
            project: this.$store.getters.merchantId,
            column: 'year',
            dataset: this.adaptDatasetName('acquereurs'),
          },
          value: '',
        },
        {
          name: 'organism',
          type: 'checkbox',
          multiple: true,
          label: this.$t('serviceProvider', 'en'),
          values: {
            project: this.$store.getters.merchantId,
            column: 'organism',
            dataset: this.adaptDatasetName('acquereurs'),
            conditionName: 'conditions',
          },
        },
        {
          name: 'organism',
          type: 'checkbox',
          multiple: true,
          label: this.$t('pspName', 'en'),
          values: {
            project: this.$store.getters.merchantId,
            column: 'organism',
            dataset: this.adaptDatasetName('psp'),
            conditionName: 'conditions',
          },
        },
        {
          name: 'country',
          type: 'country',
          multiple: true,
          label: this.$t('country', 'en'),
          values: {
            project: this.$store.getters.merchantId,
            column: 'country',
            datasets: [
              this.adaptDatasetName('acquereurs'),
              this.adaptDatasetName('psp'),
            ],
            conditionName: 'conditions',
          },
        },
        {
          name: 'sales_channel',
          type: 'list',
          multiple: true,
          label: this.$t('salesChannel', 'en'),
          values: {
            project: this.$store.getters.merchantId,
            column: 'sales_channel',
            datasets: [
              this.adaptDatasetName('acquereurs'),
              this.adaptDatasetName('psp'),
            ],
            conditionName: 'conditions',
          },
        },
        {
          name: 'brand',
          type: 'list',
          multiple: true,
          label: this.$t('brand', 'en'),
          values: {
            project: this.$store.getters.merchantId,
            column: 'brand',
            datasets: [
              this.adaptDatasetName('acquereurs'),
              this.adaptDatasetName('psp'),
            ],
            conditionName: 'conditions',
          },
        },
        {
          name: 'network',
          type: 'list',
          multiple: true,
          label: this.$t('network', 'en'),
          values: {
            project: this.$store.getters.merchantId,
            column: 'network',
            dataset: this.adaptDatasetName('acquereurs'),
            conditionName: 'conditions',
          },
        },
      ]
    },
  },
}
</script>

<style>
.hmd-kpi-icon {
  font-size: 30px !important;
  position: relative !important;
  top: -1px !important;
  right: 5px !important;
}

.vue-grid-item.cssTransforms {
  border-radius: 8px !important;
}

.vue-grid-item.vue-grid-placeholder {
  background-color: #2d62ed !important;
  border-radius: 8px !important;
}

.hmd-visualization-loading {
  background-color: #dae1f1 !important;
  height: 50px;
}

/* Filters */

.group-filters-tag.active {
  background-color: #004671 !important;
}

.hmd-query-parameter-input {
  padding: 0px !important;
}

.el-input--mini {
  font-size: 1em !important;
}

.el-input__suffix,
.el-input__inner {
  color: #004671 !important;
}

.el-input__prefix,
.el-input__suffix::placeholder,
.el-input__inner::placeholder {
  color: #929292 !important;
}

.el-input--mini .el-input__inner {
  height: 36px !important;
}

.pt--15 {
  padding-top: -15px;
}

/* Custom Bar Charts for BNC */
#homepage .v-card.v-sheet.theme--light {
  background-color: #dae1f1;
}

/* #homepage .v-card.v-sheet.theme--light .apexcharts-text.apexcharts-xaxis-label {
  display: none;
}

#homepage
  .v-card.v-sheet.theme--light
  .apexcharts-text.apexcharts-xaxis-label:first-child,
#homepage
  .v-card.v-sheet.theme--light
  .apexcharts-text.apexcharts-xaxis-label:last-child {
  display: block;
} */
</style>
