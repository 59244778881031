<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <template v-slot:activator="{ on }">
      <v-btn class="px-0" text v-on="on">
        {{ $t(text) }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline">
        {{ $t('resetPassword') }}
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="email"
          label="E-mail"
          :error-messages="$t(errors)"
          required
          @keyup.enter="send"
        />
      </v-card-text>
      <v-card-text>{{ $t('howToResetPassword') }}</v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="
            dialog = false
            $v.$reset()
          "
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn depressed @click="send" color="primary" text>
          {{ $t('send') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import AccountService from '@/services/AccountService'
export default {
  mixins: [validationMixin],
  validations: {
    email: { email, required },
  },
  props: {
    text: {
      type: String,
      default: () => 'forgotPassword',
    },
  },
  data() {
    return {
      dialog: false,
      email: '',
    }
  },
  computed: {
    errors() {
      const errors = []
      if (this.$v.email.$dirty) {
        if (!this.$v.email.email) {
          errors.push('emailMustBeValid')
        }
        if (!this.$v.email.required) {
          errors.push('emailIsRequired.')
        }
      }

      return errors
    },
  },
  methods: {
    send() {
      const self = this
      this.$v.$touch()
      if (!this.$v.$invalid) {
        AccountService.resetPassword(self.email, self).then(() => {
          self.dialog = false
        })
      }
    },
  },
}
</script>
