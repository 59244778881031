<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-col cols="12" sm="6" class="pl-0">
          <v-text-field
            v-model="$v.organismModel.name.$model"
            outlined
            :counter="50"
            label="Nom de l'organisme"
            placeholder="Baudon Nortier"
            :error-messages="errors.name"
            @blur="$v.organismModel.name.$touch()"
          />
        </v-col>
        <v-col cols="12" sm="6" class="pl-0 pb-0">
          <div class="text-h6">
            Types
          </div>
        </v-col>
        <div>
          <v-radio-group
            v-model="$v.organismModel.type.$model"
            :error-messages="errors.type"
          >
            <v-row>
              <v-col
                v-for="(t, i) in Object.keys(types)"
                :key="i"
                cols="12"
                sm="6"
                md="3"
              >
                <v-radio :label="types[t].label" :value="t" />
              </v-col>
            </v-row>
          </v-radio-group>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="text-h6">
          Détails de l'organisme
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="$v.organismModel.contact_name.$model"
          outlined
          label="Nom du contact"
          required
          :error-messages="errors.contact_name"
          placeholder=""
          @blur="$v.organismModel.contact_name.$touch()"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="$v.organismModel.phone_number.$model"
          outlined
          label="N° téléphone"
          required
          :error-messages="errors.phone_number"
          placeholder="+33600000000"
          @blur="$v.organismModel.phone_number.$touch()"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="$v.organismModel.email.$model"
          outlined
          label="Mail"
          required
          :error-messages="errors.email"
          placeholder="xxxx@bnc.fr"
          @blur="$v.organismModel.email.$touch()"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-select
          disabled
          v-model="$v.organismModel.transaction_regionality.$model"
          :items="['domestique']"
          outlined
          label="Régionalité des transactions"
          required
          :error-messages="errors.transaction_regionality"
          placeholder="Domestique"
          @blur="$v.organismModel.transaction_regionality.$touch()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-center">
        <!-- <v-btn
          v-if="this.organism"
          color="red"
          dark
          @click="remove"
          class="mx-3"
          width="250px"
        >
          Supprimer
        </v-btn> -->
        <v-btn color="success" @click="submit" class="mx-3" width="250px">
          Enregistrer
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { OrganismService } from '@/services'
import { ORGANISM_TYPES } from '@/utils/constants'
import { validationMixin } from 'vuelidate'
import { required, url, numeric, alpha } from 'vuelidate/lib/validators'
export default {
  name: 'ManageMerchantDetails',
  mixins: [validationMixin],
  validations: {
    organismModel: {
      name: { required },
      type: { required },
      contact_name: {},
      phone_number: {
        correctPhoneNumber(phone_number) {
          return /^\+(?:[0-9]●?){6,14}[0-9]$/.test(phone_number)
        },
      },
      email: {},
      transaction_regionality: {},
    },
    validationGroup: ['organismModel'],
  },
  props: {
    organism: {
      type: String,
      default: null,
    },
    touch: {
      type: Number,
      default: 0,
    },
    value: {
      type: Object,
      default: () => {
        return {
          name: '',
          type: '',
          contact_name: '',
          phone_number: '',
          email: '',
          transaction_regionality: 'domestique',
        }
      },
    },
  },
  data: () => ({
    organismToEdit: null,
    organismModel: {},
    types: ORGANISM_TYPES,
  }),
  computed: {
    errors() {
      const errors = {
        name: [],
        type: [],
        phone_number: [],
      }
      this.$v.organismModel.name.$dirty &&
        !this.$v.organismModel.name.required &&
        errors.name.push('Le nom est requis.')

      this.$v.organismModel.type.$dirty &&
        !this.$v.organismModel.type.required &&
        errors.type.push('Le type est requis.')

      this.$v.organismModel.phone_number.$dirty &&
        !this.$v.organismModel.phone_number.correctPhoneNumber &&
        errors.phone_number.push("Le numéro de tél n'est pas valide.")

      this.$emit('hasErrors', this.$v.$anyError)
      return errors
    },
  },
  watch: {
    organismModel: {
      deep: true,
      handler(nv, ov) {
        this.$emit('input', nv)
      },
    },
  },
  mounted() {
    if (this.organism) {
      OrganismService.get(this, this.organism).then(r => {
        this.organismToEdit = this.lo.cloneDeep(r)
        this.organismModel = this.lo.cloneDeep(r)
      })
    } else {
      this.organismModel = this.lo.cloneDeep(this.value)
    }
    this.$v.organismModel.$reset()
  },
  methods: {
    clear() {
      this.organismModel = this.organismToEdit || {
        name: '',
        contact_name: '',
        type: '',
        email: '',
        phone_number: '',
        website: '',
        address: '',
        zip_code: '',
        city: '',
        region: '',
        country: '',
      }
    },
    remove() {
      if (confirm('Êtes-vous sûr de vouloir supprimer cet élement ?')) {
        OrganismService.delete(this, this.organism).then(r => {
          this.$router.go(-1)
        })
      }
    },
    submit() {
      this.$v.organismModel.$touch()
      if (this.organism) {
        OrganismService.update(
          this,
          this.organism,
          this.organismModel
        ).then(r => {})
      } else if (!this.$v.organismModel.$anyError) {
        OrganismService.create(this, this.organismModel).then(r => {
          this.$router.go(-1)
        })
      }
    },
  },
}
</script>

<style scoped></style>
