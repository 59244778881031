<template>
  <v-container fluid class="pt-0">
    <v-row>
      <v-col cols="12" sm="6" md="3" class="py-0 my-0">
        <v-text-field
          v-model="filter.search"
          :dark="!$vuetify.breakpoint.xs"
          :solo="$vuetify.breakpoint.xs"
          clearable
          prepend-inner-icon="mdi-magnify"
          label="Organisme"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
        :class="{
          'pl-5': !$vuetify.breakpoint.xs
        }"
        class="py-0 my-0"
      >
        <v-select
          v-model="filter.type"
          :dark="!$vuetify.breakpoint.xs"
          :solo="$vuetify.breakpoint.xs"
          clearable
          multiple
          :items="
            Object.keys(types).map(attr => ({
              text: types[attr].label,
              value: attr
            }))
          "
          label="Catégorie"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="isLoading" key="placeholder-1">
        <loading-list-placeholder />
      </v-col>
      <v-col v-else-if="filteredOrganisms.length <= 0">
        <empty-list-placeholder>
          Aucune information disponible. Veuillez revoir vos filtres ou ajouter
          un nouvel organisme.
        </empty-list-placeholder>
      </v-col>
      <v-col
        v-for="(item, i) in filteredOrganisms"
        v-else
        :key="i"
        cols="12"
        sm="6"
        md="6"
        lg="4"
      >
        <organism-card :organism="item" @remove="remove(item)" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EmptyListPlaceholder from "@/components/base/EmptyListPlaceholder";
import LoadingListPlaceholder from "@/components/base/LoadingListPlaceholder";

import { ORGANISM_TYPES } from "@/utils/constants";
import OrganismCard from "@/components/bnc/OrganismCard";
import { OrganismService } from "@/services";
export default {
  name: "ListOrganisms",
  components: {
    OrganismCard,
    EmptyListPlaceholder,
    LoadingListPlaceholder
  },
  data() {
    return {
      filter: {
        search: "",
        type: []
      },
      isLoading: true,
      types: ORGANISM_TYPES,
      organisms: []
    };
  },
  mounted() {
    this.getOrganisms();
  },
  computed: {
    filteredOrganisms() {
      return this.organisms.filter(o => {
        const nameCondition =
          this.filter.search.trim().length > 0
            ? o.name
                .trim()
                .toLowerCase()
                .includes(this.filter.search.trim().toLowerCase())
            : true;
        const typeCondition =
          this.filter.type.length > 0
            ? this.filter.type.includes(o.type)
            : true;
        return nameCondition && typeCondition;
      });
    }
  },
  methods: {
    remove(item) {
      const index = this.organisms.indexOf(item);
      if (confirm("Êtes-vous sûr de vouloir supprimer cet élement ?")) {
        OrganismService.delete(this, this.organisms[index].id).then(() => {
          this.organisms.splice(index, 1);
        });
      }
    },
    getOrganisms() {
      this.isLoading = true;
      OrganismService.list(this)
        .then(r => {
          this.organisms = r.results;
        })
        .finally(() => {
          this.isLoading = false;
        });
      this.$forceUpdate();
    }
  }
};
</script>
