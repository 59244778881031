<template>
  <v-container fluid>
    <v-row v-if="$store.getters.isPreviewing">
      <v-col cols="12">
        <v-alert type="warning">
          <h4>{{ $t('previewMode') }}</h4>
          <div>
            {{ $t('goToUserManagementPage') }}
          </div>
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12" md="6" sm="12">
        <v-card>
          <v-card-title>Informations personnelles</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="8" class="offset-1">
                <v-text-field
                  outlined
                  disabled
                  label="E-mail"
                  :value="profile.email"
                />
                <v-text-field
                  v-model="profile.username"
                  outlined
                  :error-messages="profileInfoErrors.username"
                  label="Pseudonyme"
                  @blur="$v.profile.$touch()"
                />
                <v-text-field
                  v-model="profile.first_name"
                  outlined
                  label="Nom"
                />
                <v-text-field
                  v-model="profile.last_name"
                  outlined
                  label="Prénom"
                />

                <!-- <v-text-field
                  label="Companie"
                  value="mycompany"
                /> -->
                <!-- <v-subheader>Role</v-subheader>
                <v-chip
                  class="ma-2"
                  color="indigo"
                  text-color="white"
                >
                  <v-avatar left>
                    <v-icon>mdi-account-circle</v-icon>
                  </v-avatar>
                  Marchand
                </v-chip> -->
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="offset-3">
                <v-btn
                  depressed
                  color="primary--text"
                  :disabled="!hasChanged"
                  @click="updateProfileInfo"
                >
                  Enregistrer
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-card>
          <v-card-title>Mot de passe</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="8" class="offset-1">
                <v-text-field
                  v-model="password.old"
                  :error-messages="passwordErrors.old"
                  outlined
                  label="Mot de passe actuel"
                  @blur="$v.password.old.$touch()"
                />
                <v-text-field
                  v-model="password.new"
                  :error-messages="passwordErrors.new"
                  outlined
                  label="Nouveau mot de passe"
                  @blur="$v.password.new.$touch()"
                />
                <v-text-field
                  v-model="password.newRepeat"
                  :error-messages="passwordErrors.newRepeat"
                  outlined
                  label="Confirmer le mot de passe"
                  @blur="$v.password.newRepeat.$touch()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="offset-3">
                <v-btn depressed color="primary--text" @click="changePassword">
                  Enregistrer
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AccountService from '@/services/AccountService'
import { required, sameAs, minLength } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
export default {
  mixins: [validationMixin],
  validations: {
    password: {
      old: { required },
      new: { required, minLength: minLength(6) },
      newRepeat: {
        required,
        sameAsNew: sameAs(function () {
          return this.password.new
        }),
      },
    },
    profile: { username: { required } },
    validationGroup: ['password', 'profile'],
  },
  data() {
    return {
      loading: false,

      profileCopy: {
        email: '',
        first_name: '',
        last_name: '',
        username: '',
      },
      profile: {
        email: '',
        first_name: '',
        last_name: '',
        username: '',
      },
      password: {
        old: '',
        new: '',
        newRepeat: '',
      },
    }
  },
  computed: {
    profileInfoErrors() {
      const $ = this
      const errs = {
        username: [],
      }
      if ($.$v.profile.username.$dirty) {
        !$.$v.profile.username.required &&
          errs.username.push("Le nom d'utilisateur est requis.")
      }
      return errs
    },
    passwordErrors() {
      const $ = this
      const errs = {
        old: [],
        new: [],
        newRepeat: [],
      }
      if ($.$v.password.old.$dirty) {
        !$.$v.password.old.required &&
          errs.old.push('Ancien mot de passe est requis')
      }
      if ($.$v.password.new.$dirty) {
        !$.$v.password.new.required &&
          errs.new.push('Nouveau mot de passe est requis')
        !$.$v.password.new.minLength &&
          errs.new.push(
            'Longueur minimale du mot de passe doit être respectée (6 caractères)'
          )
      }
      if ($.$v.password.newRepeat.$dirty) {
        if (!$.$v.password.newRepeat.required)
          errs.newRepeat.push('La répétition du mot de passe est requise')
        if (!$.$v.password.newRepeat.sameAsNew)
          errs.newRepeat.push(
            'Le mot de passe doit être identique au nouveau mot de passe'
          )
      }
      return errs
    },
    hasChanged() {
      return !this.lo.isEqual(this.profileCopy, this.profile)
    },
  },
  created() {
    var t = this
    this.loading = true

    AccountService.profile(this).then(function (response) {
      t.profile = response
      t.profileCopy = t.lo.cloneDeep(response)
    })
    t.loading = false
  },
  methods: {
    updateProfileInfo() {
      const t = this
      t.$v.profile.$touch()
      if (!t.$v.profile.$invalid) {
        AccountService.updateProfileInfo(
          this,
          this.profile.first_name,
          this.profile.last_name,
          this.profile.username
        ).then((r) => {
          t.profileCopy = t.lo.cloneDeep(t.profile)
        })
      }
    },
    changePassword() {
      const $ = this
      $.$v.password.$touch()
      if (!$.$v.password.$invalid) {
        AccountService.changePassword(
          $,
          $.password.old,
          $.password.new,
          $.password.newRepeat
        ).then((r) => {})
      }
    },
  },
}
</script>
