/* eslint-disable space-before-function-paren */
import { HTTP, DefaultErrorHandler } from "@/utils/http-common";
const HMD_PARTNERS = process.env.HMD_PARTNERS || "sandbox";

export default {
  list($) {
    return new Promise((resolve, reject) => {
      HTTP.get("/bnc/organism/manage/")
        .then(r => {
          resolve(r.data);
        })
        .catch(err => DefaultErrorHandler($, err, reject));
    });
  },
  get($, id) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/bnc/organism/manage/${id}/`)
        .then(r => {
          resolve(r.data);
        })
        .catch(err => DefaultErrorHandler($, err, reject));
    });
  },
  create($, form) {
    return new Promise((resolve, reject) => {
      HTTP.post("/bnc/organism/manage/", form)
        .then(r => {
          $.$store.commit("success", "Organisme créé");
          resolve(r);
        })
        .catch(err => DefaultErrorHandler($, err, reject));
    });
  },
  update($, id, form) {
    return new Promise((resolve, reject) => {
      HTTP.put(`/bnc/organism/manage/${id}/`, form)
        .then(r => {
          $.$store.commit("success", "Organisme mis a jour");
          resolve(r.data);
        })
        .catch(err => DefaultErrorHandler($, err, reject));
    });
  },
  delete($, id) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/bnc/organism/manage/${id}/`)
        .then(r => {
          $.$store.commit("success", "Organisme supprimé");
          resolve(r.data);
        })
        .catch(err => DefaultErrorHandler($, err, reject));
    });
  }
};
