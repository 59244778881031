<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" v-if="datasets.length <= 0">
        <empty-list-placeholder>
          <template>
            <span v-if="!merchant"> {{ $t('selectMerchant') }}</span>
          </template>
        </empty-list-placeholder>
      </v-col>
      <v-col
        v-else
        v-for="(dataset, i) in datasets"
        :key="i"
        cols="12"
        class="py-2"
      >
        <v-card class="px-2">
          <v-card-title class="py-2">
            <v-row>
              <v-col class="text-h6 font-weight-light">
                <v-icon left>mdi-database</v-icon>
                {{ getTranslation(dataset.name) }}
              </v-col>
              <v-col class="justify-center text-right">
                <v-btn
                  v-if="truncateBtn"
                  small
                  text
                  color="warning"
                  class="mr-5"
                  @click="truncate(dataset)"
                >
                  <v-icon left small> mdi-delete</v-icon>
                  {{ $t('truncate') }}
                </v-btn>
                <!-- <v-btn
                  v-if="downloadBtn"
                  small
                  color="primary"
                  class="mr-5"
                  @click="openDialog(dataset)"
                >
                  <v-icon left small>mdi-download</v-icon>
                  {{ $t('download') }}
                </v-btn> -->
                <v-btn
                  v-if="downloadBtn"
                  small
                  color="primary"
                  @click="$router.push(`/consolidated-file/${dataset.hmd_id}`)"
                >
                  <v-icon left small>mdi-magnify</v-icon>
                  {{ $t('preview') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-col>

      <v-dialog
        v-model="downloadDialog"
        max-width="500"
        @click:outside="
          organism = ''
          dates = []
        "
      >
        <v-card class="pa-5 pb-2">
          <v-form>
            <v-row no-gutters>
              <v-col cols="12">
                <v-select
                  :items="organisms"
                  v-model="organism"
                  label="Organisme"
                ></v-select>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" justify="center" align="center">
                <v-date-picker
                  v-model="dates"
                  range
                  @change="orderDates()"
                ></v-date-picker>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field
                  v-model="datePeriod"
                  label="Période sélectionnée"
                  prepend-icon="mdi-calendar"
                  clearable
                  @click:clear="dates = []"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" align="end">
                <v-btn medium color="primary" @click="download()">
                  <v-icon left>mdi-download</v-icon>
                  {{ $t('download') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { MerchantService, DatasetService } from '@/services'
import EmptyListPlaceholder from '@/components/base/EmptyListPlaceholder'
export default {
  components: {
    EmptyListPlaceholder,
  },
  props: {
    truncateBtn: {
      type: Boolean,
      default: false,
    },
    downloadBtn: {
      type: Boolean,
      default: true,
    },
    merchant: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      datasets: [],
      selectedDataset: null,
      isLoading: true,
      downloadDialog: false,
      organism: null,
      dates: [],
    }
  },
  methods: {
    getTranslation(val) {
      let translation = val
      if (this.translations[val]) {
        translation = this.translations[val]
      }

      return translation
    },
    download() {
      let filters = this.generateFilters()
      DatasetService.download(
        this,
        this.selectedDataset.hmd_id,
        this.selectedDataset.name,
        filters
      )
    },
    truncate(dataset) {
      DatasetService.truncate(this, dataset.hmd_id)
    },
    getmerchants() {
      if (!this.merchant) {
        MerchantService.list(this, true).then((r) => {
          this.merchants = r.results.map((v) => ({ name: v.name, id: v.id }))
        })
      }
    },
    getConsolidatedFiles() {
      this.isLoading = true
      MerchantService.getConsolidatedFiles(this, this.merchant)
        .then((r) => {
          this.datasets = r.map((d) => {
            let name = d.name
            d.name = d.name.replace('consolidation_', '').split('_')[0] // Simplify dataset names
            if (d.name == "anomalies"){
              name = name.replace('consolidation_', '')
              name = name.replace("_", " ")
              d.name = name
            }
            d.name = d.name.charAt(0).toUpperCase() + d.name.slice(1) // Capitalize first letter
            return d
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    orderDates() {
      this.dates.sort()
    },
    openDialog(dataset) {
      this.selectedDataset = dataset
      this.downloadDialog = true
    },
    generateFilters() {
      let filtersList = []
      if (this.organism) {
        filtersList.push({
          field_name: 'organism',
          is_filtered: true,
          query: { operator: 'equals', values: [this.organism] },
        })
      }

      if (this.dates.length == 1) {
        let firstDate = this.dates[0]
        let nextDate = new Date(this.dates[0])
        nextDate.setDate(nextDate.getDate() + 1)
        nextDate =
          nextDate.getFullYear() +
          '-' +
          ('0' + (nextDate.getMonth() + 1)).slice(-2) +
          '-' +
          ('0' + nextDate.getDate()).slice(-2)

        filtersList.push({
          field_name: 'full_date',
          is_filtered: true,
          query: { operator: 'gte', values: [firstDate] },
        })
        filtersList.push({
          field_name: 'full_date',
          is_filtered: true,
          query: { operator: 'lt', values: [nextDate] },
        })
      }
      if (this.dates.length == 2) {
        let firstDate = this.dates[0]
        let nextDate = new Date(this.dates[1])
        nextDate.setDate(nextDate.getDate() + 1)
        nextDate =
          nextDate.getFullYear() +
          '-' +
          ('0' + (nextDate.getMonth() + 1)).slice(-2) +
          '-' +
          ('0' + nextDate.getDate()).slice(-2)

        filtersList.push({
          field_name: 'full_date',
          is_filtered: true,
          query: { operator: 'gte', values: [firstDate] },
        })
        filtersList.push({
          field_name: 'full_date',
          is_filtered: true,
          query: { operator: 'lt', values: [nextDate] },
        })
      }

      let filters = ''
      if (filtersList.length > 0) {
        filters = '?filter=' + JSON.stringify(filtersList)
      }
      return filters
    },
  },
  computed: {
    datePeriod() {
      return this.dates.join(' ~ ')
    },
    organisms() {
      if (this.selectedDataset && this.selectedDataset.name == 'acquereurs') {
        return ['', 'Banque A', 'BNPP', 'CMCIC', 'S.Generale']
      } else if (
        this.selectedDataset &&
        this.selectedDataset.name == 'impayes'
      ) {
        return ['', 'BNPP']
      } else if (this.selectedDataset && this.selectedDataset.name == 'psp') {
        return ['', 'APP', 'CMI', 'FATOURATI', 'MTC', 'PSP B']
      } else {
        return ['']
      }
    },

    translations() {
      let dict = {}
      dict[this.$t('acquirers', 'fr')] = this.$t('acquirers')
      dict[this.$t('unpaidTable', 'fr')] = this.$t('unpaidTable')

      return dict
    },
  },
  watch: {
    merchant(newValue, oldValue) {
      if (this.merchant) this.getConsolidatedFiles()
    },
  },
  mounted() {
    if (this.merchant) this.getConsolidatedFiles()
  },
}
</script>

<style scoped></style>
