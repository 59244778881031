var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"dash pl-10",style:({
    'padding-right': _vm.$store.state.filtersDrawerExpanded
      ? !_vm.$vuetify.breakpoint.smAndDown
        ? '320px'
        : '20px'
      : '12px',
  }),attrs:{"fluid":""}},[_c('core-app-bar'),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"d-print-none text-capitalize",attrs:{"dark":"","color":"blue-grey lighten-2"},on:{"click":function($event){return _vm.handleToggleFilters(true)}}},[_c('v-icon',[_vm._v(" mdi-filter-outline")]),_vm._v(" "+_vm._s(_vm.$t('filters'))+" ")],1),_c('v-btn',{staticClass:"d-print-none text-capitalize ml-2",attrs:{"color":"primary"},on:{"click":_vm.print}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-printer-outline")]),_vm._v(" "+_vm._s(_vm.$t('print'))+" ")],1)],1)],1),_c('v-overlay',{staticClass:"text-center",attrs:{"value":_vm.pageLoading,"z-index":10000,"opacity":0.95,"color":"white"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"64"}}),_c('div',{staticClass:"my-5 primary--text"},[_vm._v(" "+_vm._s(_vm.$t('dataLoading'))+" ")]),_c('v-btn',{attrs:{"text":"","small":"","color":"primary"},on:{"click":function($event){_vm.pageLoading = false}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close")]),_vm._v(" "+_vm._s(_vm.$t('close')))],1)],1),(_vm.isRangeDateSet)?_c('v-row',{staticClass:"mt-4 counters-row",style:({ top: _vm.marginBlocFilter })},[_c('v-col',{staticClass:"px-2 py-1",attrs:{"cols":"12","sm":"4","md":"2"}},[_c('visualization',{staticClass:"header-block too-big-header-block selected-period-counter",attrs:{"card":"","type":"counter","external-value":_vm.dateFilterValue,"options":{
          noData: {
            text: this.$t('filters'),
          },
          align: 'left',
          column: 'result',
          title: {
            value: this.$t('selectedPeriod'),
          },
        },"width":"100%","height":"100%"}})],1),_c('v-col',{staticClass:"px-2 py-1",attrs:{"cols":"12","sm":"4","md":"2"}},[_c('visualization',{staticClass:"header-block",attrs:{"card":"","type":"counter","query":"header_volume_net_encaisse","project":_vm.$store.getters.merchantId,"options":{
          noData: {
            text: this.$t('emptyData'),
          },
          align: 'left',
          column: 'Volume encaissé (net)',
          title: {
            value: this.$t('collectedVolume'),
          },
        },"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"100%"}})],1),_c('v-col',{staticClass:"px-2 py-1",attrs:{"cols":"12","sm":"4","md":"2"}},[_c('visualization',{staticClass:"header-block",attrs:{"card":"","type":"counter","query":"acquereur_header_volume_transaction","project":_vm.$store.getters.merchantId,"options":{
          noData: {
            text: this.$t('emptyData'),
          },
          align: 'left',
          column: 'Volume de transactions',
          title: {
            value: this.$t('numberOfTransactions'),
          },
        },"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"100%"}})],1),_c('v-col',{staticClass:"px-2 py-1",attrs:{"cols":"12","sm":"4","md":"2"}},[_c('visualization',{staticClass:"header-block",attrs:{"card":"","type":"counter","query":"header_acquereur_commission","project":_vm.$store.getters.merchantId,"options":{
          noData: {
            text: this.$t('emptyData'),
          },
          align: 'left',
          column: 'Total Commission',
          title: {
            value: this.$t('commissionsPaid'),
          },
        },"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"100%"}})],1),_c('v-col',{staticClass:"px-2 py-1",attrs:{"cols":"12","sm":"4","md":"2"}},[_c('visualization',{directives:[{name:"show",rawName:"v-show",value:(!_vm.impayesError),expression:"!impayesError"}],ref:"impayes-block",staticClass:"header-block",attrs:{"card":"","type":"counter","query":"header_somme_impayes","project":_vm.$store.getters.merchantId,"options":{
          noData: {
            text: this.$t('emptyData'),
          },
          align: 'left',
          column: 'Total',
          title: {
            value: this.$t('unpaid'),
          },
          value: { format: _vm.thousandsSeparator, suffix: '€' },
        },"external-filters-values":_vm.specificFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"100%"}})],1),_c('v-col',{staticClass:"px-2 py-1",attrs:{"cols":"12","sm":"4","md":"2"}},[_c('visualization',{ref:"avoirs-block",staticClass:"header-block too-big-header-block",attrs:{"card":"","type":"counter","query":"header_avoirs","project":_vm.$store.getters.merchantId,"options":{
          noData: {
            text: this.$t('emptyData'),
          },
          align: 'center',
          column: 'Refund',
          title: {
            value: this.$t('creditOrRedunds'),
            align: 'center',
          },
        },"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"100%"}})],1)],1):_vm._e(),(_vm.isRangeDateSet)?_c('v-row',[_c('v-col',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('div',{staticClass:"divider-title"},[_vm._v(_vm._s(_vm.$t('acceptanceTotal')))])]),_c('v-col',{staticClass:"py-0",attrs:{"lg":"6"}},[_c('v-row',[_c('v-col',{attrs:{"lg":"6"}},[_c('v-switch',{attrs:{"label":_vm.$t('displaySettlementToggleLabel')},on:{"change":_vm.handleShowCurrency},model:{value:(_vm.showSelectCurreny),callback:function ($$v) {_vm.showSelectCurreny=$$v},expression:"showSelectCurreny"}})],1),(_vm.showSelectCurreny)?_c('v-col',{attrs:{"lg":"6"}},[_c('v-select',{attrs:{"label":_vm.$t('currency'),"items":_vm.currencies},on:{"change":_vm.handleSelectCurrency},model:{value:(_vm.currency),callback:function ($$v) {_vm.currency=$$v},expression:"currency"}})],1):_vm._e()],1)],1),_c('v-row',{class:{
          'd-flex': _vm.showSelectCurreny,
          'align-center': _vm.showSelectCurreny,
        }},[_c('v-col',{attrs:{"lg":"6","md":"12"}},[_c('visualization',{staticClass:"card-acceptance main-table",attrs:{"card":"","type":"table","query":"acquereurs_acceptation_carte","translations":_vm.translations,"project":_vm.$store.getters.merchantId,"title":_vm.$t('cardAcceptance'),"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"100%"}})],1),_c('v-col',{attrs:{"lg":"6","md":"12"}},[(_vm.showSelectCurreny && !_vm.currency)?_c('div',{staticClass:"d-flex justify-center flex-column align-center"},[_c('v-icon',{staticClass:"mb-4 grey--text text--lighten-1",attrs:{"size":"100"}},[_vm._v(" mdi-swap-horizontal ")]),_c('span',{staticClass:"text-center text-h5 grey--text text--lighten-1"},[_vm._v(" "+_vm._s(_vm.$t('chooseCurrencyFilter')))])],1):_vm._e(),(_vm.showSelectCurreny && _vm.currency)?_c('visualization',{staticClass:"card-acceptance main-table",attrs:{"card":"","type":"table","query":"acquereurs_acceptation_carte_settlement","external-filters-values":_vm.settlementCurrencyFilters,"translations":_vm.translations,"project":_vm.$store.getters.merchantId,"title":_vm.$t('settlementAcceptance'),"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"100%"}}):_vm._e(),(!_vm.showSelectCurreny)?_c('v-row',{staticClass:"pt-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('visualization',{attrs:{"card":"","type":"pie","query":"new_acquereurs_commission_moyenne","translations":_vm.translations,"project":_vm.$store.getters.merchantId,"title":_vm.$t('distributionByTypeOfCommission'),"options":{
                  xAxes: ['type'],
                  yAxes: ['total_commission'],
                  colors: _vm.paletteChart,
                  tooltip: {
                    y: {
                      formatter: _vm.currencyFormatter,
                    },
                  },
                },"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1)],1):_vm._e(),(!_vm.showSelectCurreny)?_c('v-row',{staticClass:"pt-0"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('visualization',{attrs:{"card":"","type":"table","query":"acquereurs_volume_debit_credit","translations":_vm.translations,"project":_vm.$store.getters.merchantId,"title":_vm.$t('distributionByTypeOfTransaction'),"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1)],1):_vm._e()],1)],1),(_vm.showSelectCurreny)?_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"lg":"6","md":"12"}},[_c('visualization',{attrs:{"card":"","type":"pie","query":"new_acquereurs_commission_moyenne","translations":_vm.translations,"project":_vm.$store.getters.merchantId,"title":_vm.$t('distributionByTypeOfCommission'),"options":{
              xAxes: ['type'],
              yAxes: ['total_commission'],
              colors: _vm.paletteChart,
              tooltip: {
                y: {
                  formatter: _vm.currencyFormatter,
                },
              },
            },"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"lg":"6","md":"12"}},[_c('visualization',{attrs:{"card":"","type":"table","query":"acquereurs_volume_debit_credit","translations":_vm.translations,"project":_vm.$store.getters.merchantId,"title":_vm.$t('distributionByTypeOfTransaction'),"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1)],1):_vm._e(),_c('div',{staticClass:"divider-title"},[_vm._v(" "+_vm._s(_vm.$t('breakdownOfNetVolumeCollected'))+" ")]),_c('v-row',[_c('v-col',{attrs:{"lg":"6","md":"12"}},[_c('visualization',{attrs:{"card":"","type":"table","query":"acquereurs_repartition_du_volume_net_encaisse_table","translations":_vm.translations,"project":_vm.$store.getters.merchantId,"title":_vm.$t('breakdownOfNetVolumeCollectedDesciption'),"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1),_c('v-col',{attrs:{"lg":"6","md":"12"}},[_c('visualization',{attrs:{"card":"","type":"pie","query":"acquereurs_repartition_du_volume_net_encaisse_pie","project":_vm.$store.getters.merchantId,"title":_vm.$t('breakdownOfNetVolumeCollectedDesciption'),"options":{
              xAxes: ['Provider'],
              yAxes: ['Montant (net)'],
              colors: _vm.paletteChart,
              dataLabels: {
                formatter: _vm.percentFormatter,
              },
            },"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"divider-title"},[_vm._v(" "+_vm._s(_vm.$t('consumerOrCorporateAcceptance'))+" ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"pt-0"},[_c('v-col',{staticClass:"pt-0",attrs:{"lg":"8","md":"12"}},[_c('v-row',{staticClass:"pt-0"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","lg":"6"}},[_c('visualization',{staticClass:"card-acceptance secondary-table",attrs:{"card":"","type":"table","query":"acquereurs_acceptation_carte_consumer","translations":_vm.translations,"project":_vm.$store.getters.merchantId,"title":_vm.$t('consumerCardAcceptance'),"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","lg":"6"}},[_c('visualization',{staticClass:"card-acceptance secondary-table",attrs:{"card":"","type":"table","query":"acquereurs_acceptation_carte_corporate","translations":_vm.translations,"project":_vm.$store.getters.merchantId,"title":_vm.$t('corporateCardAcceptance'),"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%"}})],1)],1)],1),_c('v-col',{staticClass:"pt-0",attrs:{"lg":"4","md":"12"}},[_c('v-row',{staticClass:"pt-0"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('visualization',{staticClass:"small-header",attrs:{"card":"","type":"pie","query":"acquereurs_repartition_du_chiffre_daffaire","translations":_vm.translations,"project":_vm.$store.getters.merchantId,"title":_vm.$t('distributionOfRevenues'),"options":{
                      xAxes: ['Type de carte'],
                      yAxes: ['volume_affaires_net'],
                      colors: _vm.paletteChart,
                      tooltip: {
                        y: {
                          formatter: _vm.currencyFormatter,
                        },
                      },
                      plotOptions: {
                        pie: {
                          customScale: 0.8,
                        },
                      },
                    },"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1)],1),_c('v-row',{staticClass:"pt-0"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('visualization',{staticClass:"small-header",attrs:{"card":"","type":"pie","query":"acquereurs_repartition_des_commisions","translations":_vm.translations,"project":_vm.$store.getters.merchantId,"title":_vm.$t('distributionOfCommissions'),"options":{
                      xAxes: ['Type de carte'],
                      yAxes: ['Total commission'],
                      colors: _vm.paletteChart,
                      tooltip: {
                        y: {
                          formatter: _vm.currencyFormatter,
                        },
                      },
                      plotOptions: {
                        pie: {
                          customScale: 0.8,
                        },
                      },
                    },"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1)],1),_c('v-row',{staticClass:"pt-0"})],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"divider-title"},[_vm._v(" "+_vm._s(_vm.$t('vccImpact'))+" ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"pt-0"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","lg":"6"}},[_c('visualization',{staticClass:"card-acceptance secondary-table",attrs:{"card":"","type":"table","query":"acquereurs_acceptation_carte_vcc","translations":_vm.translations,"project":_vm.$store.getters.merchantId,"title":_vm.$t('vccAcceptance'),"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","lg":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('visualization',{attrs:{"card":"","type":"counter","query":"acquereurs_part_des_vcc_dans_corporate","project":_vm.$store.getters.merchantId,"options":{
                      noData: {
                        text: _vm.$t('emptyData'),
                      },
                      align: 'center',
                      column: 'round',
                      title: {
                        value: _vm.$t('vccCommissions'),
                      },
                      value: { suffix: ' %' },
                    },"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('visualization',{attrs:{"card":"","type":"counter","query":"acquereurs_part_commissions_vcc_dans_corporate","project":_vm.$store.getters.merchantId,"options":{
                      noData: {
                        text: _vm.$t('emptyData'),
                      },
                      align: 'center',
                      column: 'round',
                      title: {
                        value: _vm.$t('vccSales'),
                      },
                      value: { suffix: ' %' },
                    },"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1)],1)],1)],1)],1)],1),_c('div',{staticClass:"divider-title"},[_vm._v(_vm._s(_vm.$t('acceptanceBySalesChannel')))]),_c('v-row',[_c('v-col',{attrs:{"lg":"4","md":"12"}},[_c('visualization',{attrs:{"card":"","type":"table","query":"acquereurs_acceptation_par_canal_de_vente","translations":_vm.translations,"project":_vm.$store.getters.merchantId,"title":_vm.$t('acceptanceBySalesChannel'),"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1),_c('v-col',{attrs:{"lg":"4","md":"12"}},[_c('visualization',{attrs:{"card":"","type":"pie","query":"acquereurs_repartition_du_chiffre_d_affaire","project":_vm.$store.getters.merchantId,"title":_vm.$t('distributionOfRevenues'),"options":{
              xAxes: ['sales_channel'],
              yAxes: ['round'],
              colors: _vm.paletteChart,
              tooltip: {
                y: {
                  formatter: _vm.currencyFormatter,
                },
              },
            },"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1),_c('v-col',{attrs:{"lg":"4","md":"12"}},[_c('visualization',{attrs:{"card":"","type":"bar","query":"acquereurs_commision_moyenne_par_canal","translations":_vm.translations,"project":_vm.$store.getters.merchantId,"title":_vm.$t('averageCommissionPerChannel'),"options":{
              xAxes: ['sales_channel'],
              yAxes: [_vm.$t('percent')],
              colors: _vm.paletteChart,
              dataLabels: {
                formatter: _vm.percentFormatter,
              },
            },"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1)],1),_c('div',{staticClass:"divider-title"},[_vm._v(" "+_vm._s(_vm.$t('acceptanceByCountry'))+" (top 10) ")]),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"lg":"6","md":"12"}},[_c('visualization',{attrs:{"card":"","type":"table","query":"acquereurs_acceptation_par_pays","translations":_vm.translations,"project":_vm.$store.getters.merchantId,"title":_vm.$t('acceptanceByCountry'),"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1),_c('v-col',{attrs:{"lg":"6","md":"12"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('visualization',{attrs:{"card":"","type":"bar","query":"acquereurs_repartition_du_chiffre_daffaire_par_pays","translations":_vm.translations,"project":_vm.$store.getters.merchantId,"title":_vm.$t('distributionOfRevenuesByCountry'),"options":{
                  noData: {
                    text: _vm.$t('emptyData'),
                  },
                  colors: _vm.paletteChart,
                  xAxes: [_vm.$t('country')],
                  yAxes: [_vm.$t('revenues')],
                  dataLabels: {
                    formatter: _vm.currencyFormatter,
                    style: {
                      colors: ['#000'],
                    },
                  },
                },"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('visualization',{attrs:{"card":"","type":"bar","query":"acquereurs_repartition_du_chiffre_daffaire_par_pays","translations":_vm.translations,"project":_vm.$store.getters.merchantId,"title":_vm.$t('averageCommissionRatePerCountry'),"options":{
                  noData: {
                    text: _vm.$t('emptyData'),
                  },
                  colors: _vm.paletteChart,
                  xAxes: [_vm.$t('country')],
                  yAxes: [_vm.$t('averageCommission')],
                  dataLabels: {
                    formatter: _vm.percentFormatter,
                    style: {
                      colors: ['#000'],
                    },
                  },
                },"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1)],1)],1)],1),_c('div',{staticClass:"divider-title"},[_vm._v(" "+_vm._s(_vm.$t('distributionOfVolumesPerCountry'))+"(Top 10) ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6","md":"12"}},[_c('visualization',{attrs:{"card":"","type":"table","title":_vm.$t('distributionByCountryOfIssueOfTheCard'),"query":"acquereurs_repartition_volumes_par_pays_bin_top10","translations":_vm.translations,"project":_vm.$store.getters.merchantId,"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"12"}},[_c('visualization',{attrs:{"card":"","type":"bar","query":"acquereurs_repartition_volumes_par_pays_bin_top10","translations":_vm.translations,"project":_vm.$store.getters.merchantId,"title":_vm.$t('distributionByCountryOfIssueOfTheCard'),"options":{
              xAxes: [_vm.$t('binCountry')],
              yAxes: ['Volume'],
              colors: _vm.paletteChart,
              dataLabels: {
                style: {
                  colors: ['#000'],
                },
              },
            },"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1)],1),_c('div',{staticClass:"divider-title"},[_vm._v(_vm._s(_vm.$t('acceptanceByNetwork')))]),_c('v-row',[_c('v-col',{attrs:{"lg":"6","md":"12"}},[_c('visualization',{attrs:{"card":"","type":"table","query":"acquereurs_acceptation_par_brand_tableau","translations":_vm.translations,"project":_vm.$store.getters.merchantId,"title":_vm.$t('acceptanceByNetwork'),"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1),_c('v-col',{attrs:{"lg":"6","md":"12"}},[_c('visualization',{attrs:{"card":"","type":"pie","query":"acquereurs_acceptation_par_brand_graph","project":_vm.$store.getters.merchantId,"title":_vm.$t('distributionOfRevenuesByNetwork'),"options":{
              xAxes: ['Network'],
              yAxes: ['Volume d\'affaire (brut)'],
              colors: _vm.paletteChart,
              dataLabels: {
                formatter: _vm.percentFormatter,
              },
            },"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1)],1),_c('div',{staticClass:"divider-title"},[_vm._v(_vm._s(_vm.$t('readjustments')))]),_c('v-row',[_c('v-col',{attrs:{"lg":"6","md":"12"}},[_c('visualization',{staticClass:"adjustments",attrs:{"card":"","type":"table","query":"acquereurs_reajustements","translations":_vm.translations,"project":_vm.$store.getters.merchantId,"title":_vm.$t('readjustments'),"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1)],1)],1)],1):_vm._e(),_c('FiltersDrawer',{ref:"viz-filters",attrs:{"filters":_vm.filters()},on:{"update":_vm.updateGlobalFilters}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }