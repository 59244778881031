<template>
  <div
    class="hmd_snackbar_queue_container"
    @click="
      e => {
        e.preventDefault();
      }
    "
  >
    <v-snackbar
      v-for="(s, index) in snacks"
      id="hmd_custom_snack"
      :key="index"
      v-model="s.isVisible"
      right
      dismissible
      :timeout="s.timeout"
      :color="s.color"
    >
      <v-row>
        <v-col class="py-0 flex-grow-1 d-flex">
          <div
            class="align-self-center"
            style="white-space: pre-line !important;"
          >
            {{ s.text }}
          </div>
        </v-col>
        <v-col class="py-0 flex-shrink-1 flex-grow-0">
          <v-btn color="white" text @click.native="s.isVisible = false">
            Close
          </v-btn>
        </v-col>
      </v-row>
    </v-snackbar>
  </div>
</template>
<style>
#hmd_custom_snack {
  display: block;
  position: relative;
  margin-bottom: 10px;
  left: -10px;

  white-space: pre-line;
}
.v-snack {
  height: auto !important;
  padding-top: 0px !important;
}
.hmd_snackbar_queue_container {
  position: fixed;
  right: 10px;
  top: 90px;
  height: auto;
  z-index: 9999;
}
</style>
<script>
import moment from "moment";
export default {
  name: "Notify",
  data: () => ({}),
  computed: {
    snacks() {
      const errorLine = this.$store.state.errorLine;
      return this.lo.filter(errorLine, ml => {
        const m = moment(ml.createdAt).add(ml.timeout, "milliseconds");
        if (moment().isBefore(m)) {
          ml.isVisible = true;
          return true;
        } else {
          return false;
        }
      });
    }
  }
};
</script>
