<template>
  <v-container fluid>
    <top-header :title="$t('consolidatedFile')" :displaySelectLanguage="true" />
    <v-row>
      <v-col cols="12"
        ><v-col
          cols="12"
          class="d-flex justify-center align-center"
          v-if="loading"
        >
          <v-progress-circular
            indeterminate
            color="white"
            :size="70"
            :width="7"
          ></v-progress-circular>
        </v-col>
        <v-col v-else>
          <dataset-crud
            v-if="datasetId"
            :title="datasetDisplayName"
            :dataset="datasetId"
            :externalFilters="globalFilters"
            :externalFiltering="true"
            :readOnly="true"
            :downloadable="true"
          ></dataset-crud>
        </v-col>
      </v-col>
    </v-row>
    <FiltersDrawer
      ref="viz-filters"
      v-if="merchantId"
      :filters="filters()"
      @update="updateGlobalFilters"
    />
  </v-container>
</template>

<script>
import moment from 'moment'
import DatasetCrud from '@/components/hmd/Dataset/DatasetCrud'
import TopHeader from '@/components/base/TopHeader'
import FiltersDrawer from '@/components/inputs/FiltersDrawer.vue'
import DatasetService from '@/services/DatasetService'
export default {
  components: {
    DatasetCrud,
    TopHeader,
    FiltersDrawer,
  },
  name: 'PreviewConsolidatedFile',
  data() {
    return {
      loading: true,
      datasetId: this.$route.params.id,
      datasetName: null,
      merchantId: null,
      merchantName: null,
      globalFilters: {},
    }
  },
  mounted() {
    let t = this
    DatasetService.get_informations(this, this.datasetId)
      .then((r) => {
        if (r.data) {
          t.datasetName = r.data.name
          t.merchantId = r.data.project.id
          t.merchantName = r.data.project.name
        }
      })
      .finally(() => {
        t.loading = false
      })
  },
  computed: {
    datasetDisplayName() {
      let name
      if (this.datasetName) {
        let tmp = this.datasetName.replace('consolidation_', '').split('_')[0]
        if (tmp === this.$t('acquirers', 'fr').toLowerCase()) {
          tmp = this.$t('acquirers').toLowerCase()
        } else if (tmp === this.$t('unpaid', 'fr').toLowerCase()) {
          tmp = this.$t('unpaid').toLowerCase()
        }
        name = this.$t('file') + ' ' + tmp + ' - ' + this.merchantName
      } else {
        name = null
      }
      return name
    },
  },
  methods: {
    filters() {
      let name = this.datasetName.replace('consolidation_', '').split('_')[0]
      if (name == 'anomalies') {
        name = this.datasetName.replace('consolidation_', '')
      }
      if (name == 'acquereurs') {
        return [
          {
            name: 'full_date',
            type: 'daterange',
            multiple: true,
            label: this.$t('period', 'en'),
            values: {
              project: this.merchantId,
              column: 'full_date',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
            value: '',
          },
          {
            name: 'organism',
            type: 'checkbox',
            multiple: true,
            label: this.$t('serviceProvider', 'en'),
            values: {
              project: this.merchantId,
              column: 'organism',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
          {
            name: 'merchant_ref_2',
            type: 'list',
            multiple: true,
            label: this.$t('merchantRef', 'en') + ' 2',
            values: {
              project: this.merchantId,
              column: 'merchant_ref_2',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
          {
            name: 'merchant_ref_3',
            type: 'list',
            multiple: true,
            label: this.$t('merchantRef', 'en') + ' 3',
            values: {
              project: this.merchantId,
              column: 'merchant_ref_3',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
          {
            name: 'country',
            type: 'country',
            multiple: true,
            label: this.$t('country', 'en'),
            values: {
              project: this.merchantId,
              column: 'country',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
          {
            name: 'country_bin_code',
            type: 'country',
            multiple: true,
            label: this.$t('binCountry', 'en'),
            values: {
              project: this.merchantId,
              column: 'country_bin_code',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
          {
            name: 'distribution_channel',
            type: 'list',
            multiple: true,
            label: this.$t('distributionChannel', 'en'),
            values: {
              project: this.merchantId,
              column: 'distribution_channel',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
          {
            name: 'sales_channel',
            type: 'list',
            multiple: true,
            label: this.$t('salesChannel', 'en'),
            values: {
              project: this.merchantId,
              column: 'sales_channel',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
          {
            name: 'brand',
            type: 'list',
            multiple: true,
            label: this.$t('brand', 'en'),
            values: {
              project: this.merchantId,
              column: 'brand',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
          {
            name: 'card_type',
            type: 'list',
            multiple: true,
            label: this.$t('card', 'en'),
            values: {
              project: this.merchantId,
              column: 'card_type',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
          {
            name: 'ic_type',
            type: 'list',
            multiple: true,
            label: this.$t('icType', 'en'),
            values: {
              project: this.merchantId,
              column: 'ic_type',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
          {
            name: 'card_usage',
            type: 'list',
            multiple: true,
            label: this.$t('cardUsage', 'en'),
            values: {
              project: this.merchantId,
              column: 'card_usage',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
          {
            name: 'merchant_id',
            type: 'list',
            multiple: true,
            label: this.$t('merchantId', 'en'),
            values: {
              project: this.merchantId,
              column: 'merchant_id::text',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
          {
            name: 'network',
            type: 'list',
            multiple: true,
            label: this.$t('network', 'en'),
            values: {
              project: this.merchantId,
              column: 'network',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
          {
            name: 'status',
            type: 'list',
            multiple: true,
            label: this.$t('status', 'en'),
            values: {
              project: this.merchantId,
              column: 'status',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
        ]
      } else if (name == 'psp') {
        return [
          {
            name: 'full_date',
            type: 'daterange',
            multiple: true,
            label: this.$t('period', 'en'),
            values: {
              project: this.merchantId,
              column: 'full_date',
              dataset: this.datasetName,
            },
            value: '',
          },
          {
            name: 'organism',
            type: 'list',
            multiple: true,
            label: this.$t('pspName', 'en'),
            values: {
              project: this.merchantId,
              column: 'organism',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
          {
            name: 'country',
            type: 'country',
            multiple: true,
            label: this.$t('country', 'en'),
            values: {
              project: this.merchantId,
              column: 'country',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
          {
            name: 'cccty',
            type: 'list',
            multiple: true,
            label: this.$t('binCountry', 'en'),
            values: {
              project: this.merchantId,
              column: 'cccty',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
          {
            name: 'sales_channel',
            type: 'list',
            multiple: true,
            label: this.$t('salesChannel', 'en'),
            values: {
              project: this.merchantId,
              column: 'sales_channel',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
          {
            name: 'brand',
            type: 'list',
            multiple: true,
            label: this.$t('brand', 'en'),
            values: {
              project: this.merchantId,
              column: 'brand',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
          {
            name: 'mp_type',
            type: 'list',
            multiple: true,
            label: this.$t('mopType', 'en'),
            values: {
              project: this.merchantId,
              column: 'mp_type',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
        ]
      } else if (name == 'impayes') {
        return [
          {
            name: 'full_date',
            type: 'daterange',
            multiple: true,
            label: this.$t('period', 'en'),
            values: {
              project: this.merchantId,
              column: 'full_date',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
            value: '',
          },
          {
            name: 'organism',
            type: 'checkbox',
            multiple: true,
            label: this.$t('serviceProvider', 'en'),
            values: {
              project: this.merchantId,
              column: 'organism',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
          {
            name: 'merchant_ref_2',
            type: 'list',
            multiple: true,
            label: this.$t('merchantRef', 'en'),
            values: {
              project: this.merchantId,
              column: 'merchant_ref_2',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
          {
            name: 'country',
            type: 'country',
            multiple: true,
            label: this.$t('country', 'en'),
            values: {
              project: this.merchantId,
              column: 'country',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
          {
            name: 'country_bin_code',
            type: 'country',
            multiple: true,
            label: this.$t('binCountry', 'en'),
            values: {
              project: this.merchantId,
              column: 'country_bin_code',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
          {
            name: 'distribution_channel',
            type: 'list',
            multiple: true,
            label: this.$t('distributionChannel', 'en'),
            values: {
              project: this.merchantId,
              column: 'distribution_channel',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
          {
            name: 'sales_channel',
            type: 'list',
            multiple: true,
            label: this.$t('salesChannel', 'en'),
            values: {
              project: this.merchantId,
              column: 'sales_channel',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
          {
            name: 'brand',
            type: 'list',
            multiple: true,
            label: this.$t('brand', 'en'),
            values: {
              project: this.merchantId,
              column: 'brand',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
          {
            name: 'event_motive_label',
            type: 'list',
            multiple: true,
            label: this.$t('eventReason', 'en'),
            values: {
              project: this.merchantId,
              column: 'event_motive_label',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
        ]
      } else if (name == 'anomalies_reports_air_tahiti') {
        return [
          {
            name: 'full_date',
            type: 'daterange',
            multiple: true,
            label: this.$t('period', 'en'),
            values: {
              project: this.merchantId,
              column: 'full_date',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
            value: '',
          },
          {
            name: 'reason',
            type: 'list',
            multiple: true,
            label: this.$t('anomalyType', 'en'),
            values: {
              project: this.merchantId,
              column: 'reason',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
          {
            name: 'transaction',
            type: 'list',
            multiple: true,
            label: this.$t('transaction', 'en'),
            values: {
              project: this.merchantId,
              column: 'transaction',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
          {
            name: 'office_id',
            type: 'country',
            multiple: true,
            label: this.$t('officeId', 'en'),
            values: {
              project: this.merchantId,
              column: 'office_id',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
        ]
      } else if (name == 'movement') {
        return [
          {
            name: 'date_comptable',
            type: 'daterange',
            label: 'Date comptable',
            values: {
              project: this.merchantId,
              column: 'date_comptable',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
            value: '',
          },
          {
            name: 'fournisseur',
            type: 'list',
            multiple: true,
            label: 'Fournisseur',
            values: {
              project: this.merchantId,
              column: 'fournisseur',
              dataset: this.datasetName,
            },
            value: '',
          },
          {
            name: 'article',
            type: 'list',
            multiple: true,
            label: 'Article',
            values: {
              project: this.merchantId,
              column: 'article',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
          {
            name: 'code_movement',
            type: 'list',
            multiple: true,
            label: 'Code mouvement',
            values: {
              project: this.merchantId,
              column: 'code_movement',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
          {
            name: 'fen_num',
            type: 'list',
            multiple: true,
            label: 'Numéro Fénix',
            values: {
              project: this.merchantId,
              column: 'fen_num',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
          {
            name: 'magasin',
            type: 'list',
            multiple: true,
            label: 'Magasin',
            values: {
              project: this.merchantId,
              column: 'magasin',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
        ]
      } else if (name == 'stock') {
        return [
          {
            name: 'date_comptable',
            type: 'daterange',
            label: 'Date comptable',
            values: {
              project: this.merchantId,
              column: 'date_comptable',
              dataset: this.datasetName,
            },
            value: '',
          },
          {
            name: 'fournisseur',
            type: 'list',
            multiple: true,
            label: 'Fournisseur',
            values: {
              project: this.merchantId,
              column: 'fournisseur',
              dataset: this.datasetName,
            },
            value: '',
          },
          {
            name: 'article',
            type: 'list',
            multiple: true,
            label: 'Article',
            values: {
              project: this.merchantId,
              column: 'article',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
          {
            name: 'code_movement',
            type: 'list',
            multiple: true,
            label: 'Code mouvement',
            values: {
              project: this.merchantId,
              column: 'code_movement',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
          {
            name: 'fen_num',
            type: 'list',
            multiple: true,
            label: 'Numéro Fénix',
            values: {
              project: this.merchantId,
              column: 'fen_num',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
          {
            name: 'fen_bureau',
            type: 'list',
            multiple: true,
            label: 'Bureau',
            values: {
              project: this.merchantId,
              column: 'fen_bureau',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
          {
            name: 'magasin',
            type: 'list',
            multiple: true,
            label: 'Magasin',
            values: {
              project: this.merchantId,
              column: 'magasin',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
        ]
      } else if (name == 'anomalies_inventory') {
        return [
          {
            name: 'date_comptable',
            type: 'daterange',
            // multiple: true,
            label: 'Date comptable',
            values: {
              project: this.merchantId,
              column: 'date_comptable',
              dataset: this.datasetName,
            },
            value: '',
          },
          {
            name: 'magasin',
            type: 'list',
            multiple: true,
            label: 'Magasin',
            values: {
              project: this.merchantId,
              column: 'magasin',
              dataset: this.datasetName,
              conditionName: 'conditions',
            },
          },
        ]
      }
      return []
    },
    updateGlobalFilters(v) {
      let tmp = []
      for (let filter of v) {
        if (filter.operator == 'IN') {
          tmp.push({
            field_name: filter.column,
            is_filtered: true,
            query: { operator: 'in', values: filter.value },
            label: filter.label,
            displayValue: filter.displayValue,
          })
        } else if (filter.operator == 'BETWEEN') {
          let query = { operator: 'range', values: filter.value }
          if (filter.column == 'date_comptable') {
            query = {
              operator: 'range',
              values: [
                filter.value[0],
                moment(filter.value[1]).format('YYYY-MM-DD'),
              ],
            }
          }
          tmp.push({
            field_name: filter.column,
            is_filtered: true,
            query: query,
            label: filter.label,
            displayValue: filter.displayValue,
          })
        }
      }
      this.globalFilters = tmp
    },
  },
}
</script>

<style>
.v-data-table__wrapper {
  border-radius: 8px !important;
}
</style>
