// import HTTP from "HTTP";
import { HTTP } from './http-common'
import store from '@/store'
import router from '@/router'
import { setHmdApiKey } from 'hmd-components'

const HMD_BACKEND_API_URL = 'https://analytics.quantus-bnc.com/api'

const AUTH_TOKEN_KEY = 'token'
const ACCESS_TOKEN_KEY = 'hmd_access_token'

const URL_AUTHENT = HMD_BACKEND_API_URL + '/account/login/'

const URL_REGISTER = HMD_BACKEND_API_URL + '/account/registration/'
const URL_LOGOUT = HMD_BACKEND_API_URL + '/account/logout/'

export function login(parent, creds, redirect) {
  return new Promise((resolve, reject) => {
    HTTP.post(URL_AUTHENT, {
      email: creds.email,
      password: creds.password,
      recaptchaToken: creds.recaptchaToken,
    })
      .then(function (response) {
        setAuthorizationToken(response.data.key)
        setHmdApiKey(response.data.key)
        localStorage.setItem('user', JSON.stringify(response.data))
        if (redirect) router.push(redirect)
        else
          router.push({
            name: 'Analytics' + (store.getters.isUserAdmin ? 'Admin' : ''),
          })
        resolve(response)
      })
      .catch(function (error) {
        if (error.response) {
          parent.$store.commit('error', {
            msg: error.response.data.non_field_errors[0],
            color: 'error',
          })
        } else {
          parent.$store.commit('error', { msg: error + '', color: 'error' })
        }
        reject(error)
      })
  })
}

export function register(parent, account) {
  return new Promise((resolve, reject) => {
    HTTP.post(URL_REGISTER, account)
      .then(function (response) {
        // setAuthorizationToken(response.data.key)
        // Redirect to a specified router
        parent.$router.push('/signup/activation')
      })
      .catch(function (error) {
        // getValitdationErrors(parent, error)
        for (var propt in error.response.data) {
          if (error.response.data[propt]) {
            if (propt !== 'non_field_errors') {
              parent.errors.add(propt, error.response.data[propt])
            } else {
              parent.$store.commit('error', {
                msg: error.response.data[propt][0],
                color: 'error',
              })
            }
          }
        }
        reject(error) // the request failed
      })
  })
}

export function getValidationErrors(parent, error) {
  for (var propt in error.response.data) {
    if (error.response.data[propt]) {
      if (propt !== 'non_field_errors') {
        parent.errors.add(propt, error.response.data[propt])
      } else {
        parent.$store.commit('error', {
          msg: error.response.data[propt],
          color: 'error',
        })
      }
    }
  }
}

export function getAuthorizationToken() {
  return localStorage.getItem(AUTH_TOKEN_KEY)
}

function clearSession() {
  localStorage.removeItem(AUTH_TOKEN_KEY)
  localStorage.removeItem('user')
  localStorage.removeItem('preview')
}

// Get and store id_token in local storage
export function setAuthorizationToken(token) {
  localStorage.setItem(AUTH_TOKEN_KEY, token)
}

export function logout() {
  HTTP.get(URL_LOGOUT)
    .then((resp) => {
      clearSession()
      window.location.href = '/'
    })
    .catch((e) => {
      clearSession()
      window.location.href = '/'
    })
}

export function requireAuth(to, from, next) {
  if (!getAuthorizationToken()) {
    return next({
      path: 'signin',
      query: { redirect: to.fullPath },
    })
  } else return next()
}

export function requireAdminPreviewing(to, from, next) {
  if (
    store.getters.isUserAdmin &&
    !store.getters.isPreviewing &&
    !to.meta.requireAdmin
  )
    return next({ name: 'IndexAdmin' })
  return next()
}
export function requireAdmin(to, from, next) {
  if (store.getters.isUserAdmin) return next()
  else
    return next({
      name: 'Index',
    })
}

export function redirectTo(errorCode) {
  if (errorCode === 404) {
    window.location.href = '/#/notfound'
  } else if (errorCode === 403) {
    window.location.href = '/#/unauthorized'
  }
}
