<template>
  <v-container fluid class="pt-0">
    <v-row>
      <v-col cols="12" sm="6" md="3" class="py-0 my-0">
        <v-text-field
          v-model="filter.search"
          :dark="!$vuetify.breakpoint.xs"
          :solo="$vuetify.breakpoint.xs"
          clearable
          prepend-inner-icon="mdi-magnify"
          label="Marchands"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
        :class="{
          'pl-5': !$vuetify.breakpoint.xs,
        }"
        class="py-0 my-0"
      >
        <v-select
          v-model="filter.type"
          :dark="!$vuetify.breakpoint.xs"
          :solo="$vuetify.breakpoint.xs"
          clearable
          multiple
          :items="
            Object.keys(types).map((attr) => ({
              text: types[attr].label,
              value: attr,
            }))
          "
          label="Catégorie"
        />
      </v-col>
    </v-row>
    <v-slide-y-transition
      leave-absolute
      hide-on-leave
      group
      mode="out-in"
      tag="div"
      class="row"
    >
      <v-col v-if="isLoading" key="placeholder-1">
        <loading-list-placeholder />
      </v-col>
      <v-col v-else-if="filteredMerchants.length <= 0" key="placeholder-2">
        <empty-list-placeholder>
          Aucune information disponible. Veuillez revoir vos filtres ou ajouter
          un nouveau marchand.
        </empty-list-placeholder>
      </v-col>
      <v-col
        v-for="(item, i) in filteredMerchants"
        v-else
        :key="i"
        cols="12"
        sm="6"
        md="6"
        lg="4"
      >
        <merchant-card
          :merchant="item"
          @edit="edit(item)"
          @preview="preview(item)"
          @remove="remove(item)"
        />
      </v-col>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
import EmptyListPlaceholder from '@/components/base/EmptyListPlaceholder'
import LoadingListPlaceholder from '@/components/base/LoadingListPlaceholder'

import { MERCHANT_TYPES } from '@/utils/constants'
import MerchantCard from '@/components/bnc/MerchantCard'
import { MerchantService } from '@/services'
export default {
  name: 'ListMerchants',
  components: {
    MerchantCard,
    EmptyListPlaceholder,
    LoadingListPlaceholder,
  },
  data() {
    return {
      filter: {
        search: '',
        type: [],
      },
      isLoading: true,
      types: MERCHANT_TYPES,
      merchants: [],
      editMerchant: null,
    }
  },
  computed: {
    filteredMerchants() {
      return this.merchants.filter((o) => {
        const nameCondition =
          this.filter.search.trim().length > 0
            ? o.name
                .trim()
                .toLowerCase()
                .includes(this.filter.search.trim().toLowerCase())
            : true
        const typeCondition =
          this.filter.type.length > 0 ? this.filter.type.includes(o.type) : true
        return nameCondition && typeCondition
      })
    },
  },
  mounted() {
    this.getMerchants()
  },
  methods: {
    preview(item) {
      MerchantService.preview(this, item.id)
    },
    remove(item) {
      const index = this.merchants.indexOf(item)
      if (confirm('Êtes-vous sûr de vouloir supprimer cet élement ?')) {
        MerchantService.delete(this, this.merchants[index].id).then(() => {
          this.merchants.splice(index, 1)
        })
      }
    },
    edit(item) {
      this.editMerchant = item
    },
    handleSelection(i) {
      this.$emit('selected', this.merchants[i])
    },
    getMerchants() {
      this.isLoading = true
      MerchantService.list(this)
        .then((r) => {
          this.merchants = r.results.filter((row) => row.name != 'bnc')
        })
        .finally(() => {
          this.isLoading = false
        })
      this.$forceUpdate()
    },
  },
}
</script>
