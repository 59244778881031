<template>
  <v-container fluid>
    <top-header title="Utilisateurs" />
    <v-row>
      <v-col>
        <manage-users fill-height searchable />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import UsersCrud from '@/components/hmd/Users/UsersCrud'
import TopHeader from '@/components/base/TopHeader'
export default {
  name: 'Utilisateurs',
  components: {
    'manage-users': UsersCrud,
    TopHeader,
  },
}
</script>

<style scoped></style>
