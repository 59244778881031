<template>
  <v-container fluid class="pa-0">
    <v-form ref="form" v-model="isFormValid" lazy-validation>
      <v-row class="black--text">
        <v-col cols="2" md="2" class="pb-0">
          <div class="mt-4 mb-3 subtitle-1 font-weight-medium">
            {{ $t('kpiIndicator') }}
          </div>
        </v-col>
        <v-col cols="6" md="6" class="pb-0">
          <v-text-field
            hide-details
            v-model="kpiRuleValueName"
            outlined
            disabled
          ></v-text-field>
          <!-- <v-select
          disabled
          @change="selectedKpi"
          placeholder="Veuillez choisir un indicateur"
          :items="kpis"
          outlined
          item-text="name"
          item-value="name"
          :value="kpiRuleValue.name"
          hide-details
        ></v-select> -->
        </v-col>
      </v-row>
      <v-row class="mt-2">
        <v-col class="pb-0">
          <div class="mb-2 subtitle-2 font-weight-light">
            {{ $t('parametersToUse') }}
            <span class="formula-parameter current">M</span> :
            {{ $t('currentAmount') }}
            <span class="formula-parameter reference">R</span> :
            {{ $t('referenceValue') }}
            {{ $t('reference') }}
          </div>
          <div class="mb-2 subtitle-2 font-weight-light">
            <span
              >{{ $t('allowedMathematicalOperators') }} :
              (+),(-),(*),(/),(>),(&lt;),(>=),(&lt;=)</span
            >
            <span class="ml-5">
              {{ $t('exampleFormula') }} : 1,3*R&lt;M&lt;1,5*R</span
            >
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0">
          <v-tabs v-model="tab" icons-and-text class="mb-4">
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#tab-1">
              <span>
                <v-icon left>mdi-clipboard-text-outline</v-icon>
                <span> {{ $t('kpiRules') }}</span>
              </span>
            </v-tab>
            <!-- <v-tab href="#tab-2">
              <span>
                <v-icon left>mdi-clipboard-text-outline</v-icon>
                <span> Règles TENDANCE </span>
              </span>
            </v-tab> -->
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1">
              <v-row class="d-flex justify-center">
                <v-col class="pt-2" cols="12" md="5">
                  <div class="mb-3 subtitle-1 font-weight-medium">
                    {{ $t('KPIReferenceValue') }} [R]
                  </div>
                  <v-text-field
                    :rules="validationErrors.reference_value"
                    v-model="kpiRuleValue.value_comparator.reference_value"
                    outlined
                    number
                    :placeholder="$t('pleaseAddAValue')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row
                class="d-flex justify-start justify-md-end"
                v-for="(
                  r, i
                ) in kpiRuleValue.value_comparator.color_rules.filter(
                  (o) => !o.delete
                )"
                :key="i"
              >
                <template v-if="!r.delete">
                  <v-col cols="12" md="3" style="max-width: 130px" class="pb-0">
                    <v-select
                      v-model="r.icon"
                      :items="value_icons"
                      item-value="value"
                      outlined
                      clearable
                    >
                      <template v-slot:item="{ item }">
                        <v-icon>{{ item }}</v-icon>
                      </template>
                      <template v-slot:selection="{ item }">
                        <v-icon>{{ item }}</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="3" style="max-width: 105px" class="pb-0">
                    <v-select
                      v-model="r.color"
                      :items="colors"
                      outlined
                      style="width: 80px"
                    >
                      <template v-slot:item="{ item }">
                        <span
                          class="inline-block elevation-2 rule-color"
                          :style="{ backgroundColor: item }"
                        ></span>
                      </template>
                      <template v-slot:selection="{ item }">
                        <span
                          class="inline-block elevation-2 rule-color"
                          :style="{ backgroundColor: item }"
                        ></span>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="9" md="3" class="pb-0">
                    <v-text-field
                      :rules="validationErrors.formula"
                      v-model="r.formula"
                      outlined
                      :label="$t('formula')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3" md="3" class="pb-0">
                    <v-btn
                      small
                      fab
                      color="error"
                      text
                      @click="removeKpiRule(r)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                </template>
              </v-row>

              <v-row class="d-flex text-center">
                <v-col>
                  <v-btn text color="primary" @click="addKpiRule">
                    <v-icon left>mdi-plus</v-icon> {{ $t('addRule') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-tab-item>
            <!-- <v-tab-item value="tab-2">
              <v-col class="pt-0" cols="12" md="5">
                  <div class="mb-3 subtitle-1 font-weight-medium">
                    Période de référence [R]
                  </div>
                  <v-select
                    placeholder="Veuillez choisir une période de référence"
                    :items="comparatorTemplates"
                    item-text="label"
                    return-object
                    outlined
                    v-model="comparatorTemplatesDefault"
                  ></v-select>
                </v-col>
              <v-row
                class="d-flex justify-start justify-md-end pt-2"
                v-for="(r,
                i) in kpiRuleValue.tendency_comparator.color_rules.filter(
                  o => !o.delete
                )"
                :key="i"
              >
                <v-col cols="12" md="3" style="max-width:130px;" class="pb-0">
                  <v-select
                    v-model="r.icon"
                    :items="value_icons"
                    item-value="value"
                    clearable
                    outlined
                  >
                    <template v-slot:item="{ item }">
                      <v-icon>{{ item }}</v-icon>
                    </template>
                    <template v-slot:selection="{ item }">
                      <v-icon>{{ item }}</v-icon>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" md="3" style="max-width:105px;" class=" pb-0">
                  <v-select
                    v-model="r.color"
                    :items="colors"
                    style="width:80px;"
                    outlined
                  >
                    <template v-slot:item="{ item }">
                      <span
                        class="inline-block elevation-2 rule-color"
                        :style="{ backgroundColor: item }"
                      ></span>
                    </template>
                    <template v-slot:selection="{ item }">
                      <span
                        class="inline-block elevation-2 rule-color"
                        :style="{ backgroundColor: item }"
                      ></span>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="9" md="3" class="flex-grow-1 pb-0">
                  <v-text-field
                    :rules="validationErrors.formula"
                    v-model="r.formula"
                    outlined
                    label="Formule"
                  ></v-text-field>
                </v-col>
                <v-col cols="3" md="3">
                  <v-btn
                    small
                    fab
                    color="error"
                    text
                    @click="removeTendencyRule(r)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex justify-center">
                  <v-btn text color="primary" @click="addTendencyRule">
                    <v-icon left>mdi-plus</v-icon> Ajouter une règle
                  </v-btn>
                </v-col>
              </v-row>
            </v-tab-item> -->
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { KpiService } from '@/services'
import { isEmpty, cloneDeep, toNumber } from 'lodash'
import { TENDENCY_ICONS, VALUE_ICONS } from '@/utils/constants'
export default {
  name: 'CreateUpdateKpiRule',

  created() {
    //this.listKpis()
    // this.getComparatorTemplates()
    // this.$emit('input', this.kpiRuleValue)
    if (this.value) this.unpackValue(this.value)
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  computed: {
    hasOneKpiRule() {
      return this.kpiRuleValue.value_comparator.color_rules.length <= 1
    },
    hasOneTendencyRule() {
      return this.kpiRuleValue.tendency_comparator.color_rules.length <= 1
    },
    kpiRuleValueName() {
      return this.getTranslation(this.kpiRuleValue.name)
    },
    translations() {
      let dict = {}
      dict[this.$t('evolutionCollectedVolume', 'fr')] = this.$t(
        'evolutionCollectedVolume'
      )
      dict[this.$t('evolutionTotalRateCommission', 'fr')] = this.$t(
        'evolutionTotalRateCommission'
      )
      dict[this.$t('acceptanceRateNetWithoutDup', 'fr')] = this.$t(
        'acceptanceRateNetWithoutDup'
      )
      dict[this.$t('collectedVolume', 'fr')] = this.$t('collectedVolume')
      dict[this.$t('vccRate', 'fr')] = this.$t('vccRate')
      dict[this.$t('averageCommissionRate', 'fr')] = this.$t(
        'averageCommissionRate'
      )
      dict[this.$t('corporateRate', 'fr')] = this.$t('corporateRate')
      dict[this.$t('ratesUnpaid', 'fr')] = this.$t('ratesUnpaid')
      dict[this.$t('averageInterchangeRate', 'fr')] = this.$t(
        'averageInterchangeRate'
      )
      dict[this.$t('transactionsVolume', 'fr')] = this.$t('transactionsVolume')

      return dict
    },
  },
  methods: {
    isEmpty,
    // selectedKpi() {
    //   this.kpiRuleValue.value_comparator.rules = this.kpiRuleValue.kpi.color_rules.filter(
    //     cr => isEmpty(cr.icon)
    //   )
    //   this.kpiRuleValue.tendency_comparator.rules = this.kpiRuleValue.kpi.color_rules.filter(
    //     cr => !isEmpty(cr.icon)
    //   )
    // },
    listKpis() {
      KpiService.get(this).then((r) => {
        this.kpis = r.results
      })
    },
    // getComparatorTemplates() {
    //   KpiService.ComparatorTemplate.get(this).then(r => {
    //     this.comparatorTemplates = r.map(o => {
    //       let value = cloneDeep(o)
    //       delete value.label
    //       delete value.modifiable_fields
    //       o.value = value
    //       return o
    //     })
    //   })
    // },
    removeKpiRule(r) {
      if (confirm(this.$t('deleteElement'))) r.delete = true
    },
    removeTendencyRule(r) {
      if (confirm(this.$t('deleteElement'))) r.delete = true
    },
    addTendencyRule() {
      this.kpiRuleValue.tendency_comparator.color_rules.push({
        pk: null,
        icon: 'mdi-arrow-up',
        color: '#9CC970',
        formula: '',
        delete: false,
      })
    },
    addKpiRule() {
      this.kpiRuleValue.value_comparator.color_rules.push({
        pk: null,
        icon: null,
        color: 'green',
        formula: '',
        delete: false,
      })
    },
    unpackValue(vn) {
      this.kpiRuleValue = vn

      if (this.$refs.form) this.$refs.form.validate()
    },
    getTranslation(val) {
      let translation = val

      if (this.translations[val]) {
        translation = this.translations[val]
      }

      return translation
    },
  },
  watch: {
    isFormValid() {
      this.$emit('valid', this.isFormValid)
    },
    value(vn) {
      this.unpackValue(vn)
    },
    kpiRuleValue: {
      deep: true,
      handler(newValue, oldValue) {
        if (this.$refs.form) this.$refs.form.validate()

        this.$emit('input', newValue)
      },
    },
  },
  data() {
    return {
      validationErrors: {
        formula: [(v) => !!v || this.$t('fieldRequired')],
        reference_value: [
          (v) => !!v || this.$t('fieldRequired'),
          (v) => !!toNumber(v) || this.$t('pleaseAddANumber'),
        ],
      },
      isFormValid: false,
      value_comparator: null,
      tendency_comparator: null,
      tab: '',
      value_icons: VALUE_ICONS,
      tendency_icons: TENDENCY_ICONS,

      kpiRuleValue: {
        name: null,
        value_comparator: null,
        tendency_comparator: null,
      },
      colors: ['red', 'blue', 'orange', 'yellow', '#9CC970', 'grey'],
      comparatorTemplates: ['3 derniers mois'],
      comparatorTemplatesDefault: '3 derniers mois',
      kpis: [],
    }
  },
}
</script>

<style lang="scss" scoped>
.rule-formula {
  text-align: center;
  display: inline-block;
  margin-left: 15px !important;
}
.formula-parameter {
  border-radius: 5px;
  padding: 2px;
  margin: 0px 5px;
  width: 25px;
  height: 25px;
  display: inline-block;
  color: white;
  text-align: center;
  &.reference {
    background-color: #8b8b8b;
  }
  &.current {
    background-color: #3a77ff;
  }
}
.rule-color,
.rule-tendency-icon {
  border-radius: 5px;
  width: 25px;
  height: 25px;
  display: inline-block;
}
</style>
