const MERCHANT_TYPES = {
  'compagnie-aerienne': {
    icon: 'mdi-shield-airplane',
    label: 'Compagnie Aérienne',
  },
  'agence-de-voyage': {
    icon: 'mdi-wallet-travel',
    label: 'Agence de voyage',
  },
  services: {
    icon: 'mdi-account-tie',
    label: 'Services',
  },
  autre: {
    icon: 'mdi-shield-account-outline',
    label: 'Autre',
  },
}
const ORGANISM_TYPES = {
  acquereurs: {
    label: 'Acquéreur',
    icon: 'mdi-domain',
  },
  psp: {
    label: 'PSP',
    icon: 'mdi-domain',
  },
  bsp_iata: {
    label: 'BSP/IATA',
    icon: 'mdi-domain',
  },
  gds: {
    label: 'GDS',
    icon: 'mdi-domain',
  },
}

const TENDENCY_ICONS = [
  'mdi-arrow-top-right',
  'mdi-arrow-right',
  'mdi-arrow-bottom-right',
]

const VALUE_ICONS = [
  'mdi-emoticon-excited-outline',
  'mdi-emoticon-happy-outline',
  'mdi-emoticon-neutral-outline',
  'mdi-emoticon-sad-outline',
  'mdi-emoticon-cry-outline',
]

const HOME_KPIS_DYNAMIC_MAPPING = {
  acquereur: [
    'Taux de commission moyen',
    'Volume encaissé (net)',
    'Taux impayés prélevés',
    'Taux interchange moyen',
    'Taux de Corporate',
    'Taux CA VCC',
    'KPIs PSP',
  ],
  psp: [
    'Volume transactions',
    "Taux d'acceptation net (sans doublons)",
  ],
}

const HOME_VIZ_DYNAMIC_MAPPING = {
  acquereur: [],
  psp: [
    'Répartition par authent status',
    'Répartition par challenge mode',
  ],
}
const HOME_FILTERS = {
  acquereur: [
    'full_date',
    'full_date_ref',
    'country',
    'network',
    'organism',
    'sales_channel',
    'brand',
  ],
  psp: [
    'full_date',
    'full_date_ref',
    'country',
    'organism',
    'brand',
    'sales_channel',
  ],
}

export {
  MERCHANT_TYPES,
  ORGANISM_TYPES,
  TENDENCY_ICONS,
  VALUE_ICONS,
  HOME_KPIS_DYNAMIC_MAPPING,
  HOME_VIZ_DYNAMIC_MAPPING,
  HOME_FILTERS,
}
