<template>
  <v-app>
    <core-drawer />

    <core-view />
    <v-offline @detected-condition="amIOnline" />
    <v-snackbar
      :value="!$store.state.isOnline"
      color="error"
      :timeout="-1"
      top
      right
    >
      <v-icon color="white" left>
        mdi-earth-off
      </v-icon>
      Vous n'êtes pas connecté, veuillez vérifier votre connexion internet!
    </v-snackbar>
    <error-handler />
  </v-app>
</template>

<script>
import ErrorHandler from '@/components/utils/ErrorHandler'
import VOffline from 'v-offline'
export default {
  components: {
    VOffline,
    ErrorHandler,
    CoreDrawer: () => import('@/components/layouts/core/Drawer'),
    /* CoreFooter: () => import('@/components/layouts/core/Footer'), */

    CoreView: () => import('@/components/layouts/core/View'),
  },
  data: () => ({
    onLine: true,
  }),
  methods: {
    amIOnline(e) {
      this.$store.commit(e ? 'online' : 'offline')
    },
  },
}
</script>
