<template>
  <v-container fluid>
    <top-header title="Fichiers" />
    <v-row>
      <v-col>
        <manage-files
          fill-height
          searchable
          :height="$vuetify.breakpoint.height - 380"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import FilesCrud from '@/components/hmd/Files/FilesCrud'
import TopHeader from '@/components/base/TopHeader'
export default {
  name: 'Utilisateurs',
  components: {
    'manage-files': FilesCrud,
    TopHeader,
  },
}
</script>

<style scoped></style>
