<template>
  <v-data-table
    :loading="loading"
    :headers="table.cols"
    :items="table.rows"
    :options.sync="options"
    :server-items-length="totalItems"
    :fixed-header="fillHeight"
    :height="fillHeight ? height : 'auto'"
    :footer-props="{
      'items-per-page-options': [10, 20, 30, 40, 50],
    }"
    class="rounded-lg"
  >
    <template v-slot:top>
      <v-toolbar class="rounded-lg" flat color="white" height="90">
        <v-container fluid class="rounded-lg">
          <v-row>
            <v-col cols="10" sm="8" md="6" lg="6">
              <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                placeholder="Recherche..."
              />
            </v-col>
            <v-col cols="2" sm="4" md="6" lg="6" class="text-right">
              <upload-file-btn @create="get" />
            </v-col>
          </v-row>
        </v-container>
      </v-toolbar>
    </template>
    <template v-if="!readOnly" v-slot:item.size="{ item }">
      <v-chip>{{ Math.round(item.size / 1000) }} kb</v-chip>
    </template>
    <template v-if="!readOnly" v-slot:item.details.status="{ item }">
      <v-chip v-if="item.details.status">
        {{ item.details.status }}
      </v-chip>
    </template>
    <template v-if="!readOnly" v-slot:item.action="{ item }">
      <v-btn
        rounded
        fab
        dark
        color="blue lighten-2"
        class="mr-3"
        x-small
        @click="downloadItem(item)"
      >
        <v-icon small> mdi-download </v-icon>
      </v-btn>
      <v-btn rounded fab dark color="red" x-small @click="deleteItem(item)">
        <v-icon small> mdi-delete </v-icon>
      </v-btn>
    </template>
    <template v-slot:no-data>
      <span> Aucun fichier n'a été trouvé. </span>
    </template>
  </v-data-table>
</template>

<script>
import UploadFileBtn from '@/components/bnc/UploadFileBtn'
import { MerchantService, FileService } from '@/services'
import axios from 'axios'
export default {
  components: {
    UploadFileBtn,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    exportable: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    fillHeight: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 300,
    },
    merchantId: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    dialog: false,
    search: '',
    totalItems: null,
    options: {},
    table: {
      rows: [],
      cols: [
        {
          text: 'Nom',
          value: 'original_name',
          sortable: false,
        },
        {
          text: 'Marchand',
          value: 'project_name',
          sortable: false,
        },
        {
          text: 'Organisme',
          value: 'details.organism.name',
          sortable: false,
        },

        {
          text: 'Taille',
          value: 'size',
          sortable: false,
        },
        {
          text: 'Extension',
          value: 'extension',
          sortable: false,
        },
        {
          text: "Date d'ajout",
          value: 'uploaded_at',
          sortable: false,
        },
        {
          text: 'Ajouté par',
          value: 'uploaded_by',
          sortable: false,
        },
        {
          text: 'Etat',
          value: 'details.status',
          sortable: false,
        },
        {
          text: 'Actions',
          value: 'action',
          sortable: false,
        },
      ],
    },
    editedIndex: -1,
    loading: false,
    request: null,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
  },

  mounted() {
    const $ = this
    this.get()
  },
  watch: {
    search() {
      this.options.page = 1
      this.get()
    },
    options: {
      handler() {
        this.get()
      },
      deep: true,
    },
  },
  methods: {
    cancel() {
      if (this.request) {
        this.request.cancel()
      }
    },
    get() {
      const self = this
      self.loading = true
      this.cancel()
      const axiosSource = axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }
      FileService.list(
        self,
        this.search,
        this.options.page,
        this.options.itemsPerPage,
        axiosSource.token,
        this.merchantId
      )
        .then((r) => {
          self.table.rows = r.results
          self.totalItems = r.count
        })
        .finally(() => {
          self.loading = false
        })
    },
    downloadItem(item) {
      const index = this.table.rows.indexOf(item)
      FileService.download(this, this.table.rows[index]).then((link) => {
        link.download = this.table.rows[index].original_name
        link.click()
      })
    },
    deleteItem(item) {
      const index = this.table.rows.indexOf(item)
      if (confirm('Êtes-vous sûr de vouloir supprimer cet élement ?')) {
        FileService.delete(this, this.table.rows[index]).then(() => {
          this.table.rows.splice(index, 1)
        })
      }
    },
  },
}
</script>

<style scoped></style>
