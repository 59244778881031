import { HTTP } from '@/utils/http-common'

function print_error($, message, error) {
  console.error(error)
  $.$store.commit('push', {
    msg: message,
    color: 'error',
  })
}

function popin_success($, message) {
  $.$store.commit('push', {
    msg: message,
    color: 'success',
  })
}

export default {
  get_group_company($, groupCompanyId) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/account/settings/company/groups/${groupCompanyId}/`)
        .then(result => {
          resolve(result.data)
        })
        .catch(error => {
          print_error($, 'Could not fetch Group Company', error)
          reject(error)
        })
    })
  },

  //   create_group_company($, name, companyId) {
  //     return new Promise((resolve, reject) => {
  //       const group = {
  //         name: name,
  //         company: companyId,
  //       }
  //       HTTP.post('/account/settings/company/groups/', group)
  //         .then(result => {
  //           popin_success($, 'Group Company successfully created')
  //           resolve(result.data)
  //         })
  //         .catch(error => {
  //           print_error($, 'Could not create the group company', error)
  //           reject(error)
  //         })
  //     })
  //   },

  list_group_company($) {
    return new Promise((resolve, reject) => {
      HTTP.get('/account/settings/company/groups/')
        .then(result => {
          resolve(result.data)
        })
        .catch(error => {
          print_error($, 'Could not fetch all Group Company', error)
          reject(error)
        })
    })
  },

  //   delete_group_company($, groupCompanyId) {
  //     return new Promise((resolve, reject) => {
  //       HTTP.delete(`/account/settings/company/groups/${groupCompanyId}`)
  //         .then(result => {
  //           popin_success($, 'Group Company successfully deleted')
  //           resolve(result.data)
  //         })
  //         .catch(error => {
  //           print_error($, 'Could not delete the group company', error)
  //           reject(error)
  //         })
  //     })
  //   },

  //   update_group_company($, groupCompanyId, groupCompanyInfo) {
  //     return new Promise((resolve, reject) => {
  //       HTTP.patch(
  //         `/account/settings/company/groups/${groupCompanyId}/`,
  //         groupCompanyInfo
  //       )
  //         .then(result => {
  //           popin_success($, 'Group Company successfully updated')
  //           resolve(result.data)
  //         })
  //         .catch(error => {
  //           print_error($, 'Could not update company info', error)
  //           reject(error)
  //         })
  //     })
  //   },

  add_member_group_company($, groupCompanyId, userId) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/account/settings/company/groups/${groupCompanyId}/${userId}/`)
        .then(result => {
          popin_success($, 'Member successfully added to Group Company')
          resolve(result.data)
        })
        .catch(error => {
          print_error($, 'Could not add the member to the group company', error)
          reject(error)
        })
    })
  },

  remove_member_group_company($, groupCompanyId, userId, showPopin = true) {
    return new Promise((resolve, reject) => {
      if (!groupCompanyId || !userId) resolve()
      HTTP.delete(
        `/account/settings/company/groups/${groupCompanyId}/${userId}/`
      )
        .then(result => {
          if (showPopin) {
            popin_success($, 'Member successfully removed from Group Company')
          }

          resolve(result.data)
        })
        .catch(error => {
          if (showPopin) {
            print_error(
              $,
              'Could not remove the member from the group company',
              error
            )
          }

          reject(error)
        })
    })
  },

  //   get_my_group($) {
  //     return new Promise((resolve, reject) => {
  //       HTTP.get('/account/settings/company/mygroups/')
  //         .then(response => {
  //           resolve(response.data)
  //         })
  //         .catch(error => {
  //           reject(error)
  //         })
  //     })
  //   },
}
