import { render, staticRenderFns } from "./CreateUpdateKpiRuleDialog.vue?vue&type=template&id=158e40a7&scoped=true&"
import script from "./CreateUpdateKpiRuleDialog.vue?vue&type=script&lang=js&"
export * from "./CreateUpdateKpiRuleDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "158e40a7",
  null
  
)

export default component.exports