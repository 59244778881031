<template>
  <v-container>
    <v-form ref="form" v-model="valid" lazy-validation>
      <div class="pl-5 pr-5">
        <span class="text-subtitle-2 grey--text">A</span>
        <v-text-field
          class="pt-0"
          v-model="email"
          placeholder="mail"
          clearable
          required
          :rules="emailRules"
        ></v-text-field>
      </div>
      <div class="pl-5 pr-5">
        <span class="text-subtitle-2 grey--text">Objet</span>
        <v-text-field class="pt-0" placeholder="mail" clearable></v-text-field>
      </div>
      <div class="pl-5 pr-5">
        <span class="text-subtitle-2 grey--text">Message</span>
        <v-textarea class="pt-0" label="mail" rows="6" solo></v-textarea>
      </div>
      <div class="pl-5 pr-5">
        <span class="text-subtitle-2 grey--text">Pièces jointes :</span>
      </div>
      <div class="pl-5 pr-5">
        <span class="text-subtitle-2 grey--text">Premier envoi :</span>
        <!-- DATE -->
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          left
          offset-x
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="pt-0"
              v-model="dateFormatted"
              prepend-inner-icon="mdi-calendar"
              v-bind="attrs"
              @blur="date = parseDate(dateFormatted)"
              v-on="on"
              :rules="[v => !!v || 'Date est requise']"
              required
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            no-title
            @input="menu1 = false"
          ></v-date-picker>
        </v-menu>
        <!-- Heure -->
        <v-menu
          ref="menu"
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="time"
          transition="scale-transition"
          left
          offset-y
          offset-x
          max-width="200px"
          min-width="200px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="pt-0"
              v-model="time"
              prepend-inner-icon="mdi-clock-time-four-outline"
              v-bind="attrs"
              v-on="on"
              :rules="[v => !!v || 'Heure est requise']"
              required
            ></v-text-field>
          </template>
          <v-time-picker
            no-title
            v-if="menu2"
            v-model="time"
            full-width
            @click:minute="$refs.menu.save(time)"
          ></v-time-picker>
        </v-menu>
      </div>
      <div class="pl-5 pr-5">
        <span class="text-subtitle-2 grey--text">Recurrence :</span>
        <v-select
          class="pt-0"
          label="Tous les jours"
          clearable
          required
          :rules="[v => !!v || 'Recurrence est requis']"
          v-model="select"
        >
        </v-select>
      </div>
      <div class="d-flex align-baseline justify-center">
        <v-btn small @click="reset"> Annuler</v-btn>
        <v-btn color="primary" small :disabled="!valid" @click="validate">
          Valider</v-btn
        >
      </div>
    </v-form>
  </v-container>
</template>

<script>
export default {
  data: vm => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    dateFormatted: vm.formatDate(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10)
    ),
    menu1: false,
    time: null,
    menu2: false,
    valid: true,
    email: '',
    select: null,
    emailRules: [v => /.+@.+\..+/.test(v) || 'E-mail doit être valide'],
  }),

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date)
    },
  },

  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date)
    },
  },

  methods: {
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    parseDate(date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    validate() {
      this.$refs.form.validate()
    },
    reset() {
      this.$refs.form.reset()
    },
  },
}
</script>
