<template>
  <v-row :class="{ 'pb-4': $vuetify.breakpoint.xs }">
    <v-col cols="12" class="pt-0 d-flex" md="6" lg="6" sm="6">
      <slot name="searchable" />
      <span class="display-1 white--text font-weight-light d-line-block pt-3">
        {{ title }}
      </span>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="6"
      sm="6"
      :class="{ 'text-right': !$vuetify.breakpoint.xs }"
    >
      <v-col
        cols="12"
        class="d-flex flex-row-reverse mb-n5 mt-n2"
        v-if="displaySelectLanguage"
      >
        <v-select
          v-model="selectLanguage"
          :items="languages"
          @change="switchLanguage"
          solo
          style="max-width: 75px"
        >
          <template v-slot:selection="{ item }">
            <img style="width: 22px" :src="item.image" />
          </template>
          <template v-slot:item="{ item }">
            <img style="width: 22px" :src="item.image" />
          </template>
        </v-select>
      </v-col>
      <slot name="SideMenu" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'TopHeader',
  data: () => ({
    languages: [
      {
        name: 'fr',
        image: require('@/assets/fr-48.png'),
      },
      {
        name: 'en',
        image: require('@/assets/uk-48.png'),
      },
    ],
    selectLanguage: {},
  }),
  props: {
    title: {
      type: String,
      default: '',
    },
    displaySelectLanguage: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.selectLanguage = {
      name: this.$store.state.language.name,
      image: this.$store.state.language.image,
    }
  },
  methods: {
    switchLanguage(v) {
      if (this.$i18n.locale === 'fr') {
        this.$i18n.locale = 'en'
      } else {
        this.$i18n.locale = 'fr'
      }

      this.$store.commit('setLanguage', v)
    },
  },
}
</script>

<style lang="scss" scoped></style>
