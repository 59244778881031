<template>
  <v-row>
    <v-col cols="12">
      <top-header :title="title">
        <template v-slot:SideMenu>
          <v-btn
            :small="dense"
            class="mb-2 mr-2"
            color="white primary--text"
            @click="openInventoryModal"
            :disabled="is.loading"
            v-if="datasetSlug === 'stock_tracking'"
          >
            <v-icon left> mdi-download </v-icon>{{ $t('inventoryModal') }}
          </v-btn>
          <v-dialog v-model="inventoryDialog" persistent max-width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">{{ $t('selectOptions') }}</span>
              </v-card-title>
              <v-card-text>
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dates"
                      label="Période"
                      prepend-icon="mdi-calendar"
                      :error-messages="$t(errors.dates)"
                      readonly
                      required
                      v-bind="attrs"
                      v-on="on"
                      :disabled="isInventoryLoading"
                      @blur="$v.dates.$touch()"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    range
                    v-model="dates"
                    scrollable
                    :disabled="isInventoryLoading"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">OK</v-btn>
                  </v-date-picker>
                </v-menu>
                <v-select
                  :items="magasins"
                  label="Magasin"
                  v-model="selectedStorage"
                  required
                  :disabled="isInventoryLoading"
                  :error-messages="$t(errors.selectedStorage)"
                  @blur="$v.selectedStorage.$touch()"
                ></v-select>
                <v-select
                  :items="fournisseurs"
                  label="Fournisseur"
                  v-model="selectedProvider"
                  required
                  :disabled="isInventoryLoading"
                  :error-messages="$t(errors.selectedProvider)"
                  @blur="$v.selectedProvider.$touch()"
                ></v-select>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="inventoryDialog = false"
                  :disabled="isInventoryLoading"
                  >{{ $t('close') }}</v-btn
                >
                <v-btn
                  color="blue darken-1"
                  text
                  @click="downloadStockState"
                  :disabled="isInventoryLoading"
                  :loading="isInventoryLoading"
                  >{{ $t('download') }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn
            :small="dense"
            class="mb-2 mr-2"
            color="white primary--text"
            @click="handleToggleFilters(true)"
            v-if="externalFiltering"
            :disabled="is.loading"
          >
            <v-icon left> mdi-filter </v-icon>{{ $t('filters') }}
          </v-btn>
          <v-btn
            :small="dense"
            class="mb-2 mr-2"
            color="white primary--text"
            @click="download"
            v-if="downloadable"
            :disabled="is.loading"
          >
            <v-icon left> mdi-download </v-icon>{{ $t('download') }}
          </v-btn>
          <v-btn
            :small="dense"
            class="mb-2 mr-2"
            color="white red--text"
            @click="deleteFilteredDataset"
            :disabled="is.loading"
            v-if="
              (!$store.getters.isPreviewing && $store.getters.isUserAdmin) ||
              isDeleteAllowed
            "
          >
            <v-icon left> mdi-delete </v-icon>{{ $t('delete') }}
          </v-btn>
          <template v-if="!readOnly">
            <template v-if="!!$slots['create']">
              <slot name="create" />
            </template>
            <v-dialog
              v-else-if="!readOnly && create"
              v-model="dialog"
              max-width="500px"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  color="white primary--text"
                  :small="dense"
                  class="ml-2 mb-2"
                  :disabled="isActionsDisabled"
                  v-on="on"
                >
                  <v-icon left> mdi-plus </v-icon>
                  {{ $t('add') }}
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                        v-for="(f, i) in table.colsMeta.filter(
                          (o) => !(o.primary_key && o.generated)
                        )"
                        :key="i"
                        cols="12"
                        sm="6"
                        md="4"
                      >
                        <v-text-field
                          v-if="['float', 'integer'].includes(f.type)"
                          v-model="editedItem[f.name]"
                          :label="f.name"
                          type="number"
                        />
                        <v-switch
                          v-else-if="f.type === 'boolean'"
                          v-model="editedItem[f.name]"
                          :label="f.name"
                        />
                        <v-text-field
                          v-else
                          v-model="editedItem[f.name]"
                          :label="f.name"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="close">
                    {{ $t('cancel') }}
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save">
                    {{ $t('save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </template>
      </top-header>
    </v-col>
    <v-col cols="12">
      <v-data-table
        :loading="is.loading"
        :headers="table.cols"
        :items="table.rows"
        :fixed-header="true"
        :height="fillHeight ? height : 'auto'"
        hide-default-header
        :options.sync="pagination"
        :footer-props="{
          'items-per-page-options': pagination.itemsPerPageItems,
        }"
        :server-items-length="pagination.totalItems"
        @update:options="handlePaginationSortFilter"
      >
        <template v-slot:header="{}">
          <thead>
            <tr>
              <th v-for="(col, i) in table.cols" :key="i">
                <dataset-crud-header-cell
                  v-model="table.cols[i]"
                  :externalFiltering="externalFiltering"
                  @apply="handlePaginationSortFilter"
                />
              </th>
            </tr>
          </thead>
        </template>

        <template v-if="!readOnly" v-slot:item.action="{ item }">
          <v-btn
            rounded
            fab
            color="primary"
            class="mr-3"
            text
            x-small
            @click="editItem(item)"
          >
            <v-icon small> mdi-pencil </v-icon>
          </v-btn>
          <v-btn
            rounded
            fab
            dark
            color="red"
            text
            x-small
            @click="deleteItem(item)"
          >
            <v-icon small> mdi-delete </v-icon>
          </v-btn>
        </template>
        <template v-slot:no-data>
          <span v-if="!is.datasetDefined" class="text-center">
            <v-icon left size="30" color="grey lighten-2"
              >mdi-cloud-alert</v-icon
            >
            {{ $t('specifyDatasetName') }}
          </span>
          <span v-else-if="is.datasetNotFound" class="text-center">
            <v-icon left size="30" color="grey lighten-2"
              >mdi-cloud-question</v-icon
            >
            Le dataset "{{ datasetName }}" est introuvable
          </span>
          <span v-else> {{ $t('noDataAvailable') }} </span>
        </template>
      </v-data-table>
    </v-col>
    <v-dialog v-model="deleteDialog" width="500"
      ><v-card>
        <v-card-title class="text-h5 red--text red lighten-5">{{
          $t('confirmDeletion')
        }}</v-card-title>
        <v-spacer></v-spacer>
        <v-card-text class="mt-5">
          {{ $t('areYouSureYouWantToDeleteTheseLines') }}<br />
          <span class="font-weight-bold">
            {{ pagination.totalItems }} {{ $t('linesToDelete') }}<br />
          </span>
          <span v-if="externalFilters.length > 0" class="mt-2 mb-2">
            {{ $t('filtersApplied') }}:
            <ul>
              <li v-for="(item, index) in externalFilters" :key="index">
                <span class="font-weight-bold">{{ item.label }}:</span>
                {{ item.displayValue }}
              </li>
            </ul>
          </span>
          <span v-else class="mt-2 mb-2">
            {{ $t('noFilterApplied') }}<br />
          </span>
          <span class="font-weight-bold">
            {{ $t('irreversibleAction') }}.
          </span>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="handleDeleteFilteredDataset"
            :disabled="deleteButtonDisabled || is.loading"
          >
            <v-progress-circular
              v-if="deleteButtonDisabled"
              indeterminate
              width="2"
              size="24"
              >{{ displayedDeleteTimer }}</v-progress-circular
            >
            <span v-else>
              {{ $t('delete') }}
            </span>
          </v-btn>
          <v-btn color="primary" text @click="deleteDialog = false">
            {{ $t('cancel') }}</v-btn
          >
        </v-card-actions>
      </v-card></v-dialog
    >
  </v-row>
</template>

<script>
import TopHeader from '@/components/base/TopHeader'
import DatasetCrudHeaderCell from '@/components/hmd/Dataset/DatasetCrudHeaderCell'
import DatasetService from '@/services/DatasetService'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
export default {
  components: {
    DatasetCrudHeaderCell,
    TopHeader,
  },
  mixins: [validationMixin],
  props: {
    pickable: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: null,
    },
    dataset: {
      type: String,
      default: null,
    },
    externalFilters: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Object,
      default: () => {},
    },
    create: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    exportable: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    fillHeight: {
      type: Boolean,
      default: false,
    },
    downloadable: {
      type: Boolean,
      default: false,
    },
    externalFiltering: {
      type: Boolean,
      default: false,
    },
  },
  validations: {
    dates: { required },
    selectedStorage: { required },
    selectedProvider: { required },
  },
  data: () => ({
    menu: false,
    datasetSlug: '',
    dates: [],
    magasins: [],
    fournisseurs: [],
    inventoryDialog: false,
    isInventoryLoading: false,
    selectedStorage: null,
    selectedProvider: null,
    pagination: {
      page: 1,
      itemsPerPage: 10,
      totalItems: 0,
      itemsPerPageItems: [10, 20, 30],
    },
    height: 500,
    searchInput: '',
    dialog: false,
    table: {
      rows: [],
      cols: [],
      colsMeta: [],
    },
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    is: {
      loading: false,
      datasetNotFound: false,
      datasetDefined: false,
    },
    selectedDataset: null,
    deleteDialog: false,
    deleteButtonDisabled: false,
    deleteButtonTimer: 100,
    deleteTimerInterval: null,
  }),
  computed: {
    datasetName() {
      return (
        this.dataset ||
        (this.selectedDataset ? this.selectedDataset.name : null)
      )
    },
    displayedDeleteTimer() {
      return this.deleteButtonTimer / 20
    },
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    isActionsDisabled() {
      return (
        this.is.loading || this.is.datasetNotFound || !this.is.datasetDefined
      )
    },
    isDeleteAllowed() {
      return ['movement_balancing_output', 'stock_tracking'].includes(
        this.datasetSlug
      )
    },
    errors() {
      const errors = {
        dates: [],
        selectedStorage: [],
        selectedProvider: [],
      }

      if (this.$v.dates.$dirty) {
        !this.$v.dates.required && errors.dates.push('fieldRequired')
      }

      if (this.$v.selectedStorage.$dirty) {
        !this.$v.selectedStorage.required &&
          errors.selectedStorage.push('fieldRequired')
      }

      if (this.$v.selectedProvider.$dirty) {
        !this.$v.selectedProvider.required &&
          errors.selectedProvider.push('fieldRequired')
      }
      return errors
    },
  },

  watch: {
    pickable(vn, vo) {
      const $ = this
      if (vn.length > 0 && $.selectedDataset == null) {
        $.selectedDataset = vn[0]
        this.initialize()
      }
    },
    deleteDialog(val) {
      if (val) {
        this.launchDeleteButtonTimer()
      }
    },
    dialog(val) {
      val || this.close()
    },
    externalFilters(newValue, oldValue) {
      if (newValue != oldValue) {
        this.handlePaginationSortFilter()
      }
    },
  },
  mounted() {
    const $ = this
    if (this.dataset) $.initialize()
    $.height = window.innerHeight - 300
    window.addEventListener('resize', (e) => {
      $.height = window.innerHeight - 300
    })

    // get dataset slug
    DatasetService.get_informations(this, this.dataset).then((r) => {
      if (r.data) {
        this.datasetSlug = r.data.name

        // get inventory options for fournisseur and magasin
        // it's necessary to call the API twice
        DatasetService.getInventoryOptions(
          $,
          this.$store.getters.merchantId,
          this.datasetSlug,
          'fournisseur'
        ).then((r) => {
          this.fournisseurs = r.data.rows.map((row) => row.values)
        })

        DatasetService.getInventoryOptions(
          $,
          this.$store.getters.merchantId,
          this.datasetSlug,
          'magasin'
        ).then((r) => {
          this.magasins = r.data.rows.map((row) => row.values)
        })
      }
    })
  },

  methods: {
    openModal() {
      this.dialog = true
    },
    resetInventoryForm() {
      this.$v.$reset()
      this.dates = null
      this.selectedProvider = null
      this.selectedStorage = null
    },
    openInventoryModal() {
      this.inventoryDialog = true
      this.resetInventoryForm()
    },

    evaluateParameters() {
      return {
        limit: this.pagination.itemsPerPage,
        offset: (this.pagination.page - 1) * this.pagination.itemsPerPage,
        sort: this.lo.map(
          this.lo.filter(this.table.cols, 'sortOrder'),
          (col) => {
            return { field_name: col.name, order: col.sortOrder }
          }
        ),
        filter: this.externalFilters
          ? this.externalFilters
          : this.lo.map(
              this.lo.filter(this.table.cols, 'filter.operator'),
              (col) => {
                return {
                  field_name: col.name,
                  is_filtered: true,
                  query: {
                    operator: col.filter.operator,
                    values: [col.filter.value != null ? col.filter.value : ''],
                  },
                }
              }
            ),
      }
    },
    getMeta(dataset) {
      const $ = this
      return DatasetService.get_informations($, dataset)
        .then((r) => {
          $.table.colsMeta = r.data.fields
          $.table.cols = r.data.fields
            .filter((f) => !(f.primary_key && f.generated))
            .map((f) => {
              $.editedItem[f.name] = ['float', 'integer'].includes(f.type)
                ? 0
                : f.type === 'boolean'
                ? false
                : ''
              $.defaultItem[f.name] = ['float', 'integer'].includes(f.type)
                ? 0
                : f.type === 'boolean'
                ? false
                : ''
              f.text = $.lo.capitalize(f.name)
              f.value = f.name
              f.align = 'start'
              f.sortOrder = ''
              f.filter = {
                operator: '',
                value: '',
              }
              return f
            })
          if (!this.readOnly) {
            this.table.cols.push({
              text: 'Actions',
              value: 'action',
              sortable: false,
            })
          }
        })
        .catch((err) => {
          if (err.response) {
            if (
              err.response.statusText === 'Not Found' &&
              err.response.status === 404
            ) {
              $.is.datasetNotFound = true
            }
          }
        })
    },
    handlePaginationSortFilter() {
      if (this.datasetName) {
        this.get(this.datasetName, this.evaluateParameters())
      }
    },
    get(dataset, params) {
      params = this.evaluateParameters()
      this.is.loading = true
      return DatasetService.get(this, dataset, params, this.headers).then(
        (r) => {
          this.pagination.totalItems = r.data.count
          this.table.rows = r.data.results
          this.is.loading = false
        }
      )
    },
    initialize() {
      if (this.datasetName) {
        this.is.loading = true
        this.is.datasetDefined = true
        Promise.all([
          this.getMeta(this.datasetName),
          this.get(this.datasetName),
        ])
          .then((r) => {})
          .finally(() => {
            this.is.loading = false
          })
      }
    },

    editItem(item) {
      this.editedIndex = this.table.rows.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      const index = this.table.rows.indexOf(item)
      if (confirm('Êtes-vous sûr de vouloir supprimer cet élement ?')) {
        DatasetService.deleteRow(
          this,
          this.datasetName,
          this.table.rows[index],
          this.table.colsMeta
        ).then(() => {
          this.table.rows.splice(index, 1)
        })
      }
    },

    close() {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },

    save() {
      if (this.editedIndex > -1) {
        DatasetService.update(
          this,
          this.datasetName,
          this.editedItem,
          this.table.colsMeta
        ).then((r) => {
          this.$emit('updated')
          this.get(this.datasetName, this.evaluateParameters())
        })
      } else {
        DatasetService.create(this, this.datasetName, this.editedItem).then(
          (r) => {
            this.$emit('updated')
            this.get(this.datasetName, this.evaluateParameters())
          }
        )
      }
      this.close()
    },
    handleToggleFilters(v) {
      this.$store.commit('setFiltersDrawerExpanded', v)
    },
    download() {
      let params = this.evaluateParameters()
      DatasetService.download(this, this.dataset, null, params)
    },
    downloadStockState() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.isInventoryLoading = true

        DatasetService.downloadStockState(
          this,
          this.dates,
          this.selectedStorage,
          this.selectedProvider
        )
          .then(() => {
            this.inventoryDialog = false
          })
          .catch(() => {})
          .finally(() => {
            this.isInventoryLoading = false
          })
      }
    },
    deleteFilteredDataset() {
      this.deleteDialog = true
    },
    handleDeleteFilteredDataset() {
      this.deleteDialog = false
      this.is.loading = true
      DatasetService.deleteFilteredDataset(
        this,
        this.dataset,
        this.evaluateParameters(),
        this.headers
      ).finally(() => {
        this.externalFilters = []
        this.get(this.dataset, this.evaluateParameters())
        this.is.loading = false
      })
    },
    launchDeleteButtonTimer() {
      let t = this
      clearInterval(t.deleteTimerInterval)
      t.deleteButtonTimer = 100
      t.deleteButtonDisabled = true
      t.deleteTimerInterval = setInterval(() => {
        t.deleteButtonTimer -= 20
        if (t.deleteButtonTimer <= 0) {
          t.deleteButtonTimer = 100
          t.deleteButtonDisabled = false
          clearInterval(t.deleteTimerInterval)
          return
        }
      }, 1000)
    },
  },
}
</script>

<style scoped></style>
