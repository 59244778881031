<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card class="pb-5 px-5 pt-3">
          <v-tabs v-model="tab">
            <v-tabs-slider />

            <v-tab v-for="i in tabs" :key="i" class="text-left" :href="`#${i}`">
              {{ i }}
            </v-tab>

            <v-tab-item value="Paramètres">
              <manage-merchant-details :merchant="merchant" />
            </v-tab-item>
            <v-tab-item value="Organismes">
              <manage-merchant-organisms :merchant="merchant" />
            </v-tab-item>
            <v-tab-item value="Utilisateurs">
              <div class="text-h5 ml-3 mt-4">
                Gestion des utilisateurs
              </div>
              <users-crud :merchant="merchant" />
            </v-tab-item>
            <v-tab-item value="Fichiers consolidés">
              <div class="text-h5 ml-3 mt-4">
                Fichiers consolidés
              </div>
              <manage-merchant-datasets truncate-btn :merchant="merchant" />
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ManageMerchantDatasets from '@/components/bnc/ManageMerchantDatasets'
import ManageMerchantOrganisms from '@/components/bnc/ManageMerchantOrganisms'
import ManageMerchantDetails from '@/components/bnc/ManageMerchantDetails'
import UsersCrud from '@/components/hmd/Users/UsersCrud'
export default {
  name: 'Marchand',
  components: {
    ManageMerchantDatasets,
    ManageMerchantOrganisms,
    ManageMerchantDetails,
    UsersCrud,
  },
  data() {
    return {
      merchant: this.$route.params.merchant,
      tab: '',
      tabs: ['Paramètres', 'Organismes', 'Utilisateurs', 'Fichiers consolidés'],
    }
  },
}
</script>

<style scoped></style>
