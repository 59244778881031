<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="pb-0">
        <v-btn
          absolute
          fab
          left
          :elevation="5"
          class="d-print-none"
          @click.stop="onClick"
          style="top: 30px; left: -20px; background-color: #dae1f1"
          icon
          v-if="$vuetify.breakpoint.mdAndDown"
        >
          <v-icon color="blue accent-3"> mdi-menu </v-icon>
        </v-btn>
        <v-card color="transparent" dark>
          <v-card-title>
            <h2 class="text-capitalize">
              Bienvenue {{ $store.state.user.username }}
            </h2>
          </v-card-title>
          <v-card-text>
            <p class="subtitle-1">
              Gérez les organismes, les marchands. Suivez l'état d'avancement de
              tous vos flux bancaires et les alertes en temps réel.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="6" lg="3" class="pt-0">
        <visualization
          type="counter"
          card
          width="100%"
          :data="{
            columns: [{ name: 'count' }],
            rows: [{ count: stats.organisms_count }],
          }"
          :options="{
            rowIndex: 0,
            column: 'count',
            subTitle: {
              size: 18,
              value: 'Organismes',
            },
            icon: {
              bgColor: '#09d0a9',
              value: 'mdi-domain',
              size: 36,
            },
          }"
        />
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="3" class="pt-0">
        <visualization
          type="counter"
          card
          width="100%"
          :data="{
            columns: [{ name: 'count' }],
            rows: [{ count: stats.merchants_count }],
          }"
          :options="{
            rowIndex: 0,
            column: 'count',
            subTitle: {
              size: 18,
              value: 'Marchands',
            },
            icon: {
              bgColor: '#DB5461',
              value: 'mdi-shield-account-outline',
              size: 36,
            },
          }"
        />
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="3" class="pt-0">
        <visualization
          type="counter"
          card
          width="100%"
          :data="{
            columns: [{ name: 'count' }],
            rows: [{ count: stats.users_count }],
          }"
          :options="{
            rowIndex: 0,
            column: 'count',
            subTitle: {
              size: 18,
              value: 'Utilisateurs',
            },
            icon: {
              bgColor: '#E9CE2C',
              value: 'mdi-account-group-outline',
              size: 36,
            },
          }"
        />
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="3" class="pt-0">
        <visualization
          type="counter"
          card
          width="100%"
          :data="{
            columns: [{ name: 'count' }],
            rows: [{ count: stats.files_count }],
          }"
          :options="{
            rowIndex: 0,
            column: 'count',
            subTitle: {
              size: 18,
              value: 'Fichiers consolidés',
            },
            icon: {
              bgColor: '#86BBD8',
              value: 'mdi-content-copy',
              size: 36,
            },
          }"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" class="pt-0">
            <v-card>
              <v-card-title>Alertes</v-card-title>
              <v-card-text>
                <v-alert
                  text
                  color="warning"
                  border="left"
                  elevation="0"
                  icon="mdi-shield-airplane"
                >
                  Impayés en progression
                </v-alert>
                <v-alert
                  text
                  color="warning"
                  border="left"
                  elevation="0"
                  bordered
                  icon="mdi-shield-airplane"
                >
                  Attention aucun fichier traité depuis le 14/04/2020
                </v-alert>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card>
              <v-card-title
                ><v-row>
                  <v-col class="py-0 px-4" cols="8">
                    Conversion de devises
                  </v-col>
                  <v-col class="py-0 px-4 text-right" cols="4">
                    <v-btn :to="{ name: 'Devises' }" text color="primary">
                      <v-icon left small>mdi-cog</v-icon>
                      Gérer
                    </v-btn>
                  </v-col>
                </v-row></v-card-title
              >
              <v-card-text>
                <CurrencyConversionTool></CurrencyConversionTool>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="6">
        <v-card>
          <v-card-title class="pb-0">
            <v-row>
              <v-col class="py-0 px-4" cols="8"> Fichiers consolidés </v-col>
              <v-col class="py-0 px-4" cols="4">
                <merchant-input
                  select-first
                  small
                  v-model="selectedMerchant"
                ></merchant-input>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text class="pt-0 px-1">
            <manage-merchant-datasets
              download
              :merchant="selectedMerchant"
            ></manage-merchant-datasets>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import CurrencyConversionTool from '@/components/bnc/CurrencyConversionTool.vue'
import { BncService } from '@/services'
import MerchantInput from '@/components/inputs/MerchantInput'
import ManageMerchantDatasets from '@/components/bnc/ManageMerchantDatasets'
import { mapMutations } from 'vuex'
export default {
  name: 'Index',
  components: { MerchantInput, ManageMerchantDatasets, CurrencyConversionTool },
  data: () => ({
    selectedMerchant: '',
    stats: {
      files_count: 0,
      merchants_count: 0,
      organisms_count: 0,
      users_count: 0,
    },
    add_list_items: ['Marchand', ' Organisme', 'Utilisateur'],
  }),
  mounted() {
    BncService.stats(this).then((r) => {
      this.stats = r
    })
  },
  methods: {
    ...mapMutations('app', ['setDrawer', 'toggleDrawer']),
    onClick() {
      this.setDrawer(!this.$store.state.app.drawer)
    },
  },
}
</script>

<style scoped></style>
