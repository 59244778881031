/* eslint-disable space-before-function-paren */
import { DefaultErrorHandler, HTTP } from '@/utils/http-common'

export default {
  list($, company) {
    return new Promise((resolve, reject) => {
      HTTP.get('/projects/manage/bnc/dataset/')
        .then((r) => {
          resolve(r)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  get_informations($, name) {
    return new Promise((resolve, reject) => {
      HTTP.get('/datastore/dataset/manage/' + name + '/')
        .then((r) => {
          resolve(r)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  get($, name, params, headers) {
    const p =
      '?' +
      $.lo.join(
        $.lo.map(params, (o, i) => {
          return i + '=' + (typeof o === 'object' ? JSON.stringify(o) : o)
        }),
        '&'
      )
    return new Promise((resolve, reject) => {
      HTTP.get(
        '/v1/dataset/' + name + '/' + (p.replace('?', '') ? p : ''),
        headers
      )
        .then((r) => {
          resolve(r)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteFilteredDataset($, name, params, headers) {
    const p =
      '?' +
      $.lo.join(
        $.lo.map(params, (o, i) => {
          return i + '=' + (typeof o === 'object' ? JSON.stringify(o) : o)
        }),
        '&'
      )
    return new Promise((resolve, reject) => {
      HTTP.delete(
        '/v1/dataset/' + name + '/' + (p.replace('?', '') ? p : ''),
        headers
      )
        .then((r) => {
          $.$store.commit('success', 'Lignes supprimées')
          resolve(r)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  update($, datasetName, rowToEdit, fields) {
    return new Promise((resolve, reject) => {
      let keys = {}
      keys = $.lo.join(
        $.lo.map($.lo.filter(fields, { primary_key: true }), (c, i) => {
          return `${c.name}=${rowToEdit[c.name]}`
        }),
        ','
      )
      HTTP.post(
        '/v1/dataset/' + datasetName + '/by_id/' + keys + '/',
        rowToEdit
      )
        .then((response) => {
          $.$store.commit('success', 'Information mise a jour')
          resolve(response)
        })
        .catch((error) => {
          $.$store.commit('error', {
            msg: 'Echec de mise a jour',
            color: 'error',
          })
          reject(error)
        })
    })
  },
  getInventoryOptions($, merchantId, datasetName, columnName) {
    return new Promise((resolve, reject) => {
      const query = `SELECT DISTINCT ${columnName} as values FROM ${datasetName} WHERE ${columnName} <> '';`

      HTTP.post('query/results/', {
        parameters: null,
        project_id: merchantId,
        query: query,
      })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          $.$store.commit('error', {
            msg: 'Echec de mise a jour',
            color: 'error',
          })
          reject(error)
        })
    })
  },
  downloadStockState($, dates, magasin, fournisseur) {
    return new Promise((resolve, reject) => {
      let startDate = dates[0]
      let endDate = dates[1]

      if (startDate && !endDate) {
        endDate = startDate
      }

      const params = {
        start_date: startDate,
        end_date: endDate,
        magasin: magasin,
        fournisseur: fournisseur,
      }

      HTTP.post(
        'bnc/inventory/',
        {},
        {
          params: params,
          responseType: 'blob', // important
        }
      )
        .then((r) => {
          const url = window.URL.createObjectURL(new Blob([r.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            'Stock_state_' + startDate + '_' + endDate + '.xlsx'
          )
          document.body.appendChild(link)
          link.click()
          link.parentNode.removeChild(link)
          resolve()
        })
        .catch((error) => {
          $.$store.commit('error', {
            msg: 'No data with selected parameters',
            color: 'error',
          })
          console.log(error)
          reject(error)
        })
    })
  },
  create($, datasetName, row) {
    return new Promise((resolve, reject) => {
      HTTP.post('/v1/dataset/' + datasetName + '/', row)
        .then((response) => {
          $.$store.commit('success', 'Information créé')
          resolve(response)
        })
        .catch((error) => {
          let message = ''
          for (const err in error.response.data) {
            message += '- ' + err + ' : ' + error.response.data[err] + '\n'
          }
          $.$store.commit('error', { msg: message, color: 'error' })
          reject(error)
        })
    })
  },
  deleteRow($, datasetName, rowToDelete, colums) {
    let keys = {}
    keys = $.lo.join(
      $.lo.map($.lo.filter(colums, { primary_key: true }), (c, i) => {
        return `${c.name}=${rowToDelete[c.name]}`
      }),
      ','
    )

    return new Promise((resolve, reject) => {
      HTTP.delete('/v1/dataset/' + datasetName + '/by_id/' + keys + '/')
        .then((response) => {
          $.$store.commit('success', 'Information supprimé')
          resolve(response)
        })
        .catch((error) => {
          let message = ''
          for (const err in error.response.data) {
            message += '- ' + err + ' : ' + error.response.data[err] + '\n'
          }
          $.$store.commit('error', { msg: message, color: 'error' })
          reject(error)
        })
    })
  },
  truncate($, id) {
    return new Promise((resolve, reject) => {
      if (
        confirm(
          'Etes vous sure de vouloir tronquer ce dataset ? (Cette opération est irréversible)'
        )
      ) {
        HTTP.get('/datastore/dataset/manage/' + id + '/truncate')
          .then((r) => {
            $.$store.commit('success', 'Dataset tronqué')
            resolve(r)
          })
          .catch((e) => DefaultErrorHandler($, e, reject))
      } else {
        reject(new Error('Non confirmé'))
      }
    })
  },
  download($, id, name = '', params = null) {
    const p =
      '?' +
      $.lo.join(
        $.lo.map(params, (o, i) => {
          return i + '=' + (typeof o === 'object' ? JSON.stringify(o) : o)
        }),
        '&'
      )
    return new Promise((resolve, reject) => {
      HTTP.get(
        '/v1/dataset/' + id + '/export/' + (p.replace('?', '') ? p : ''),
        { responseType: 'blob' }
      )
        .then((r) => {
          const url = window.URL.createObjectURL(new Blob([r.data]))
          const link = document.createElement('a')
          link.href = url
          link.download =
            (name || id).toUpperCase() +
            '_' +
            new Date().toISOString().slice(0, 19) +
            '.xlsx'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          resolve(link)
        })
        .catch((e) => DefaultErrorHandler($, e, reject))
    })
  },
}
