import '@babel/polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins'
import vuetify from './plugins/vuetify'
import { sync } from 'vuex-router-sync'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import Vuelidate from 'vuelidate'
import VueI18n from 'vue-i18n'

import * as HmdComponents from 'hmd-components'

import english from './translations/en.json'
import french from './translations/fr.json'

Vue.use(VueI18n)
Vue.use(HmdComponents, {
  HmdApiUrl: process.env.VUE_APP_ROOT_API,
})

Vue.use(Vuelidate)
Vue.use(VueLodash, { name: 'lo', lodash: lodash })

store.state.processFunction = process.env.VUE_APP_ADMIN_PROCESS_FUNCTION_ID
store.state.devises = process.env.VUE_APP_ADMIN_DATASET_DEVISES_ID
store.state.bin_mapping = process.env.VUE_APP_ADMIN_DATASET_BIN_MAPPING_ID

sync(store, router)

Vue.config.productionTip = false

// Create VueI18n instance with options
export const i18n = new VueI18n({
  locale: store.state.language.name, // set the default locale
  messages: {
    fr: french,
    en: english,
  },
})

export default new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
