var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"dash",style:({
    'padding-right': _vm.$store.state.filtersDrawerExpanded
      ? !_vm.$vuetify.breakpoint.smAndDown
        ? '320px'
        : '20px'
      : '12px',
  }),attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"d-print-none text-capitalize",attrs:{"dark":"","color":"blue-grey lighten-2"},on:{"click":function($event){return _vm.handleToggleFilters(true)}}},[_c('v-icon',[_vm._v(" mdi-filter-outline")]),_vm._v(" "+_vm._s(_vm.$t('filters'))+" ")],1),_c('v-btn',{staticClass:"d-print-none text-capitalize ml-2",attrs:{"color":"primary"},on:{"click":_vm.print}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-printer-outline")]),_vm._v(" "+_vm._s(_vm.$t('print'))+" ")],1)],1)],1),_c('core-app-bar'),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('visualization',{attrs:{"card":"","type":"bar","query":"taux_impayes_preleves","translations":_vm.translations,"project":_vm.$store.getters.merchantId,"title":_vm.$t('rateOfDelinquenciesPerMonth'),"options":{
          xAxes: ['Mois'],
          yAxes: [_vm.$t('rate')],
          dataLabels: {
            formatter: _vm.percentFormatter,
          },
          colors: ['#32325D'],
        },"external-filters-values":_vm.objectToArray(['reference_date', 'event_motive_label']),"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1)],1),_c('v-row',[_c('v-col',[_c('TCDImpayes',{staticClass:"unpaid",attrs:{"filters":_vm.objectToArray(),"data":_vm.impayesTCD}})],1)],1),_c('FiltersDrawer',{attrs:{"filters":_vm.filters()},on:{"update":_vm.handleFilterValueUpdate}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }