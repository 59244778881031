<template>
  <v-select
    class="pt-0"
    :items="merchants"
    v-model="selectedValue"
    item-text="name"
    item-value="id"
    :multiple="multiple"
    :outlined="outlined"
    :error-messages="errorMessages"
    :label="$t('merchant')"
    @input="handleInput"
    @blur="$emit('blur')"
  ></v-select>
</template>

<script>
import { MerchantService } from '@/services'
export default {
  name: 'MerchantInput',
  props: {
    selectFirst: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: null,
    },
    merchant: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedValue: '',
      merchants: [],
    }
  },
  methods: {
    handleInput() {
      this.$emit('input', this.selectedValue)
    },
    getmerchants() {
      MerchantService.list(this, true).then((r) => {
        this.merchants = r.results.map((v) => ({ name: v.name, id: v.id }))
        if (this.selectFirst) {
          this.selectedValue = this.merchants[0].id
          this.handleInput()
        }
      })
    },
  },
  watch: {
    value(newValue, oldValue) {
      this.selectedValue = newValue
    },
  },
  mounted() {
    this.getmerchants()
    this.selectedValue = this.merchant
  },
}
</script>

<style lang="scss" scoped></style>
