<template>
  <div class="text-center py-15">
    <v-icon size="70" class="mb-4" color="grey lighten-2">
      mdi-folder-hidden
    </v-icon>
    <div class="text-h5 font-weight-light" color="grey">
      {{ $t('noResultFound') }}
    </div>
    <div class="mt-2">
      <slot />
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped></style>
