<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card class="pb-5 px-5 pt-3">
          <v-tabs v-model="tab">
            <v-tabs-slider />

            <v-tab v-for="i in tabs" :key="i" class="text-left" :href="`#${i}`">
              {{ i }}
            </v-tab>

            <v-tab-item value="Paramètres">
              <manage-organism-details :organism="organism" />
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ManageOrganismDetails from "@/components/bnc/ManageOrganismDetails";
import TopHeader from "@/components/base/TopHeader";
export default {
  name: "Organisme",
  components: {
    ManageOrganismDetails
  },
  data() {
    return {
      organism: this.$route.params.organism,
      tab: "",
      tabs: ["Paramètres"]
    };
  }
};
</script>

<style scoped></style>
