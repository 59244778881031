<template>
  <v-card :style="'width:auto;' + (fillParent ? 'height:100%;' : '') + styles">
    <vue-element-loading :active="loading" spinner="spinner" color="#3ba99c" />
    <v-row no-gutters style="width: 100%; padding: 10px; height: 100%">
      <!-- <div style="width:100%; padding: 10px; height:100%;"> -->
      <div v-if="visualization.type != 'kpi'" class="primary--text graph-title">
        {{ getTranslation(visualization.label) }}
      </div>
      <v-dialog v-model="dialog" width="400" v-if="removable">
        <template v-slot:activator="{ on }">
          <v-fab-transition v-if="removable">
            <v-btn
              class="ico-delete-widget"
              v-on="on"
              v-show="!hidden"
              color="white"
              fab
              absolute
            >
              <v-icon color="error">mdi-delete-outline</v-icon>
            </v-btn>
          </v-fab-transition>
        </template>
        <v-card>
          <v-card-title class="text-subtitle-1 secondary">
            {{ getTranslation(visualization.label) }}
          </v-card-title>

          <v-divider></v-divider>
          <v-card-text class="body-1 mt-5">
            {{ $t('deleteWidget') }}
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="removeWidget(visualization)">
              OK
            </v-btn>
            <v-btn color="grey" text @click="dialog = false">
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <template v-if="!_.isEmpty(execErrors)">
        <v-alert
          v-for="(err_body, err_label, err_index) in execErrors"
          :key="err_index"
          :value="true"
          color="accent"
          type="error"
        >
          Error running query : {{ err_body }}
        </v-alert>
      </template>

      <visualization
        :project="$store.getters.merchantId"
        :type="visualization.type"
        :query="visualization.query ? visualization.query.name : null"
        :kpi="visualization.kpi ? visualization.kpi.pk : null"
        :options="
          visualization.query
            ? listOptions
            : {
                title: {
                  value: titleKpi,
                  color: '#2d62ed',
                },
              }
        "
        :valueFormatter="visualization.value_formatter"
        :tooltipFormatter="visualization.tooltip_formatter"
        :yAxisFormatter="visualization.y_axis_formatter"
        :forcedUser="$store.state.preview ? $store.state.preview.pk : null"
        :external-filters-values="externalFilters"
        :filtersDontApplyToValue="['full_date_ref']"
        :filtersDontApplyToRef="['full_date']"
        :translations="translations"
        width="100%"
        height="auto"
      >
      </visualization>

      <!-- </div> -->
    </v-row>
  </v-card>
</template>

<script>
import VueElementLoading from 'vue-element-loading'
import {
  HOME_KPIS_DYNAMIC_MAPPING,
  HOME_VIZ_DYNAMIC_MAPPING,
  HOME_FILTERS,
} from '@/utils/constants.js'
import _ from 'lodash'

export default {
  name: 'DashboardElement',
  components: {
    'vue-element-loading': VueElementLoading,
  },
  props: {
    globalFilters: {
      required: false,
      type: Array,
    },
    fillParent: {
      required: false,
      type: Boolean,
      default: () => {
        return false
      },
    },
    depressed: {
      required: false,
      type: Boolean,
      default: () => {
        return false
      },
    },
    removable: {
      required: false,
      type: Boolean,
      default: () => {
        return false
      },
    },
    project: {
      required: false,
      type: String,
    },
    visualization: {
      required: true,
      type: Object,
    },
  },
  computed: {
    translations() {
      let dict = {}
      dict[this.$t('evolutionCollectedVolume', 'fr')] = this.$t(
        'evolutionCollectedVolume'
      )
      dict[this.$t('evolutionTotalRateCommission', 'fr')] = this.$t(
        'evolutionTotalRateCommission'
      )
      dict[this.$t('acceptanceRateNetWithoutDup', 'fr')] = this.$t(
        'acceptanceRateNetWithoutDup'
      )
      dict[this.$t('collectedVolume', 'fr')] = this.$t('collectedVolume')
      dict[this.$t('vccRate', 'fr')] = this.$t('vccRate')
      dict[this.$t('averageCommissionRate', 'fr')] = this.$t(
        'averageCommissionRate'
      )
      dict[this.$t('corporateRate', 'fr')] = this.$t('corporateRate')
      dict[this.$t('ratesUnpaid', 'fr')] = this.$t('ratesUnpaid')
      dict[this.$t('averageInterchangeRate', 'fr')] = this.$t(
        'averageInterchangeRate'
      )
      dict[this.$t('transactionsVolume', 'fr')] = this.$t('transactionsVolume')
      dict[this.$t('distributionByAuthentStatus', 'fr')] = this.$t(
        'distributionByAuthentStatus'
      )
      dict[this.$t('distributionByChallengeMode', 'fr')] = this.$t(
        'distributionByChallengeMode'
      )

      dict[this.$t('commissionRate', 'fr')] = this.$t('commissionRate')

      return dict
    },
    styles() {
      if (this.visualization.type == 'kpi') {
        return 'background-color:#dae1f1;'
      }

      return null
    },
    externalFilters() {
      let filters = this.globalFilters

      if (
        HOME_KPIS_DYNAMIC_MAPPING.acquereur.includes(
          this.$t(this.visualization.label, 'fr')
        ) ||
        HOME_VIZ_DYNAMIC_MAPPING.acquereur.includes(
          this.$t(this.visualization.label, 'fr')
        )
      ) {
        filters = this.globalFilters.filter((o) =>
          HOME_FILTERS.acquereur.includes(o.column)
        )
      }
      if (
        HOME_KPIS_DYNAMIC_MAPPING.psp.includes(
          this.$t(this.visualization.label, 'fr')
        ) ||
        HOME_VIZ_DYNAMIC_MAPPING.psp.includes(
          this.$t(this.visualization.label, 'fr')
        )
      ) {
        filters = this.globalFilters.filter((o) =>
          HOME_FILTERS.psp.includes(o.column)
        )
      }

      // Do not apply date filter for line charts
      if (this.visualization.type === 'line') {
        filters = filters.filter((o) => o.column !== 'full_date')
      }
      // Do not apply full_date_ref filter for visualizations that are not KPIs
      if (!this.visualization.kpi) {
        filters = filters.filter((o) => o.column !== 'full_date_ref')
      }

      return filters
    },

    listOptions() {
      let options = {
        ...this.visualization.options,
        yAxes: [this.getTranslation(this.visualization.options.yAxes[0])],
      }

      return options
    },
    titleKpi() {
      return this.getTranslation(this.visualization.label)
    },
  },

  data() {
    return {
      dialog: false,
      filters: [],
      execErrors: {},
      options: {},
      loading: false,
      ready: false,
      now: null,
    }
  },

  methods: {
    removeWidget(widget) {
      this.$emit('remove', widget)
    },

    getTranslation(val) {
      let translation = val

      if (this.translations[val]) {
        translation = this.translations[val]
      }

      return translation
    },
  },
}
</script>

<style scoped>
.element_actions {
  position: absolute;
  right: 5px;
  top: 4px;
  width: 220px;
}
.ico-delete-widget {
  top: 8px;
  right: 12px;
  z-index: 1000;
}

.hmd-kpi-container {
  border-radius: 8px !important;
  background-color: #dae1f1 !important;
}

.graph-title {
  font-size: 1.1rem;
  margin-top: 7px;
}
</style>
