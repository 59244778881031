<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card class="px-5 py-2">
          <manage-merchant-details />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ManageMerchantDetails from "@/components/bnc/ManageMerchantDetails";
import TopHeader from "@/components/base/TopHeader";
export default {
  components: {
    ManageMerchantDetails
  },
  data() {
    return {};
  }
};
</script>

<style scoped></style>
