<template>
  <div
    v-if="value.filter"
    style="position: relative"
    class="grid-filter-sort-dialog"
  >
    {{ lo.capitalize(lo.lowerCase(value.text || '')) }}
    <v-menu
      v-model="showDropdown"
      dense
      left
      :close-on-content-click="false"
      :max-width="250"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          class="gridHeaderCellMenuDropdown ml-1"
          text
          icon
          color="grey lighten-2"
          small
          v-on="on"
          @click="$emit('toggleDropdown')"
          v-if="!externalFiltering"
        >
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-card dense>
        <v-card-title>
          {{ lo.capitalize(lo.lowerCase(value.text || '')) }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0 pb-2"> Sort </v-col>
            <v-col cols="12" class="py-0">
              <v-select
                v-model.lazy="value.sortOrder"
                clearable
                dense
                :items="[
                  { text: 'Ascending', value: 'asc' },
                  { text: 'Descending', value: 'desc' },
                ]"
                :label="'Sort ' + value.text"
                solo
                @click:clear="
                  $nextTick(() => {
                    value.sortOrder = ''
                  })
                "
                @input="evaluateChangeState"
              >
                <template v-slot:prepend-inner>
                  <v-icon>{{
                    value.sortOrder == 'desc'
                      ? 'mdi-arrow-down'
                      : value.sortOrder == 'asc'
                      ? 'mdi-arrow-up'
                      : 'mdi-view-headline'
                  }}</v-icon>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" class="py-0 pb-2"> Filter </v-col>
            <v-col cols="12" class="py-0">
              <v-select
                v-model="value.filter.operator"
                :error-messages="errors.operator"
                dense
                clearable
                :items="availableOperators"
                label="Select an operator"
                solo
                @click:clear="
                  $nextTick(() => {
                    value.filter.operator = ''
                  })
                "
                @input="evaluateChangeState"
              />
              <v-text-field
                v-if="
                  ['integer', 'decimal', 'float', 'text'].includes(value.type)
                "
                v-model="value.filter.value"
                :error-messages="errors.value"
                :type="value.type == 'text' ? 'text' : 'number'"
                outlined
                dense
                clearable
                label="Value"
                @click:clear="value.filter.value = ''"
                @input="evaluateChangeState"
              />
              <v-menu
                v-else
                v-model="menuValue"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="value.filter.value"
                    outlined
                    label="Veuillez choisir une date"
                    readonly
                    clearable
                    v-on="on"
                    @click:clear="value.filter.value = ''"
                    @input="evaluateChangeState"
                  />
                </template>
                <v-date-picker
                  v-model="value.filter.value"
                  @input="
                    menuValue = false
                    evaluateChangeState()
                  "
                />
              </v-menu>
            </v-col>
            <v-col class="text-center controls">
              <v-btn
                block
                class="mb-1"
                outlined
                color="primary"
                :disabled="!hasChanged"
                @click="applyFilter"
              >
                Apply
              </v-btn>
              <v-row>
                <v-col style="padding-right: 5px">
                  <v-btn text block @click="cancelFilter"> Reset </v-btn>
                </v-col>
                <v-col style="padding-left: 5px">
                  <v-btn block @click="showDropdown = false"> Close </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      required: true,
      type: Object,
      default: () => {
        return {
          text: 'undefined',
        }
      },
    },
    externalFiltering: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menuValue: false,
      hardCopy: {
        filter: '',
        sortOrder: '',
      },
      hasChanged: false,
      showDropdown: false,
      availableOperators: [
        { text: 'Equals (=)', value: 'equals' },
        // {text:'Different (!=)',value:'!='},
        { text: 'Greater-than (>)', value: 'gt' },
        { text: 'Greater or Equal than (>=)', value: 'gte' },
        { text: 'Lower-than (<)', value: 'lt' },
        { text: 'Lower or Equal than (<=', value: 'lte' },
        { text: 'Contains', value: 'contains' },
      ],
      errors: {
        operator: [],
        value: [],
      },
    }
  },
  computed: {
    hasErrors() {
      return this.errors.operator.length > 0 || this.errors.value.length > 0
    },
  },
  watch: {
    sortOrder(vnew, vold) {
      this.value.sortOrder = this.sortOrder
    },
  },
  mounted() {
    this.value.sortOrder = this.sortOrder
    if (!this.value.sortOrder) {
      this.value.sortOrder = ''
    }
    if (!this.value.filter) {
      this.value.filter = {
        operator: '',
        value: '',
      }
    }
  },
  methods: {
    evaluateErrors() {
      const errs = {
        operator: [],
        value: [],
      }
      if (this.value.filter.operator && !this.value.filter.value) {
        errs.value.push('Ce champ est requis')
      }
      if (!this.value.filter.operator && this.value.filter.value) {
        errs.operator.push('Ce champ est requis')
      }
      this.errors = errs
    },
    evaluateChangeState() {
      this.hasChanged =
        this.hardCopy.filter.operator != this.value.filter.operator ||
        this.hardCopy.filter.value != this.value.filter.value ||
        this.hardCopy.sortOrder != this.value.sortOrder
    },
    applyFilter() {
      this.hardCopy = {
        filter: this.lo.cloneDeep(this.value.filter),
        sortOrder: this.lo.cloneDeep(this.value.sortOrder),
      }
      this.evaluateErrors()
      this.evaluateChangeState()
      if (!this.hasErrors) {
        this.$emit('apply', this.selected)
      }
    },
    cancelFilter() {
      this.value.sortOrder = ''
      this.value.filter = {
        operator: '',
        value: '',
      }
      this.evaluateErrors()
      this.evaluateChangeState()
      // this.applyFilter()
    },
  },
}
</script>
