const DEFAULT_SIZES = {
  counter: { w: 2, h: 4, minH: 4, minW: 2 },
  table: { w: 5, h: 9, minH: 6, minW: 3 },
  chart: { w: 5, h: 9, minH: 6, minW: 3 },
  kpi: { w: 3, h: 4, minH: 5, minW: 3 },
  line: { w: 6, h: 6, minH: 8, minW: 3 },
  area: { w: 3, h: 5, minH: 6, minW: 3 },
  'time-series': { w: 3, h: 5, minH: 6, minW: 3 },
  bar: { w: 6, h: 8, minH: 6, minW: 6 },
  histogram: { w: 3, h: 5, minH: 6, minW: 3 },
  pie: { w: 3, h: 7, minH: 7, minW: 3 },
  donut: { w: 3, h: 5, minH: 6, minW: 3 },
  radialBar: { w: 3, h: 5, minH: 6, minW: 3 },
  scatter: { w: 3, h: 5, minH: 6, minW: 3 },
  bubble: { w: 3, h: 5, minH: 6, minW: 3 },
  heatmap: { w: 3, h: 5, minH: 6, minW: 3 },
  candlestick: { w: 3, h: 5, minH: 6, minW: 3 },
  radar: { w: 3, h: 5, minH: 6, minW: 3 },
  polarArea: { w: 3, h: 5, minH: 6, minW: 3 },
  rangeBar: { w: 3, h: 5, minH: 6, minW: 3 },
}
export { DEFAULT_SIZES }
