<template>
  <v-app>
    <v-main>
      <v-container class="pa-0 fill-height" fluid>
        <v-row class="fill-height ma-0">
          <v-col
            class="imredd-bg pos-relative px-10"
            cols="12"
            md="6"
            sm="12"
            lg="4"
            xl="4"
          >
            <v-row class="fill-height" align="center" justify="center">
              <v-col v-if="!loading">
                <SignupDetail
                  v-show="!invalid_key"
                  v-model="signupForm"
                  :invitation_key="$route.params.key"
                  :invited_by="inviter"
                  :user_pk="user_pk"
                />
                <InvalidInvitationKey v-show="invalid_key" />
                <div class="text-center">
                  <img
                    class="mt-2 powered_by"
                    src="@/assets/powered_by_himydata_blue.png"
                    height="35px"
                    contain
                  />
                </div>
              </v-col>
              <v-col v-else>
                <h4>Loading...</h4>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            class="
              bg-cover
              fill-height
              hidden-xs-only hidden-sm-only
              md-6
              lg-8
              xl-8
            "
          />
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import { register } from '@/utils/auth'
import AccountService from '@/services/AccountService'
import SignupDetail from '@/components/bnc/SignupDetail'
import InvalidInvitationKey from '@/components/bnc/InvalidInvitationKey'

export default {
  components: {
    SignupDetail,
    InvalidInvitationKey,
  },
  data() {
    return {
      signupForm: {
        email: '',
        username: '',
        first_name: '',
        last_name: '',
        password: '',
        password2: '',
        merchant: '',
      },
      invalid_key: false,
      inviter: {
        name: '',
        company: '',
      },
      user_pk: -1,
      loading: false,
    }
  },
  methods: {
    init() {
      var key = this.$route.params.key
      if (key) {
        // Check if the key is valid
        // If the key is not valid show that is not valid
        this.loading = true
        AccountService.getInvitation(this, key)
          .then((result) => {
            this.signupForm.email = result.data.user.email
            this.signupForm.username = result.data.user.username
            this.signupForm.first_name = result.data.user.first_name
            this.signupForm.last_name = result.data.user.last_name
            this.signupForm.merchant = result.data.user.merchant.id
            this.inviter.name = result.data.inviter_name
            this.inviter.company = result.data.inviter_company
            this.user_pk = result.data.user.pk
          })
          .catch((error) => {
            console.log(error)
            this.invalid_key = true
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.invalid_key = true
      }
    },
  },
  mounted() {
    this.init()
  },
}
</script>
<style>
.imredd-bg {
  background-color: white;
}
.pos-relative {
  position: relative;
}
.bg-cover {
  background-size: cover;
  background-position: 50% 50%;
  background-image: url(../../assets/signin_cover.png);
}
.powered_by {
  margin: 20px;
}
</style>
